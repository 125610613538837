<template>
  <v-row no-gutters class="footer__evaluate-links">
    <v-col cols="12" class="mb-2">
      <router-link
        :to="{ name: 'CustomPage', params: { ident: 'evaluate-service' } }"
        class="base-2"
      >
        {{ sectionData.fields.link_evaluate }}
      </router-link>
    </v-col>
    <v-col cols="12" class="mb-2">
      <router-link
        :to="{
          name: 'CustomPage',
          params: { ident: 'evaluate-service-results' },
        }"
        class="base-2"
      >
        {{ sectionData.fields.link_evaluate_results }}
      </router-link>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "FooterEvaluateLinks",
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
#app.v-application .theme--light.footer .footer__evaluate-links {
  color: var(--v-linksOnBgDark-base);

  a {
    color: var(--v-linksOnBgDark-base);
    text-decoration: underline;
  }
}
</style>
