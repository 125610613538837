<template>
  <div v-if="sectionData" class="subsection">
    <section-title header-id="names" :title="sectionData.fields.title" />
    <v-row no-gutters class="align-start">
      <v-col cols="12" class="longread pb-5 pb-sm-10">
        <kk-dynamic-html :html="sectionData.fields.text_upper" />
      </v-col>
      <v-col cols="12" class="pb-5 pb-sm-10">
        <v-img :src="sectionData.fields.image" eager />
      </v-col>
      <v-col cols="12" xl="9" class="pb-5 pb-sm-10 pb-xl-0 pr-xl-6">
        <kk-dynamic-html :html="sectionData.fields.text_left" />
      </v-col>
      <v-col cols="12" xl="3">
        <kk-dynamic-html :html="sectionData.fields.text_right" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "AboutNames",
  components: { SectionTitle, KkDynamicHtml },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
