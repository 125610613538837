import Exhibitions from "@/pages/Exhibitions";
import Exhibition from "@/pages/Exhibition";

export default [
  {
    path: "/exhibitions",
    component: Exhibitions,
    props: true,
    children: [
      {
        path: "tag/:tag",
        name: "ExhibitionsByTag",
        props: true,
        meta: {
          kind: "Exhibitions",
          group: "Exhibitions",
        },
      },
      {
        path: "",
        name: "Exhibitions",
        meta: {
          kind: "Exhibitions",
          group: "Exhibitions",
        },
      },
      {
        path: "future/tag/:tag",
        name: "ExhibitionsFutureByTag",
        props: true,
        meta: {
          kind: "ExhibitionsFuture",
          group: "Exhibitions",
        },
      },
      {
        path: "future",
        name: "ExhibitionsFuture",
        meta: {
          kind: "ExhibitionsFuture",
          group: "Exhibitions",
        },
      },
      {
        path: "archive/tag/:tag",
        name: "ExhibitionsArchiveByTag",
        props: true,
        meta: {
          kind: "ExhibitionsArchive",
          group: "Exhibitions",
        },
      },
      {
        path: "archive",
        name: "ExhibitionsArchive",
        meta: {
          kind: "ExhibitionsArchive",
          group: "Exhibitions",
        },
      },
      {
        path: "all/tag/:tag",
        name: "ExhibitionsAllByTag",
        props: true,
        meta: {
          kind: "ExhibitionsAll",
          group: "Exhibitions",
        },
      },
      {
        path: "all",
        name: "ExhibitionsAll",
        meta: {
          kind: "ExhibitionsAll",
          group: "Exhibitions",
        },
      },
      {
        path: ":ident",
        name: "Exhibition",
        component: Exhibition,
        props: true,
      },
    ],
  },
];
