import Repository from "@/plugins/axios";

export default {
  // Получить базу
  getLibrary(params) {
    return Repository.get(`/library`, { params });
  },

  // Получить список книг
  getBooks(params) {
    return Repository.get(`/library/books`, { params });
  },

  // Получить страницу книги
  getBook(bookName) {
    return Repository.get(`/library/${bookName}`);
  },

  // Получить список подборок
  getSets(params) {
    return Repository.get(`/library/sets`, { params });
  },

  // Получить страницу подборки
  getSet(setName) {
    return Repository.get(`/library/sets/${setName}`);
  },

  // Получить страницу о библиотеке
  getAbout() {
    return Repository.get(`/library/about`);
  },

  // Выполнить поиск в словаре
  searchDic(dic, term) {
    return Repository.get(`/library/books/dic`, { params: { dic, term } });
  },
};
