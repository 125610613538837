<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="square"
      />
      <path
        d="M16 2V6"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="square"
      />
      <path
        d="M8 2V6"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="square"
      />
      <path
        d="M3 10H21"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="square"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
