var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.pageLoaded)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == 'Event'),expression:"$route.name == 'Event'"}],staticClass:"section"},[_c('image-header',{ref:"refimageheader",attrs:{"image":_vm.pageData.fields.image,"video":_vm.pageData.fields.video}}),_vm._v(" "),(_vm.pageLoaded)?_c('kk-article',{ref:"refarticle",attrs:{"breadcrumbs":_vm.breadcrumbsItems,"contents":_vm.contents,"ticket-settings":_vm.pageData.fields}},[_c('h2',[_vm._v(_vm._s(_vm.pageData.fields.title))]),_vm._v(" "),_c('kk-dynamic-html',{attrs:{"html":_vm.allHtml}}),_vm._v(" "),(_vm.pageData.children.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mb-2 mb-sm-4",attrs:{"id":"subevents"}},[_vm._v(_vm._s(_vm.subEventsTitle))])]),_vm._v(" "),_vm._l((_vm.pageData.children),function(subEvent){return _c('v-col',{key:subEvent.node_name,attrs:{"cols":"12","lg":"6"}},[_c('preview',{attrs:{"payload":subEvent,"to":{
              name: 'EventSub',
              params: { ident: _vm.ident, subIdent: subEvent.node_name },
            },"inner-info":"","full-width":""}})],1)})],2):_vm._e(),_vm._v(" "),_c('tag-list',{attrs:{"tags":_vm.pageData.fields.tags,"tag-route":_vm.tagRoute}})],1):_vm._e(),_vm._v(" "),(_vm.pageData.library_set)?_c('section-title',{attrs:{"title":_vm.pageData.library_set.fields.activity_title,"browse-title":_vm.pageData.library_set.fields.activity_browse,"browse-link":{
        name: 'LibrarySet',
        params: { ident: _vm.pageData.library_set.set_node_name },
      }}}):_vm._e(),_vm._v(" "),(_vm.pageData.library_set)?_c('horizontal-scroller',{staticClass:"subsection",attrs:{"items":_vm.pageData.library_set.children,"content-class":"mx-n3"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('horizontal-scroller-end-link-book',{attrs:{"browse-title":_vm.pageData.library_set.fields.activity_browse_link,"browse-link":{
            name: 'LibrarySet',
            params: { ident: _vm.pageData.library_set.set_node_name },
          }}})]},proxy:true},{key:"default",fn:function({ item }){return [_c('library-book-preview',{staticClass:"mx-3",attrs:{"book":item,"width":_vm.$vuetify.breakpoint.smAndUp ? 286 : 214,"in-set-list":""}})]}}],null,false,2194149357)}):_vm._e(),_vm._v(" "),_c('section-title',{attrs:{"title":_vm.pageData.events_fields.title_other,"browse-title":_vm.pageData.events_fields.button_all,"browse-link":{ path: '/events' }}}),_vm._v(" "),_c('horizontal-scroller',{attrs:{"items":_vm.pageData.more,"content-class":"mx-n3"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('horizontal-scroller-end-link',{attrs:{"browse-title":_vm.pageData.events_fields.button_all,"browse-link":{ path: '/events' }}})]},proxy:true},{key:"default",fn:function({ item }){return [_c('preview',{staticClass:"mx-3",attrs:{"payload":item,"to":{ name: 'Event', params: { ident: item.node_name } },"tag-route":_vm.tagRouteInList,"inner-info":""}})]}}],null,false,602760458)})],1):_vm._e(),_vm._v(" "),_c('router-view',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name == 'EventSub'),expression:"$route.name == 'EventSub'"}],key:_vm.$route.path,on:{"page-loaded":function($event){return _vm.$emit('page-loaded')}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }