import About from "@/pages/About/index.vue";
import AboutHistory from "@/pages/About/AboutHistory.vue";

export default [
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/about/history",
    name: "AboutHistory",
    component: AboutHistory,
  },
];
