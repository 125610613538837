<template>
  <v-row v-if="items.length > 0">
    <v-col v-for="book in items" :key="book.node_name" :class="colClass">
      <library-book-preview :book="book" />
    </v-col>
  </v-row>
  <v-row v-else-if="notFoundText.length > 0">
    <v-col>
      <kk-dynamic-html :html="notFoundText" />
    </v-col>
  </v-row>
</template>

<script>
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import LibraryBookPreview from "./LibraryBookPreview.vue";

export default {
  name: "LibraryList",
  components: { KkDynamicHtml, LibraryBookPreview },
  props: {
    items: {
      type: Array,
      required: true,
    },
    filtersVisible: {
      type: Boolean,
      default: false,
    },
    notFoundText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    colClass() {
      return this.filtersVisible
        ? "col-6 col-md-6 col-lg-4 col-xl-3"
        : "col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2";
    },
  },
};
</script>
