<template>
  <div class="image-clipped">
    <img
      v-if="$attrs.image !== undefined"
      :src="src"
      v-bind="$attrs"
      :style="imgStyle"
      class="d-block"
      alt=""
      v-on="$listeners"
    />
  </div>
</template>
<script>
export default {
  name: "ImageClipped",
  computed: {
    img() {
      return this.$attrs.image;
    },
    fit() {
      return this.$attrs.fit;
    },
    src() {
      return this.img.i_main;
    },
    imgStyle() {
      const { img } = this;
      const scaleFactor =
        (img.i_height * (img.i_bottom - img.i_top) +
          img.i_width * (img.i_right - img.i_left)) /
        200;
      const scale = this.fit / scaleFactor;
      const translateX = (-img.i_left * img.i_width) / 100;
      const translateY = (-img.i_top * img.i_height) / 100;
      return {
        "transform-origin": "top left",
        transform: `scale(${scale}) translateX(${translateX}px) translateY(${translateY}px)`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.image-clipped {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
