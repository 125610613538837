<template>
  <div
    v-if="$vuetify.breakpoint.smAndUp"
    ref="refcontainer"
    class="collection__container mt-n3"
    @mousemove="onMouseMove"
    @mouseleave="onMouseOut"
  >
    <div class="collection__item" :style="getStyleWidth(0, 0.25)">
      <fluid-collection-item
        :item-data="getItem(0)"
        :style="getStyleHeight(0, 0.6)"
      />
      <fluid-collection-item
        :item-data="getItem(1)"
        :style="getStyleHeight(1, 0.4)"
      />
    </div>
    <div class="collection__item" :style="getStyleWidth(0.125 + 0.25, 0.25)">
      <fluid-collection-item
        :item-data="getItem(2)"
        :style="getStyleHeight(0.5, 1)"
      />
    </div>
    <div class="collection__item" :style="getStyleWidth(0.125 + 0.5, 0.25)">
      <fluid-collection-item
        :item-data="getItem(3)"
        :style="getStyleHeight(0, 0.4)"
      />
      <fluid-collection-item
        :item-data="getItem(4)"
        :style="getStyleHeight(1, 0.6)"
      />
    </div>
    <div class="collection__item" :style="getStyleWidth(1, 0.25)">
      <fluid-collection-item
        :item-data="getItem(5)"
        :style="getStyleHeight(0.5, 1)"
      />
    </div>
  </div>

  <horizontal-scroller
    v-else
    :items="items"
    content-class="ml-n3"
    unlimited-item-width
  >
    <template v-slot="{ item }">
      <fluid-collection-item :item-data="item" class="py-0" />
    </template>
  </horizontal-scroller>
</template>
<script>
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import FluidCollectionItem from "./FluidCollectionItem.vue";

export default {
  name: "FluidCollection",
  components: { FluidCollectionItem, HorizontalScroller },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pos: {
        x: 0.5,
        y: 0.5,
      },
    };
  },
  computed: {
    getItem() {
      return (index) => this.items[index] || {};
    },
    getStyleWidth() {
      return (centerX, sizeX) => {
        const delta = 0.5 - Math.abs(this.pos.x - centerX);
        const width = (sizeX + delta * 0.2) * 100;
        return {
          flex: `2 2 ${width}%`,
        };
      };
    },
    getStyleHeight() {
      return (centerY, sizeY) => {
        const delta = sizeY == 1 ? 0 : 0.5 - Math.abs(this.pos.y - centerY);
        const height = sizeY + delta * 0.2;
        return {
          width: "100%",
          flex: `2 2 ${height * 100}%`,
        };
      };
    },
  },
  methods: {
    onMouseMove(event) {
      const rect = this.$refs.refcontainer.getBoundingClientRect();
      const x = (event.clientX - rect.left) / rect.width;
      const y = (event.clientY - rect.top) / rect.height;
      this.pos.x = Math.max(Math.min(x, 0.8), 0.2);
      this.pos.y = Math.max(Math.min(y, 0.8), 0.2);
    },
    onMouseOut() {
      this.pos.x = this.pos.y = 0.5;
    },
  },
};
</script>
<style lang="scss" scoped>
.collection__container {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 40vw;
  .collection__item {
    display: flex;
    flex-direction: column;
    transition: flex 0.3s linear;
  }
}
</style>
