<template>
  <div>
    <v-row dense class="align-center">
      <v-col class="flex-grow-0 text-no-wrap placeholder mr-1">
        {{ $t("Common.yearPickerFrom") }}
      </v-col>
      <v-col class="flex-grow-0 input-year">
        <v-text-field
          :value="selectedYears[0]"
          dense
          outlined
          maxlength="4"
          hide-details
          class="text-field-centered"
          @change="typeMin"
        />
      </v-col>
      <v-spacer class="d-sm-none" />
      <v-col class="flex-grow-0 text-no-wrap placeholder mr-1 ml-4">
        {{ $t("Common.yearPickerTo") }}
      </v-col>
      <v-col class="flex-grow-0 input-year">
        <v-text-field
          :value="selectedYears[1]"
          dense
          outlined
          maxlength="4"
          hide-details
          class="text-field-centered"
          @change="typeMax"
        />
      </v-col>
    </v-row>
    <v-row dense class="mt-2">
      <v-col cols="12">
        <v-range-slider
          v-model="selectedYears"
          height="20"
          :min="limits[0]"
          :max="limits[1]"
          hide-details
          track-color="line"
          @change="$emit('change')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "YearRangeSelector",
  props: {
    value: {
      type: Array,
      required: true,
    },
    limits: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    selectedYears: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async typeMin(minInput) {
      let min = parseInt(minInput);
      if (isNaN(min) || min < this.limits[0]) {
        min = this.selectedYears[0];
      }
      if (min > this.selectedYears[1]) {
        min = this.selectedYears[1];
      }
      if (min == this.selectedYears[0]) {
        this.$emit("input", [this.selectedYears[0] + 1, this.selectedYears[1]]);
        await this.$nextTick();
      }
      this.$emit("input", [min, this.selectedYears[1]]);
      this.$emit("change");
    },
    async typeMax(maxInput) {
      let max = parseInt(maxInput);
      if (isNaN(max) || max > this.limits[1]) {
        max = this.selectedYears[1];
      }
      if (max < this.selectedYears[0]) {
        max = this.selectedYears[0];
      }
      if (max == this.selectedYears[1]) {
        this.$emit("input", [this.selectedYears[0], this.selectedYears[1] - 1]);
        await this.$nextTick();
      }
      this.$emit("input", [this.selectedYears[0], max]);
      this.$emit("change");
    },
  },
};
</script>
<style scoped lang="scss">
.input-year {
  min-width: 95px;
  max-width: 95px;
  @media (max-width: 1023px) {
    min-width: 75px;
    max-width: 75px;
  }
}
</style>
