import Repository from "@/plugins/axios";

export default {
  // Получить страницу "О музее"
  getAbout() {
    return Repository.get(`/about`);
  },

  // Получить страницу "История музея"
  getHistory() {
    return Repository.get(`/about/history`);
  },
};
