import { render, staticRenderFns } from "./PageTitle.vue?vue&type=template&id=2c7a8398&scoped=true"
import script from "./PageTitle.vue?vue&type=script&lang=js"
export * from "./PageTitle.vue?vue&type=script&lang=js"
import style0 from "./PageTitle.vue?vue&type=style&index=0&id=2c7a8398&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c7a8398",
  null
  
)

export default component.exports