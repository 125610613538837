<template>
  <div>
    <div v-show="isListRoute" id="content-top">
      <horizontal-scroller
        v-if="pageData.stat.tags"
        :items="pageData.stat.tags"
        :disabled="$vuetify.breakpoint.smAndUp"
        content-class="mx-n2"
        class="mb-sm-16"
        disable-spacing
      >
        <template v-slot:prepend>
          <v-col class="flex-grow-0">
            <tag-chip
              :input-value="filters.tags.length == 0"
              class="my-2 mx-2"
              @click="onTagsReset"
            >
              {{ $t("Common.allTags") }}
            </tag-chip>
          </v-col>
        </template>

        <template v-slot="{ item }">
          <tag-chip
            :input-value="filters.tags.some((ct) => ct == item.id)"
            class="my-2 mx-2"
            @click="onTagToggle(item)"
          >
            {{ (item.text || "").toLowerCase() }}
          </tag-chip>
        </template>
      </horizontal-scroller>

      <v-row class="mt-6">
        <v-col
          v-for="(librarySet, index) in pageData.children"
          :key="librarySet.node_name"
          cols="12"
          :sm="getCols(index)"
        >
          <preview
            :payload="librarySet"
            :to="{
              name: 'LibrarySet',
              params: { ident: librarySet.node_name },
            }"
            inner-info
            no-date
            class="mb-1 mb-sm-9"
            :tag-route="tagRoute"
          />
        </v-col>
      </v-row>

      <infinite-loader
        :fetch="fetchMore"
        :disabled="disableInfiniteScroll"
        :visible="pageData.more > 0 || !pageLoaded"
      />

      <zuruck />
    </div>

    <router-view
      v-if="!isListRoute"
      :key="$route.params.ident"
      @page-loaded="$emit('page-loaded')"
    >
    </router-view>
  </div>
</template>

<script>
import LibraryRepository from "@/api/library.js";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import TagChip from "@/components/Tags/TagChip.vue";
import Preview from "@/components/Preview/Preview.vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";
import Zuruck from "@/components/Zuruck.vue";
import xor from "lodash/xor";

export default {
  components: {
    HorizontalScroller,
    TagChip,
    Preview,
    InfiniteLoader,
    Zuruck,
  },
  props: {
    tag: {
      type: String,
      default: "",
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
        stat: {
          tags: [],
        },
        more: 0,
      },
      filters: {
        tags: [],
      },
      offset: 0,
      loading: true,
    };
  },
  computed: {
    isListRoute() {
      return this.routeKind === "LibrarySets";
    },
    disableInfiniteScroll() {
      return this.loading || this.pageData.more == 0;
    },
    queryParams() {
      const params = {};
      if (this.filters.tags.length > 0) params.tags = this.filters.tags;
      return params;
    },
    routeKind() {
      return this.$route.meta?.kind || this.$route.name;
    },
    getCols() {
      return (index) => {
        const row = Math.floor(index / 2);
        if (row % 2 == 0) {
          return 6;
        } else if ((row - 1) % 4 == 0) {
          return index % 2 == 0 ? 5 : 7;
        } else {
          return index % 2 == 0 ? 7 : 5;
        }
      };
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    routeKind() {
      if (this.tag.length > 0) {
        this.filters.tags = [this.tag];
      }
    },
    async tag(newV) {
      if (newV.length > 0) {
        this.filters.tags = [newV];
        await this.loadPage();
        await this.$nextTick();
        this.$vuetify.goTo("#content-top");
      }
    },
  },
  mounted() {
    if (this.tag.length > 0) {
      this.filters.tags = [this.tag];
    }
    this.loadPage();
  },
  methods: {
    async loadPage() {
      await this.queryItems();
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.$emit("page-loaded");
      await this.$nextTick();
      this.loading = false;
    },
    async queryItems() {
      const { data } = await LibraryRepository.getSets(this.queryParams);
      this.offset = data.children.length;
      this.pageData = data;
    },
    async fetchMore() {
      this.loading = true;

      const queryParams = { ...this.queryParams };
      queryParams.offset = this.offset;

      const { data } = await LibraryRepository.getSets(queryParams);

      this.offset += data.children.length;
      data.children.forEach((element) => {
        const index = this.pageData.children.length;
        this.$set(this.pageData.children, index, element);
      });
      this.pageData.more = data.more;

      this.loading = false;
    },
    onTagsReset() {
      this.filters.tags = [];
      this.queryItems();
    },
    onTagToggle(tag) {
      this.filters.tags = xor(this.filters.tags, [tag.id]);
      this.queryItems();
    },
    tagRoute(tag) {
      return { name: "LibrarySetsByTag", params: { tag: tag.id } };
    },
  },
};
</script>
<style scoped>
.guides-list {
  margin-top: 120px;
}
@media (max-width: 1023px) {
  .guides-list {
    margin-top: 60px;
  }
}
</style>
