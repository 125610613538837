var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.sectionData)?_c('div',{staticClass:"subsection"},[_c('section-title',{staticStyle:{"margin-bottom":"0 !important"},attrs:{"header-id":"subscriptions","title":_vm.sectionData.fields.title}}),_vm._v(" "),_c('div',{staticClass:"grid"},_vm._l((_vm.sectionData.children),function(ticket,ticketIndex){return _c('div',{key:ticketIndex,staticClass:"subgrid",class:ticketIndex == _vm.sectionData.children.length - 1 ? 'subgrid--last' : ''},[_c('div'),_vm._v(" "),_c('div',{staticClass:"cell cell-center longread ticket-title d-flex align-center",class:_vm.ticketCellClass(
            ticketIndex == 0,
            ticketIndex == _vm.sectionData.children.length - 1
          )},[_c('div',[_vm._v(_vm._s(ticket.fields.title))]),_vm._v(" "),_c('kk-popover',{staticClass:"ml-2",attrs:{"html":ticket.fields.addinfo_title}})],1),_vm._v(" "),_c('div',{staticClass:"cell cell-center d-flex flex-column justify-center",class:_vm.ticketCellClass(
            ticketIndex == 0,
            ticketIndex == _vm.sectionData.children.length - 1
          )},[_c('h4',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(ticket.fields.price))])]),_vm._v(" "),_c('div',{staticClass:"cell cell-right d-flex align-center",class:_vm.ticketCellClass(
            ticketIndex == 0,
            ticketIndex == _vm.sectionData.children.length - 1
          )},[_c('v-btn',{attrs:{"outlined":"","block":"","tile":"","href":ticket.fields.service_url,"target":"_blank"}},[_vm._v("\n          "+_vm._s(_vm.sectionData.fields.button_buy.toLowerCase())+"\n        ")])],1)])}),0),_vm._v(" "),_c('v-row',{staticClass:"mb-6 mb-sm-12"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-img',{staticClass:"mb-1 mb-sm-3",attrs:{"src":_vm.sectionData.fields.image1,"eager":""}}),_vm._v(" "),_c('kk-dynamic-html',{attrs:{"html":_vm.sectionData.fields.image1_text}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-img',{staticClass:"mb-1 mb-sm-3",attrs:{"src":_vm.sectionData.fields.image2,"eager":""}}),_vm._v(" "),_c('kk-dynamic-html',{attrs:{"html":_vm.sectionData.fields.image2_text}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }