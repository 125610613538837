import OnlineProjects from "@/pages/OnlineProjects";

export default [
  {
    path: "/online-projects",
    name: "OnlineProjects",
    component: OnlineProjects,
    props: true,
    children: [
      {
        path: ":ident",
        name: "OnlineProject",
      },
    ],
  },
];
