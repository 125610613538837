<template>
  <div v-if="pageLoaded" class="section">
    <page-title :title="pageData.fields.title" />

    <kk-dynamic-html
      v-if="pageData.fields.article"
      :html="pageData.fields.article"
      class="subsection"
    />

    <v-row class="mt-n6 align-stretch flex-nowrap">
      <v-col v-for="col in colCount" :key="col" :cols="colWidth">
        <div
          v-for="onlineProject in childrenOrganized[col - 1]"
          :key="onlineProject.node_name"
          class="py-3"
        >
          <online-project-list-item
            :payload="onlineProject"
            :active="ident == onlineProject.node_name"
            high-xs-breakpoint
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OnlineProjectsRepository from "@/api/online-projects.js";
import PageTitle from "@/components/PageTitle.vue";
import OnlineProjectListItem from "@/components/OnlineProjectListItem.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  components: {
    PageTitle,
    OnlineProjectListItem,
    KkDynamicHtml,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  props: {
    ident: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
      },
      heightFix: 0.2,
    };
  },
  computed: {
    colCount() {
      if (this.$vuetify.breakpoint.xlOnly) return 4;
      if (this.$vuetify.breakpoint.lgAndUp) return 3;
      if (this.$vuetify.breakpoint.smAndUp) return 2;
      return 1;
    },
    colWidth() {
      return Math.floor(12 / this.colCount);
    },
    childrenOrganized() {
      const cols = new Array(this.colCount).fill(null).map(() => new Array());
      const lengths = new Array(this.colCount).fill(0);
      this.pageData.children.forEach((item) => {
        const ap =
          Math.round(
            ((item.fields.image && item.fields.image.i_aspect_ratio
              ? 1 / item.fields.image.i_aspect_ratio
              : 0) +
              this.heightFix) *
              1000
          ) / 1000;
        const minLength = Math.min(...lengths);
        const colIndex = lengths.indexOf(minLength);
        lengths[colIndex] += ap;
        cols[colIndex].push({ ...item });
      });
      return cols;
    },
    disableInfiniteScroll() {
      return this.loading || this.pageData.more == 0;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await OnlineProjectsRepository.getOnlineProjects({});
      this.pageData = data;

      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.$emit("page-loaded");
      this.loading = false;
    },
  },
};
</script>
<style scoped lang="scss">
#app.v-application.theme--light {
  .loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
  }
}
</style>
