var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pageLoaded)?_c('div',{staticClass:"section"},[_c('page-title',{attrs:{"title":_vm.pageData.fields.title}}),_vm._v(" "),(_vm.pageData.fields.article)?_c('kk-dynamic-html',{staticClass:"subsection",attrs:{"html":_vm.pageData.fields.article}}):_vm._e(),_vm._v(" "),_c('v-row',{staticClass:"section flex-nowrap"},[_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('accordion',{attrs:{"items":_vm.pageData.categories},scopedSlots:_vm._u([{key:"header",fn:function({ item }){return [_vm._v("\n          "+_vm._s(item.fields.title)+"\n        ")]}},{key:"default",fn:function({ item }){return [_c('div',{staticClass:"mb-6 mt-n4 mt-sm-n6"},_vm._l((item.children),function(document){return _c('file-attach',{key:document.node_name,attrs:{"item":{
                f_path: document.fields.file,
                f_title: document.fields.title,
              }}})}),1)]}}],null,false,3654322340)}),_vm._v(" "),_vm._l((_vm.pageData.documents),function(document){return _c('file-attach',{key:document.node_name,attrs:{"item":{
          f_path: document.fields.file,
          f_title: document.fields.title,
        }}})})],2),_vm._v(" "),_c('v-col',{staticClass:"d-none d-lg-block",attrs:{"lg":"3"}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }