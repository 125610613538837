const langAvailFactory = () => {
  return {
    ru: true,
    en: true,
  };
};

export default {
  state: {
    langsAvail: langAvailFactory(),
  },
  getters: {
    getLangAvail(state) {
      return (langId) => state.langsAvail[langId] || false;
    },
    getAllLangs(state) {
      return state.langsAvail;
    },
  },
  mutations: {
    setLangsAvail(state /*, langsAvail*/) {
      state.langsAvail = langAvailFactory();
      /*state.langsAvail = Object.entries(langsAvail).reduce((product, entry) => {
        product[entry[0]] = entry[1];
        return product;
      }, langAvailFactory());*/
    },
    setLangsAvailAll(state) {
      state.langsAvail = langAvailFactory();
    },
  },
};
