<template>
  <kk-link
    v-if="hasLink"
    :link="payload.fields.link"
    class="d-block kk-hoverable"
    :style="heightStyle"
  >
    <div class="online-project-item d-flex flex-column" :style="heightStyle">
      <div class="d-flex flex-nowrap justify-space-between">
        <div class="online-project-item__title longread textBase--text">
          {{ payload.fields.title }}
        </div>
        <div class="online-project-item__exticon flex-grow-0 ml-6">
          <v-icon>$vuetify.icons.ExternalLink</v-icon>
        </div>
      </div>
      <div v-if="hasImage" class="online-project-item__image flex-grow-1">
        <img
          class="img-xs"
          :class="imgClass"
          :src="payload.fields.image.i_main"
          alt=""
        />
        <image-extended
          :image="payload.fields.image"
          class="img-sm"
          :class="imgClass"
          height="100%"
          eager
        />
      </div>
    </div>
  </kk-link>

  <div
    v-else-if="hasText"
    :id="`online-project-${payload.node_name}`"
    class="clickable kk-hoverable"
    :style="heightStyle"
    @click="openDialog"
  >
    <div class="online-project-item d-flex flex-column" :style="heightStyle">
      <div class="online-project-item__title longread textBase--text">
        {{ payload.fields.title }}
      </div>
      <div v-if="hasImage" class="online-project-item__image flex-grow-1">
        <img
          class="img-xs"
          :class="imgClass"
          :src="payload.fields.image.i_main"
          alt=""
        />
        <image-extended
          :image="payload.fields.image"
          class="img-sm"
          :class="imgClass"
          height="100%"
          eager
        />
      </div>
    </div>

    <v-dialog v-model="dialog" content-class="common-dialog">
      <v-card class="card">
        <v-row no-gutters class="mb-8 mb-sm-12">
          <v-col>
            <h3>{{ payload.fields.title }}</h3>
          </v-col>
          <v-col class="text-right flex-grow-0">
            <div class="pa-2 mb-1 mr-n2" @click="closeDialog">
              <v-icon class="link" size="40">$vuetify.icons.Close</v-icon>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <kk-dynamic-html :html="payload.fields.text" />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import KkDynamicHtml from "./Article/KkDynamicHtml.vue";
import ImageExtended from "./ImageExtended.vue";
import KkLink from "./KkLink.vue";

export default {
  name: "OnlineProjectListItem",
  components: { KkLink, ImageExtended, KkDynamicHtml },
  props: {
    payload: {
      type: Object,
      required: true,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    highXsBreakpoint: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    heightStyle() {
      return this.fullHeight ? { height: "100%" } : {};
    },
    imgClass() {
      return this.highXsBreakpoint ? "breakpoint1024" : "breakpoint640";
    },
    hasImage() {
      return !!this.payload.fields?.image?.i_main;
    },
    hasLink() {
      return this.payload.fields && this.payload.fields.link.length > 0;
    },
    hasText() {
      return this.payload.fields && this.payload.fields.text.length > 0;
    },
  },
  async mounted() {
    if (this.active) {
      setTimeout(() => {
        const element = document.getElementById(
          `online-project-${this.payload.node_name}`
        );
        if (element) {
          element.scrollIntoView({ behavior: "instant" });
        }
      }, 500);
      this.dialog = true;
    }
  },
  methods: {
    openDialog() {
      this.$router
        .push({ path: `/online-projects/${this.payload.node_name}` })
        .catch(() => {});
      this.dialog = true;
    },
    closeDialog() {
      this.$router.push({ path: "/online-projects" }).catch(() => {});
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
#app.v-application.theme--light .online-project-item {
  background-color: var(--v-bgSecondary-base);
  border: 1px solid var(--v-line-base);
  padding: 12px;
  @media (min-width: 1024px) {
    padding: 24px;
  }
  .online-project-item__exticon .v-icon {
    color: var(--v-textLight-base);
  }
  .online-project-item__image {
    margin-top: 24px;
    text-align: center;

    img {
      max-width: 100%;
    }

    .breakpoint640.img-xs {
      display: inline;
      @media (min-width: 640px) {
        display: none;
      }
    }
    .breakpoint640.img-sm {
      display: none;
      @media (min-width: 640px) {
        display: block;
        height: 100%;
      }
    }

    .breakpoint1024.img-xs {
      display: inline;
      @media (min-width: 1024px) {
        display: none;
      }
    }
    .breakpoint1024.img-sm {
      display: none;
      @media (min-width: 1024px) {
        display: block;
        height: 100%;
      }
    }
  }
}
.online-project-dialog-image {
  max-width: 100%;
}
</style>
