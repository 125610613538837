<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <path
      d="M11.9986 18.26L4.9452 22.2082L6.52053 14.2799L0.585938 8.7918L8.61298 7.84006L11.9986 0.5L15.3842 7.84006L23.4112 8.7918L17.4767 14.2799L19.052 22.2082L11.9986 18.26ZM11.9986 15.968L16.2453 18.3451L15.2968 13.5717L18.8699 10.2674L14.037 9.69434L11.9986 5.27502L9.96019 9.69434L5.12726 10.2674L8.70036 13.5717L7.75188 18.3451L11.9986 15.968Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
