<template>
  <a :href="href" target="_blank" class="masked-icon-link">
    <div class="masked-icon" :class="{ inverted }" :style="style">
      <img :src="maskIcon" class="masked-image-spacer" alt="" />
    </div>
  </a>
</template>

<script>
export default {
  name: "MaskedIconLink",
  props: {
    maskIcon: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    height: {
      type: [String, Number],
      required: true,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return {
        mask: `url(${this.maskIcon}) no-repeat center / contain`,
        "-webkit-mask": `url(${this.maskIcon}) no-repeat center / contain`,
        height: `${this.height}px`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.masked-icon-link {
  display: block;

  .masked-icon {
    --color: var(--v-iconSocial-base);
    background-color: var(--color);

    .masked-image-spacer {
      height: 100%;
      opacity: 0;
    }
  }
  .masked-icon:hover {
    --color: var(--v-textBase-base);
    transition: background-color 0.3s ease-in-out;
  }
  .masked-icon.inverted:hover {
    --color: var(--v-textOnBgDark-base);
  }
}
</style>
