<template>
  <div>
    <div v-if="pageLoaded" v-show="isListRoute" class="section">
      <page-title :title="pageData.fields.title" />

      <tabs-filters
        id="content-top"
        ref="reffilter"
        v-model="filters"
        :meta="pageData.stat"
        :dic="dic"
        @change="queryItems"
      />

      <kk-dynamic-html
        v-if="pageData.fields.article && filters.kind == 'actual'"
        :html="pageData.fields.article"
        class="mb-8 mb-sm-16"
      />

      <div v-if="pageData.children.length == 0 && filters.kind == 'future'">
        <v-row no-gutters class="mt-10">
          <v-col cols="12" class="longread mb-6">
            <div v-html="pageData.fields.no_future" />
          </v-col>
          <v-col cols="12" sm="6" lg="5" xl="4" class="pr-0 pr-sm-6 mb-3">
            <validation-observer ref="validator">
              <validation-provider
                v-slot="{ errors }"
                rules="email"
                mode="eager"
                :name="$t('Common.emailPlaceholder')"
              >
                <v-text-field
                  id="form-text-field-email"
                  v-model="email"
                  block
                  outlined
                  :placeholder="$t('Common.emailPlaceholder')"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </validation-observer>
          </v-col>
          <v-col class="flex-sm-grow-0 mb-10">
            <v-btn outlined tile block @click="subscribeClick">
              {{ header.fields.subscribe_button }}
            </v-btn>
          </v-col>
          <v-col cols="12">
            <social-buttons height="35" space-between="24" />
          </v-col>
        </v-row>

        <news-subscribe-modal v-model="showSubscriptionDialog" />
      </div>

      <v-row v-else class="mt-6">
        <v-col
          v-for="exhibition in pageData.children"
          :key="exhibition.node_name"
          cols="12"
          sm="6"
        >
          <preview
            :payload="exhibition"
            :to="{
              name: 'Exhibition',
              params: { ident: exhibition.node_name },
            }"
            class="mb-1 mb-sm-9"
            :tag-route="tagRoute"
          />
        </v-col>
      </v-row>

      <infinite-loader
        :fetch="fetchMore"
        :disabled="disableInfiniteScroll"
        :visible="pageData.more > 0 || !pageLoaded"
      />

      <zuruck />
    </div>

    <router-view
      v-show="!isListRoute"
      :key="$route.path"
      @page-loaded="$emit('page-loaded')"
    >
    </router-view>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import Preview from "@/components/Preview/Preview.vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";
import TabsFilters from "@/components/TabsFilters.vue";
import NewsSubscribeModal from "@/components/NewsSubscribeModal.vue";
import ExhibitionsRepository from "@/api/exhibitions.js";
import SupplementRepository from "@/api/supplement.js";
import SocialButtons from "@/components/SocialButtons.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    PageTitle,
    Preview,
    TabsFilters,
    InfiniteLoader,
    NewsSubscribeModal,
    SocialButtons,
    KkDynamicHtml,
    Zuruck,
  },
  props: {
    tag: {
      type: String,
      default: "",
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
        stat: {
          kind: {},
          year: {},
          tags: [],
        },
        more: 0,
      },
      filters: {
        kind: "actual",
        year: null,
        tags: [],
      },
      scrollTop: {
        Exhibitions: 0,
        ExhibitionsFuture: 0,
        ExhibitionsArchive: 0,
        ExhibitionsAll: 0,
      },
      offset: 0,
      loading: true,
      email: "",
      showSubscriptionDialog: false,
    };
  },
  computed: {
    disableInfiniteScroll() {
      return this.loading || this.pageData.more == 0;
    },
    isListRoute() {
      return Object.keys(this.scrollTop).includes(this.routeKind);
    },
    dic() {
      return {
        kind: Object.keys(this.pageData.stat.kind).reduce((product, kind) => {
          product[kind] = this.pageData.fields[`section_${kind}`];
          return product;
        }, {}),
      };
    },
    queryParams() {
      const params = { kind: this.filters.kind };
      if (this.filters.year != null) params.year = this.filters.year;
      if (this.filters.tags.length > 0) params.tags = this.filters.tags;
      return params;
    },
    header() {
      return this.$store.getters.getHeader;
    },
    routeKind() {
      return this.$route.meta?.kind || this.$route.name;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    routeKind(newV, oldV) {
      // + disalbed scroll behaviour in router/index.js
      if (Object.keys(this.scrollTop).includes(oldV)) {
        this.scrollTop[oldV] = window.scrollY;
      }
      if (Object.keys(this.scrollTop).includes(newV)) {
        this.$nextTick(() => {
          window.scrollTo(0, this.scrollTop[newV]);
        });
      }
      if (this.tag.length > 0) {
        this.filters.tags = [this.tag];
        this.fixKindByRoute();
      }
    },
    async tag(newV) {
      if (newV.length > 0) {
        this.filters.tags = [newV];
        await this.loadPage();
        this.$vuetify.goTo("#content-top");
      }
    },
  },
  mounted() {
    if (this.tag.length > 0) {
      this.filters.tags = [this.tag];
      this.fixKindByRoute();
    }
    this.loadPage();
  },
  methods: {
    async loadPage() {
      await this.queryItems();
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      if (this.isListRoute) {
        this.$emit("page-loaded");
      }
      await this.$nextTick();
      this.$refs.reffilter.init();
      this.loading = false;
    },
    fixKindByRoute() {
      const name = this.routeKind;
      if (name == "ExhibitionsArchive") {
        this.filters.kind = "archive";
      } else if (name == "ExhibitionsFuture") {
        this.filters.kind = "future";
      } else if (name == "ExhibitionsAll") {
        this.filters.kind = "all";
      } else if (name == "Exhibitions") {
        this.filters.kind = "actual";
      }
    },
    getRouteName() {
      switch (this.filters.kind) {
        case "archive":
          return "ExhibitionsArchiveByTag";
        case "future":
          return "ExhibitionsFutureByTag";
        case "all":
          return "ExhibitionsAllByTag";
        default:
          return "ExhibitionsByTag";
      }
    },
    tagRoute(tag) {
      return { name: this.getRouteName(), params: { tag: tag.id } };
    },
    async queryItems() {
      const { data } = await ExhibitionsRepository.getExhibitions(
        this.queryParams
      );
      this.offset = data.children.length;
      this.pageData = data;
    },
    async fetchMore() {
      this.loading = true;

      const queryParams = { ...this.queryParams };
      queryParams.offset = this.offset;

      const { data } = await ExhibitionsRepository.getExhibitions(queryParams);

      this.offset += data.children.length;
      data.children.forEach((element) => {
        const index = this.pageData.children.length;
        this.$set(this.pageData.children, index, element);
      });
      this.pageData.more = data.more;

      this.loading = false;
    },
    async subscribeClick() {
      if (!(await this.$refs.validator.validate())) return false;
      if (this.email.length == 0) return false;

      const { data } = await SupplementRepository.subscribe(this.email);
      if (data.code == 0) {
        this.showSubscriptionDialog = true;
        this.email = "";
      } else {
        alert(this.$t(`Common.feedback.error${data.code}`));
      }
    },
  },
};
</script>
