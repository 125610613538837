<template>
  <div v-if="sectionData">
    <section-title header-id="faq" :title="sectionData.fields.title" />

    <accordion :items="sectionData.children">
      <template v-slot:header="{ item }">
        {{ item.fields.title }}
      </template>
      <template v-slot="{ item }">
        <kk-dynamic-html class="mb-6" :html="item.fields.article" />
      </template>
    </accordion>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Accordion from "@/components/Accordion.vue";

export default {
  name: "VisitorRules",
  components: { SectionTitle, KkDynamicHtml, Accordion },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
