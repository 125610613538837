<template>
  <div>
    <div class="mb-6">{{ $t("Common.searchPage.categoriesFilterTitle") }}:</div>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="search-filter-item clickable pb-3"
      :class="item.id == value ? 'search-filter-item--active' : ''"
      @click="filter = item.id"
    >
      {{ item.text }} ({{ item.count }})
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchFilter",
  props: {
    value: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filter: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    itemsCompiled() {
      return this.items
        .map((item, index) => ({ id: index, text: item }))
        .filter((item) => {
          return (
            item.text.toLowerCase().includes(this.search.toLowerCase()) ||
            this.search.length == 0
          );
        });
    },
  },
};
</script>
<style scoped lang="scss">
.search-filter-item {
  &.search-filter-item--active {
    color: var(--v-mira-base);
  }
}
</style>
