import Repository from "@/plugins/axios";

export default {
  // Получить список выставок
  getExhibitions(params) {
    return Repository.get(`/exhibitions`, { params });
  },

  // Получить страницу выставки
  getExhibition(ident) {
    return Repository.get(`/exhibitions/${ident}`);
  },
};
