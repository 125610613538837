<template>
  <div class="mb-7">
    <horizontal-scroller :items="items" content-class="ml-n1">
      <template v-slot="{ item }">
        <div class="mx-2">
          <kk-link
            :link="item.fields.link"
            class="d-block item__container"
            draggable="false"
          >
            <image-extended
              :image="item.fields.image"
              class="main-image__scrollable"
            />
            <div
              v-if="item.fields.video && item.fields.video.length > 0"
              class="video__container"
            >
              <video-background :src="item.fields.video" />
            </div>
          </kk-link>
          <div class="pt-4">
            <h4 class="mb-1">{{ item.fields.title }}</h4>
            <div class="longread">{{ item.fields.subtitle }}</div>
          </div>
        </div>
      </template>
    </horizontal-scroller>
  </div>
</template>
<script>
import KkLink from "@/components/KkLink.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import ImageExtended from "@/components/ImageExtended.vue";
import VideoBackground from "@/components/VideoBackground.vue";
export default {
  name: "MainImageScroller",
  components: { KkLink, HorizontalScroller, ImageExtended, VideoBackground },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.item__container {
  position: relative;
  overflow: hidden;
}
.main-image__scrollable {
  height: 40vw;
  @media (max-width: 1023px) {
    width: 80vw;
  }
  @media (min-width: 768px) {
    height: 330px;
  }
}
</style>
