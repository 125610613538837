<template>
  <div>
    <div v-if="pageLoaded" v-show="$route.name == 'Event'" class="section">
      <image-header
        ref="refimageheader"
        :image="pageData.fields.image"
        :video="pageData.fields.video"
      />

      <kk-article
        v-if="pageLoaded"
        ref="refarticle"
        :breadcrumbs="breadcrumbsItems"
        :contents="contents"
        :ticket-settings="pageData.fields"
      >
        <h2>{{ pageData.fields.title }}</h2>
        <kk-dynamic-html :html="allHtml" />
        <v-row v-if="pageData.children.length > 0">
          <v-col cols="12">
            <h3 id="subevents" class="mb-2 mb-sm-4">{{ subEventsTitle }}</h3>
          </v-col>
          <v-col
            v-for="subEvent in pageData.children"
            :key="subEvent.node_name"
            cols="12"
            lg="6"
          >
            <preview
              :payload="subEvent"
              :to="{
                name: 'EventSub',
                params: { ident, subIdent: subEvent.node_name },
              }"
              inner-info
              full-width
            />
          </v-col>
        </v-row>
        <tag-list :tags="pageData.fields.tags" :tag-route="tagRoute" />
      </kk-article>

      <section-title
        v-if="pageData.library_set"
        :title="pageData.library_set.fields.activity_title"
        :browse-title="pageData.library_set.fields.activity_browse"
        :browse-link="{
          name: 'LibrarySet',
          params: { ident: pageData.library_set.set_node_name },
        }"
      />

      <horizontal-scroller
        v-if="pageData.library_set"
        :items="pageData.library_set.children"
        content-class="mx-n3"
        class="subsection"
      >
        <template v-slot:append>
          <horizontal-scroller-end-link-book
            :browse-title="pageData.library_set.fields.activity_browse_link"
            :browse-link="{
              name: 'LibrarySet',
              params: { ident: pageData.library_set.set_node_name },
            }"
          />
        </template>
        <template v-slot="{ item }">
          <library-book-preview
            :book="item"
            :width="$vuetify.breakpoint.smAndUp ? 286 : 214"
            in-set-list
            class="mx-3"
          />
        </template>
      </horizontal-scroller>

      <section-title
        :title="pageData.events_fields.title_other"
        :browse-title="pageData.events_fields.button_all"
        :browse-link="{ path: '/events' }"
      />

      <horizontal-scroller :items="pageData.more" content-class="mx-n3">
        <template v-slot:append>
          <horizontal-scroller-end-link
            :browse-title="pageData.events_fields.button_all"
            :browse-link="{ path: '/events' }"
          />
        </template>
        <template v-slot="{ item }">
          <preview
            :payload="item"
            :to="{ name: 'Event', params: { ident: item.node_name } }"
            :tag-route="tagRouteInList"
            inner-info
            class="mx-3"
          />
        </template>
      </horizontal-scroller>
    </div>

    <router-view
      v-show="$route.name == 'EventSub'"
      :key="$route.path"
      @page-loaded="$emit('page-loaded')"
    >
    </router-view>
  </div>
</template>

<script>
import EventsRepository from "@/api/events.js";
import KkArticle from "@/components/Article/KkArticle.vue";
import ImageHeader from "@/components/ImageHeader.vue";
import TagList from "@/components/Tags/TagList.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import Preview from "@/components/Preview/Preview.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import HorizontalScrollerEndLink from "@/components/HorizontalScrollerEndLink.vue";
import HorizontalScrollerEndLinkBook from "@/components/HorizontalScrollerEndLinkBook.vue";
import LibraryBookPreview from "./Library/LibraryBookPreview.vue";

export default {
  components: {
    KkArticle,
    ImageHeader,
    TagList,
    Preview,
    SectionTitle,
    KkDynamicHtml,
    HorizontalScroller,
    HorizontalScrollerEndLink,
    HorizontalScrollerEndLinkBook,
    LibraryBookPreview,
  },
  props: {
    ident: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
        main_fields: {},
        events_fields: {},
        kind: "actual",
        more: [],
      },
      scrollTop: {
        Event: 0,
      },
    };
  },
  computed: {
    allHtml() {
      return `${this.pageData.events_fields.all_child_text}${this.pageData.fields.article}`;
    },
    breadcrumbsItems() {
      const { kind } = this.pageData;
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.events_fields.title || "",
          to: { path: "/events" },
          exact: true,
          disabled: false,
        },
        ...(kind != "actual"
          ? [
              {
                text: this.pageData.events_fields[`section_${kind}`] || "",
                to: { path: `/events/${kind}` },
                exact: true,
                disabled: false,
              },
            ]
          : []),
      ];
    },
    contents() {
      if (this.pageData.children.length > 0) {
        return {
          ...this.pageData.fields.contents,
          subevents: this.subEventsTitle,
        };
      } else {
        return this.pageData.fields.contents;
      }
    },
    subEventsTitle() {
      return (
        this.pageData.fields.subs_title ||
        this.pageData.events_fields.subs_title
      );
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    "$route.name"(newV, oldV) {
      // + disalbed scroll behaviour in router/index.js
      if (Object.keys(this.scrollTop).includes(oldV)) {
        this.scrollTop[oldV] = window.scrollY;
      }
      if (Object.keys(this.scrollTop).includes(newV)) {
        this.onAllLoaded();
        this.$nextTick(() => {
          window.scrollTo(0, this.scrollTop[newV]);
        });
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await EventsRepository.getEvent(this.ident);
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      if (this.$route.name == "Event") {
        this.onAllLoaded();
      }
    },
    onAllLoaded() {
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
        this.$refs.refimageheader.initObserver();
      });
    },
    tagRoute(tag) {
      const name = this.pageData.kind == "archive" ? "EventsArchive" : "Events";
      return { name, params: { tag: tag.id } };
    },
    tagRouteInList(tag) {
      return { name: "Events", params: { tag: tag.id } };
    },
  },
};
</script>
