<template>
  <!-- Диалог просмотра изображения подэлемента с описанием -->
  <v-dialog :value="show" content-class="zoom-dialog" :overlay-opacity="0.54">
    <div
      class="zoom-control zoom-close clickable"
      @click="$emit('close-dialog')"
    >
      <v-icon size="36">$vuetify.icons.Close</v-icon>
    </div>
    <div class="zoom-title primary">
      {{ innerItem?.fields?.title || "" }}
    </div>
    <div v-if="innerItem?.fields" class="zoom-static-image">
      <img
        :src="
          innerItem.fields.image?.i_size_500 || innerItem.fields.image?.i_main
        "
        alt=""
      />
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "CollectionSubPieceDialog",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    ident: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      innerItem: null,

      /*
       * Кэширование для навигации туда-сюда-обратно,
       * в случае если загрузили какой-то элемент по прямой ссылке
       * и его ещё нет в прокрученном списке
       */
      itemsCache: {},
    };
  },

  watch: {
    show(newV) {
      if (newV) {
        if (this.item) {
          this.itemsCache[this.ident] = this.item;
          this.innerItem = this.item;
        } else {
          this.innerItem = this.itemsCache[this.ident] || null;
        }
      }
    },
  },
};
</script>
