<template>
  <div class="video__container" :style="videoStyle">
    <video
      v-if="src && src.length > 0"
      ref="refvideo"
      class="video"
      autoplay
      muted
      loop
      playsinline
      @canplaythrough="onCanPlayThrough"
    >
      <source ref="refvideosrc" :src="src" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: "VideoBackground",
  props: {
    src: {
      type: String,
      default: "",
    },
    fade: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      canPlay: false,
    };
  },
  computed: {
    videoStyle() {
      return {
        opacity: this.canPlay && this.src.length > 0 && !this.fade ? "1" : "0",
      };
    },
  },
  watch: {
    src(newVal, oldVal) {
      if (oldVal.length > 0 && newVal.length > 0) {
        this.onVideoReload();
      }
    },
  },
  methods: {
    onCanPlayThrough() {
      this.canPlay = true;
    },
    async onVideoReload() {
      await this.$nextTick();
      const video = this.$refs.refvideo;
      const source = this.$refs.refvideosrc;
      if (video) {
        video.pause();
        source.setAttribute("src", this.src);
        video.load();
        video.play();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.video__container {
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 300%;
  transition: opacity 0.3s ease-in;
  z-index: 2;
  .video {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    min-height: 50%;
    min-width: 50%;
    width: 100%;
  }
}
</style>
