<template>
  <v-breadcrumbs
    v-dragscroll.x
    class="py-0 section__title kk-breadcrumbs"
    :items="items"
    :divider="divider"
    :dark="dark"
    draggable="false"
  >
    <slot name="default"></slot>
    <template v-slot:item="{ item }">
      <router-link
        :to="item.to"
        class="v-breadcrumbs__item"
        draggable="false"
        >{{ item.text }}</router-link
      >
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "KkBreadcrumbs",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    divider: {
      type: String,
      default: "—",
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
#app.v-application {
  .kk-breadcrumbs.v-breadcrumbs {
    flex-wrap: nowrap;
    overflow-x: hidden;
    white-space: nowrap;
    user-select: none;
    cursor: grab;

    .v-breadcrumbs__item,
    .v-breadcrumbs__divider {
      // "base" font typography
      font-size: 20px;
      @media (max-width: 1024px) {
        font-size: 16px;
      }
    }
    &.theme--light a.v-breadcrumbs__item {
      color: var(--v-textLight-base);
    }
    &.theme--dark a.v-breadcrumbs__item {
      color: white;
    }
    .v-breadcrumbs__item--disabled {
      color: var(--v-textLight-base) !important;
    }
  }
}
</style>
