<template>
  <fragment>
    <transition name="fadeapp">
      <v-app
        v-show="allLoaded"
        :class="showGosuslugiWidget ? 'gosuslugi-widget--active' : ''"
      >
        <header-widget-gosuslugi
          v-if="systemSettings.system_settings_gosuslugi"
          :section-data="systemSettings.system_settings_gosuslugi.fields"
          @close="gosuslugiWidgetEnabled = false"
        />
        <Header
          @menu-open="gosuslugiWidgetVisible = false"
          @menu-close="gosuslugiWidgetVisible = true"
        />
        <div class="content padding-from-body padding-from-top">
          <transition name="fade" mode="out-in">
            <router-view :key="routeKey" @page-loaded="onPageLoaded" />
          </transition>
          <transition name="fade" mode="out-in">
            <preloader-internal v-show="internalLoading" />
          </transition>
        </div>
        <Footer v-show="!internalLoading" />
        <kkg-notification
          v-if="notificationAppear.length > 0"
          :notification-data="notificationAppear"
          @close="hideNotification"
        />
      </v-app>
    </transition>
    <transition name="fade">
      <preloader v-show="!allLoaded" ref="preloader" @finalize="showContent" />
    </transition>
  </fragment>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
import moment from "moment";
import KkgNotification from "@/components/KkgNotification.vue";
import HeaderWidgetGosuslugi from "./components/Header/HeaderWidgetGosuslugi.vue";
import PreloaderInternal from "./components/PreloaderInternal.vue";
import Preloader from "@/components/Preloader.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    KkgNotification,
    HeaderWidgetGosuslugi,
    PreloaderInternal,
    Preloader,
  },
  data() {
    return {
      appInitiated: false,
      pageLoaded: false,
      allLoaded: false,
      gosuslugiWidgetEnabled: true,
      gosuslugiWidgetVisible: true,
      gosuslugiWidgetAtTop: true,
    };
  },
  computed: {
    routeKey() {
      const to = this.$route;
      return to.matched && to.matched.length > 1 ? to.matched[0].path : to.path;
    },
    showGosuslugiWidget() {
      return (
        this.systemSettings.system_settings_gosuslugi &&
        this.gosuslugiWidgetVisible &&
        this.gosuslugiWidgetEnabled &&
        this.gosuslugiWidgetAtTop &&
        this.$route.path == "/"
      );
    },
    systemSettings() {
      return this.$store.getters.getSystem;
    },
    header() {
      return this.$store.getters.getHeader;
    },
    notificationAppear() {
      return this.allLoaded ? this.systemSettings.fields.notification : "";
    },
    internalLoading() {
      return this.$store.getters.getInternalLoading;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.initApp();
      }
    },
  },
  created() {
    this.initApp();
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    async initApp() {
      const local = this;
      const promises = [
        Promise.resolve(local.$store.dispatch("fetchBase")),
        Promise.resolve(local.$store.dispatch("fetchServices")),
      ];

      Promise.all(promises)
        .then(() => {
          this.initiateUserScripts();
          const currentLocale = local.$store.getters.getLang;
          local.$vuetify.lang.current = currentLocale;
          local.$i18n.locale = currentLocale;
          moment.locale(currentLocale);

          const html = document.querySelector("html");
          html.setAttribute("lang", currentLocale);

          local.appInitiated = true;
          local.checkLoaded();
        })
        .catch(() => {
          return true;
        });
    },
    onPageLoaded() {
      this.$store.commit("setFinishInternalLoading");
      this.pageLoaded = true;
      this.checkLoaded();
    },
    checkLoaded() {
      if (this.appInitiated && this.pageLoaded) {
        document.getElementById("app").scrollIntoView({ behavior: "instant" });
        if (this.$refs.preloader) {
          this.$refs.preloader.finish();
        }
      }
    },
    showContent() {
      this.allLoaded = true;
      this.$nextTick(() => {
        if (location.hash?.length) {
          const element = document.getElementById(location.hash.substring(1));
          if (element) {
            element.scrollIntoView({ behavior: "instant" });
          }
        }
        this.$store.commit("setFinishMainLoading");
      });
    },
    hideNotification() {
      this.$store.commit("hideNotification");
    },
    onHeaderMenuToggle() {
      this.gosuslugiWidgetActive = false;
    },
    onScroll() {
      this.gosuslugiWidgetAtTop = window.scrollY == 0;
    },
    initiateUserScripts() {
      const scriptsList = this.systemSettings.system_settings_scripts.children;
      scriptsList.forEach((script) => {
        const template = document.createElement("template");
        template.innerHTML = script.fields.code.trim();
        const oldScript = template.content.firstChild;
        document.head.appendChild(oldScript);

        const newScript = document.createElement("script");
        Array.from(oldScript.attributes).forEach((attr) =>
          newScript.setAttribute(attr.name, attr.value)
        );
        newScript.appendChild(document.createTextNode(oldScript.innerHTML));
        document.head.replaceChild(newScript, oldScript);
      });
    },
  },
};
</script>

<style lang="scss">
@import "./assets/styles/styles.scss";
.fadeapp-enter-active,
.fadeapp-leave-active {
  transition-property: opacity;
  transition-duration: 1s;
}

.fadeapp-enter,
.fadeapp-leave-active {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  background-color: var(--v-bgPrimary-base);
}

.content {
  width: 100%;
  height: 100%;
}
</style>
