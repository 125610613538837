<template>
  <div v-if="sectionData">
    <h3 id="phones" class="mb-5 mb-sm-10">
      {{ sectionData.fields.title }}
    </h3>
    <div class="subsection">
      <v-row
        v-for="item in sectionData.children"
        :key="item.node_name"
        no-gutters
      >
        <v-col class="phone">
          <a class="link" :href="`tel:${stripPhone(item.fields.phone)}`">{{
            item.fields.phone
          }}</a>
        </v-col>
        <v-col class="mb-3 mb-sm-6">{{ item.fields.title }}</v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContactsPhones",
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    stripPhone() {
      return (phone) => {
        return phone.replace(/\s|-|\(|\)/g, "");
      };
    },
  },
};
</script>
<style scoped lang="scss">
.phone {
  min-width: 160px;
  max-width: 160px;
  @media (min-width: 1024px) {
    min-width: 216px;
    max-width: 216px;
  }
}
</style>
