<template>
  <div v-if="pageLoaded" class="section">
    <image-header
      ref="refimageheader"
      :image="pageData.fields.image"
      :glow="pageData.fields.glow"
      :video="pageData.fields.video"
    />

    <kk-article
      ref="refarticle"
      :breadcrumbs="breadcrumbsItems"
      :contents="pageData.fields.contents"
      :ticket-settings="pageData.fields"
      dense
      class="subsection"
    >
      <h2>{{ pageData.fields.title }}</h2>
      <kk-dynamic-html :html="allHtml" />
      <tag-list :tags="pageData.fields.tags" :tag-route="tagRoute" />
    </kk-article>

    <section-title
      v-if="pageData.library_set"
      :title="pageData.library_set.fields.activity_title"
      :browse-title="pageData.library_set.fields.activity_browse"
      :browse-link="{
        name: 'LibrarySet',
        params: { ident: pageData.library_set.set_node_name },
      }"
    />

    <horizontal-scroller
      v-if="pageData.library_set"
      :items="pageData.library_set.children"
      content-class="mx-n3"
      class="subsection"
    >
      <template v-slot:append>
        <horizontal-scroller-end-link-book
          :browse-title="pageData.library_set.fields.activity_browse_link"
          :browse-link="{
            name: 'LibrarySet',
            params: { ident: pageData.library_set.set_node_name },
          }"
        />
      </template>
      <template v-slot="{ item }">
        <library-book-preview
          :book="item"
          :width="$vuetify.breakpoint.smAndUp ? 286 : 214"
          in-set-list
          class="mx-3"
        />
      </template>
    </horizontal-scroller>

    <reference-widget :page-data="pageData" />

    <section-title
      :title="pageData.tours_fields.title_other"
      :browse-title="pageData.tours_fields.button_all"
      :browse-link="{ name: 'PupilTours' }"
    />

    <horizontal-scroller
      v-if="pageData.more.length > 0"
      :items="pageData.more"
      content-class="mx-n3"
      :disabled="pageData.more.length == 1"
    >
      <template v-slot="{ item }">
        <preview
          :payload="item"
          :to="{ name: 'PupilTour', params: { ident: item.node_name } }"
          inner-info
          :glow="item.fields.glow"
          class="mx-3 pb-6"
          :tag-route="tagRouteInList"
        />
      </template>
    </horizontal-scroller>
  </div>
</template>

<script>
import ToursRepository from "@/api/tours.js";
import KkArticle from "@/components/Article/KkArticle.vue";
import ImageHeader from "@/components/ImageHeader.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import Preview from "@/components/Preview/Preview.vue";
import TagList from "@/components/Tags/TagList.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ReferenceWidget from "@/components/ReferenceWidget.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import HorizontalScrollerEndLinkBook from "@/components/HorizontalScrollerEndLinkBook.vue";
import LibraryBookPreview from "./Library/LibraryBookPreview.vue";

export default {
  components: {
    KkArticle,
    KkDynamicHtml,
    ImageHeader,
    HorizontalScroller,
    SectionTitle,
    Preview,
    TagList,
    ReferenceWidget,
    HorizontalScrollerEndLinkBook,
    LibraryBookPreview,
  },
  props: {
    ident: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        main_fields: {},
        tours_fields: {},
        more: [],
        kind: "actual",
        exhibitions_section: { fields: {}, children: [] },
        events_section: { fields: {}, children: [] },
      },
    };
  },
  computed: {
    allHtml() {
      return `${this.pageData.tours_fields.all_child_text}${this.pageData.fields.article}`;
    },
    breadcrumbsItems() {
      const { kind } = this.pageData;
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.tours_fields.title || "",
          to: { name: "PupilTours" },
          exact: true,
          disabled: false,
        },
        ...(kind != "actual"
          ? [
              {
                text: this.pageData.tours_fields[`section_${kind}`] || "",
                to: { path: `/pupil-tours/${kind}` },
                exact: true,
                disabled: false,
              },
            ]
          : []),
      ];
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await ToursRepository.getPupilTour(this.ident);
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
        this.$refs.refimageheader.initObserver();
      });
    },
    getRouteName() {
      switch (this.pageData.kind) {
        case "archive":
          return "PupilToursArchiveByTag";
        case "all":
          return "PupilToursAllByTag";
        default:
          return "PupilToursByTag";
      }
    },
    tagRoute(tag) {
      return { name: this.getRouteName(), params: { tag: tag.id } };
    },
    tagRouteInList(tag) {
      return { name: "PupilToursByTag", params: { tag: tag.id } };
    },
  },
};
</script>
