<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.0223 6.71094L30.3087 15.9995L21.0223 25.288L19.5119 23.7776L26.2255 17.0661H2.13379V14.9328H26.2255L19.5119 8.22134L21.0223 6.71094Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
  },
};
</script>
