<template>
  <div class="base-2">
    <span>{{ workHours }}</span>
    <router-link
      :to="{ name: 'Visitors', hash: '#operating-hours' }"
      class="menu-closure link"
    >
      {{ sectionData.fields.opened_link }}
    </router-link>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timer: null,
      day: "",
    };
  },
  computed: {
    workHours() {
      return this.sectionData.fields[`opened_${this.day}`] || "";
    },
  },
  mounted() {
    this.day = moment().day();
    this.startTimer();
  },
  destroyed() {
    this.stopTimer();
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.day = moment().day();
      }, 10000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
  },
};
</script>
