<template>
  <div v-if="pageLoaded">
    <div v-if="isListRoute" class="section">
      <page-title :title="pageData.fields.title" />

      <kk-dynamic-html
        v-if="pageData.fields.article"
        :html="pageData.fields.article"
        class="subsection"
      />

      <v-row no-gutters>
        <div id="news-top-page" />
        <v-col cols="12" lg="9">
          <news-list-item
            v-for="newsItem in pageData.children"
            :key="newsItem.node_name"
            :payload="newsItem"
          />

          <infinite-loader
            :fetch="fetchMore"
            :disabled="disableInfiniteScroll"
            :visible="pageData.more > 0 || !pageLoaded"
          />
        </v-col>
        <v-col
          cols=""
          lg="3"
          class="order-first order-lg-last year-col mb-4 mb-sm-8"
        >
          <div class="year-sticky">
            <v-select
              v-model="filters.year"
              dense
              outlined
              clearable
              :items="selectableYears"
              item-value="id"
              item-text="text"
              :menu-props="{
                nudgeBottom: $vuetify.breakpoint.smAndUp ? 53 : 40,
                contentClass: 'kk-select-menu',
              }"
              append-icon="$vuetify.icons.ChevronDown"
              :placeholder="$t('Common.selectYear')"
              hide-details
              width="100%"
              tabindex="0"
              @change="onChangeYear"
            />
          </div>
        </v-col>
      </v-row>

      <zuruck />
    </div>

    <router-view
      v-show="!isListRoute"
      :key="$route.path"
      @page-loaded="$emit('page-loaded')"
    >
    </router-view>
  </div>
</template>

<script>
import NewsRepository from "@/api/news.js";
import PageTitle from "@/components/PageTitle.vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";
import NewsListItem from "@/components/NewsListItem.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    PageTitle,
    InfiniteLoader,
    NewsListItem,
    KkDynamicHtml,
    Zuruck,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
        stat: {
          year: {},
        },
        more: 0,
      },
      filters: {
        year: null,
      },
      offset: 0,
      loading: true,
      scrollTop: {
        NewsList: 0,
      },
    };
  },
  computed: {
    disableInfiniteScroll() {
      return this.loading || this.pageData.more == 0;
    },
    isListRoute() {
      return Object.keys(this.scrollTop).includes(this.$route.name);
    },
    selectableYears() {
      if (!this.pageData.stat.year) return [];
      const entries = Object.entries(this.pageData.stat.year);
      if (entries.length <= 1) return [];
      return entries
        .sort((y1, y2) => parseInt(y2[0]) - parseInt(y1[0]))
        .map((entry) => ({ id: entry[0], text: entry[0], cnt: entry[1] }));
    },
    queryParams() {
      const params = {};
      if (this.filters.year != null) params.year = this.filters.year;
      return params;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    "$route.name"(newV, oldV) {
      // + disalbed scroll behaviour in router/index.js
      if (Object.keys(this.scrollTop).includes(oldV)) {
        this.scrollTop[oldV] = window.scrollY;
      }
      if (Object.keys(this.scrollTop).includes(newV)) {
        this.$nextTick(() => {
          window.scrollTo(0, this.scrollTop[newV]);
        });
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      await this.queryItems();
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      if (this.isListRoute) {
        this.$emit("page-loaded");
      }
      this.loading = false;
    },
    async queryItems() {
      const { data } = await NewsRepository.getNewsList(this.queryParams);
      this.offset = data.children.length;
      this.pageData = data;
    },
    async fetchMore() {
      this.loading = true;

      const queryParams = { ...this.queryParams };
      queryParams.offset = this.offset;

      const { data } = await NewsRepository.getNewsList(queryParams);

      this.offset += data.children.length;
      data.children.forEach((element) => {
        const index = this.pageData.children.length;
        this.$set(this.pageData.children, index, element);
      });
      this.pageData.more = data.more;

      this.loading = false;
    },
    onChangeYear() {
      document
        .getElementById("news-top-page")
        .scrollIntoView({ behavior: "instant" });
      this.queryItems();
    },
  },
};
</script>
<style scoped lang="scss">
.year-col {
  .year-sticky {
    position: sticky;
    top: 100px;
  }
}
@media (max-width: 1439px) {
  .year-col {
    max-width: 220px;
    min-width: 220px;
  }
  #news-top-page {
    margin-top: -110px;
    padding-top: 110px;
  }
}
@media (min-width: 1440px) {
  .year-col {
    padding-left: 100px !important;
  }
  #news-top-page {
    margin-top: -60px;
    padding-top: 60px;
  }
}
</style>
