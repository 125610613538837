<template>
  <div v-if="sectionData" class="subsection">
    <section-title
      header-id="exhibitions"
      :title="sectionData.fields.title"
      :browse-title="sectionData.fields.browse_all"
      :browse-link="browseLink"
    />

    <horizontal-scroller
      :items="sectionData.children"
      content-class="mx-n3"
      stick-to="left"
      class="mb-5 mb-sm-10"
    >
      <template v-slot:append>
        <horizontal-scroller-end-link
          :browse-title="sectionData.fields.browse_all"
          :browse-link="browseLink"
        />
      </template>
      <template v-slot="{ item }">
        <preview
          :payload="item"
          :to="{ name: 'Exhibition', params: { ident: item.node_name } }"
          :tag-route="tagRouteInList"
          class="mx-3"
        />
      </template>
    </horizontal-scroller>

    <kk-dynamic-html :html="sectionData.fields.article" />
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import Preview from "@/components/Preview/Preview.vue";
import HorizontalScrollerEndLink from "@/components/HorizontalScrollerEndLink.vue";

export default {
  name: "AboutExhibitions",
  components: {
    SectionTitle,
    KkDynamicHtml,
    HorizontalScroller,
    Preview,
    HorizontalScrollerEndLink,
  },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    browseLink() {
      if (this.sectionData.fields.tags_select.length == 0) {
        return { path: "/exhibitions" };
      } else {
        const tag = this.sectionData.fields.tags_select[0];
        return { name: "Exhibitions", params: { tag: tag.id } };
      }
    },
  },
  methods: {
    tagRouteInList(tag) {
      return { name: "Exhibitions", params: { tag: tag.id } };
    },
  },
};
</script>
