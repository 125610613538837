<template>
  <div>
    <div>
      <v-text-field
        v-model="filters.titleOrAuthor"
        block
        outlined
        :placeholder="$t('Common.search')"
        append-icon="$vuetify.icons.Search"
        hide-details
        @change="$emit('change')"
      />
    </div>

    <v-list expand flat subheader class="menu-expansion-list">
      <v-list-group v-if="filtersCount == 0" color="textBase" disabled>
        <template v-slot:activator>
          <v-list-item-content class="ml-n4">
            {{ $t("Common.filtersSelectedNone") }}
          </v-list-item-content>
        </template>
        <template v-slot:appendIcon>&nbsp;</template>
      </v-list-group>
      <v-list-group v-else v-model="groups.line" color="textBase">
        <template v-slot:activator>
          <v-list-item-content class="ml-n4">
            {{ $t("Common.filtersSelectedCount", { n: filtersCount }) }}
          </v-list-item-content>
        </template>
        <template v-slot:appendIcon>
          <v-icon class="chevron mr-n4">$vuetify.icons.ChevronDown</v-icon>
        </template>
        <collection-filters-line
          v-model="filters"
          :meta="meta"
          :dic="dic"
          @change="$emit('change')"
        />
      </v-list-group>

      <v-list-group v-model="groups.categories" color="textBase">
        <template v-slot:activator>
          <v-list-item-content class="ml-n4">
            {{ dic.filters_category }}
          </v-list-item-content>
        </template>
        <template v-slot:appendIcon>
          <v-icon class="chevron mr-n4">$vuetify.icons.ChevronDown</v-icon>
        </template>
        <check-box-list-filter
          v-model="filters.categories"
          :items="meta.categories"
          @change="$emit('change')"
        />
      </v-list-group>

      <v-list-group v-model="groups.years" color="textBase">
        <template v-slot:activator>
          <v-list-item-content class="ml-n4">
            {{ dic.filters_year }}
          </v-list-item-content>
        </template>
        <template v-slot:appendIcon>
          <v-icon class="chevron mr-n4">$vuetify.icons.ChevronDown</v-icon>
        </template>
        <year-range-selector
          v-model="filters.years"
          :limits="meta.years"
          class="pr-3 pl-1 mb-3"
          @change="$emit('change')"
        />
      </v-list-group>

      <v-list-group v-model="groups.authors" color="textBase">
        <template v-slot:activator>
          <v-list-item-content class="ml-n4">
            {{ dic.filters_author }}
          </v-list-item-content>
        </template>
        <template v-slot:appendIcon>
          <v-icon class="chevron mr-n4">$vuetify.icons.ChevronDown</v-icon>
        </template>
        <check-box-list-filter
          v-model="filters.authors"
          :items="meta.authors"
          @change="$emit('change')"
        />
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
import CheckBoxListFilter from "@/components/Controls/CheckBoxListFilter.vue";
import YearRangeSelector from "@/components/Controls/YearRangeSelector.vue";
import CollectionFiltersLine from "./CollectionFiltersLine.vue";

export default {
  name: "CollectionFiltersControlsMobile",
  components: { CheckBoxListFilter, YearRangeSelector, CollectionFiltersLine },
  props: {
    value: {
      type: Object,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    dic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      groups: {
        line: false,
        categories: false,
        years: false,
        authors: false,
      },
    };
  },
  computed: {
    filters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filtersCount() {
      let count = 0;
      const { years, authors, categories } = this.filters;
      const limits = this.meta.years;
      if (years[0] > limits[0] || years[1] < limits[1]) count++;
      count += categories.length;
      count += authors.length;
      return count;
    },
  },
  watch: {
    filtersCount(newVal) {
      if (newVal == 0) {
        this.groups.line = false;
      }
    },
  },
};
</script>
