<template>
  <v-chip
    color="bgSecondary"
    text-color="textBase-base"
    v-bind="$attrs"
    label
    class="tag-chip base my-1"
    v-on="$listeners"
  >
    <slot></slot>
  </v-chip>
</template>

<script>
export default {};
</script>
