<template>
  <div v-if="pageLoaded" class="section">
    <kk-article
      ref="refarticle"
      :breadcrumbs="breadcrumbsItems"
      :contents="pageData.fields.contents"
      dense
      class="subsection"
    >
      <v-row no-gutters class="align-start mb-3 mb-sm-6">
        <v-col cols="12" xl="" class="flex-grow-0 mb-6 mb-xl-0">
          <v-avatar :size="$vuetify.breakpoint.xlOnly ? 100 : 280">
            <image-clipped
              :image="pageData.fields.image"
              :fit="$vuetify.breakpoint.xlOnly ? 100 : 280"
            />
          </v-avatar>
        </v-col>
        <v-col class="flex-grow-1 pl-xl-6">
          <h2 class="mb-3">
            {{ pageData.fields.title }}
          </h2>
          <div class="textLight--text">
            {{ formatDate(pageData.fields.date) }}
          </div>
        </v-col>
      </v-row>

      <kk-dynamic-html :html="pageData.fields.article" />
    </kk-article>

    <reference-widget :page-data="pageData" />

    <section-title
      :title="pageData.news_fields.title_other"
      :browse-title="pageData.news_fields.button_all"
      :browse-link="{ name: 'NewsList' }"
    />

    <news-list-item
      v-for="newsItem in pageData.more"
      :key="newsItem.node_name"
      :payload="newsItem"
    />
  </div>
</template>

<script>
import NewsRepository from "@/api/news.js";
import KkArticle from "@/components/Article/KkArticle.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import NewsListItem from "@/components/NewsListItem.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ReferenceWidget from "@/components/ReferenceWidget.vue";
import moment from "moment";
import ImageClipped from "../components/ImageClipped.vue";

export default {
  components: {
    KkArticle,
    SectionTitle,
    NewsListItem,
    KkDynamicHtml,
    ImageClipped,
    ReferenceWidget,
  },
  props: {
    ident: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        main_fields: {},
        news_fields: {},
        more: [],
        exhibitions_section: { fields: {}, children: [] },
        events_section: { fields: {}, children: [] },
      },
    };
  },
  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.news_fields.title || "",
          to: { name: "NewsList" },
          exact: true,
          disabled: false,
        },
      ];
    },
    formatDate() {
      return (date) => moment(date).format("DD.MM.yyyy");
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await NewsRepository.getNewsItem(this.ident);
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
  },
};
</script>
