<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <rect x="2" y="2" width="8" height="8" fill="currentColor" />
    <rect x="2" y="14" width="8" height="8" fill="currentColor" />
    <rect x="14" y="2" width="8" height="8" fill="currentColor" />
    <rect x="14" y="14" width="8" height="8" fill="currentColor" />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
