<template>
  <transition name="slide">
    <kk-scrollbar v-show="opened" class="menu" :style="kkScrollbarStyle">
      <header-hours
        :section-data="sectionData"
        class="d-block d-sm-none mt-5 mb-5"
      />
      <header-buy-ticket
        :section-data="sectionData"
        class="d-inline-block d-sm-none mb-5"
      />
      <header-lang-selector
        class="d-flex d-sm-none mb-5"
        :langs="langs"
        :locale="locale"
        @change-locale="onChangeLocale"
      />
      <header-search-mobile
        class="d-block d-sm-none mb-10"
        @on-search="onSearch"
      />

      <header-menu-wide-list
        class="d-none d-sm-flex"
        :section-data="sectionData"
      />
      <header-menu-expansion-list
        class="d-block d-sm-none mb-10"
        :section-data="sectionData"
      />

      <v-row>
        <v-col cols="12" md="6" class="mb-6 mb-sm-0">
          <v-row no-gutters>
            <v-col cols="12" class="mb-3">
              {{ sectionData.fields.subscribe_text }}
            </v-col>
            <v-col cols="12" sm="" lg="6" class="pr-0 pr-sm-6 mb-3">
              <validation-observer ref="validator">
                <validation-provider
                  v-slot="{ errors }"
                  rules="email"
                  mode="eager"
                  :name="$t('Common.emailPlaceholder')"
                >
                  <v-text-field
                    id="form-text-field-email"
                    v-model="email"
                    block
                    outlined
                    :placeholder="$t('Common.emailPlaceholder')"
                    hide-details="auto"
                    :error-messages="errors"
                  />
                </validation-provider>
              </validation-observer>
            </v-col>
            <v-col class="input-email-col flex-sm-grow-0">
              <v-btn outlined tile block @click="subscribeClick">
                {{ sectionData.fields.subscribe_button }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <social-buttons height="35" space-between="24" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" offset-lg="3" lg="3" class="base-2">
          <div v-html="sectionData.fields.contacts" />
        </v-col>
      </v-row>

      <news-subscribe-modal v-model="showSubscriptionDialog" />
    </kk-scrollbar>
  </transition>
</template>

<script>
import HeaderMenuExpansionList from "./HeaderMenuExpansionList.vue";
import HeaderMenuWideList from "./HeaderMenuWideList.vue";
import HeaderHours from "./HeaderHours.vue";
import HeaderBuyTicket from "./HeaderBuyTicket.vue";
import HeaderLangSelector from "./HeaderLangSelector.vue";
import HeaderSearchMobile from "./HeaderSearchMobile.vue";
import SocialButtons from "@/components/SocialButtons.vue";
import NewsSubscribeModal from "@/components/NewsSubscribeModal.vue";
import SupplementRepository from "@/api/supplement.js";

export default {
  components: {
    HeaderMenuExpansionList,
    HeaderMenuWideList,
    HeaderHours,
    HeaderBuyTicket,
    HeaderLangSelector,
    HeaderSearchMobile,
    SocialButtons,
    NewsSubscribeModal,
  },
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
    sectionData: {
      type: Object,
      required: true,
    },
    langs: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    addPadding: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      search: "",
      email: "",
      showSearch: false,
      showSubscriptionDialog: false,
    };
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    kkScrollbarStyle() {
      const padding = this.$vuetify.breakpoint.smAndUp ? 40 : 16;
      return {
        "padding-right": `${padding + this.addPadding}px`,
      };
    },
  },
  methods: {
    async subscribeClick() {
      if (!(await this.$refs.validator.validate())) return false;
      if (this.email.length == 0) return false;

      const { data } = await SupplementRepository.subscribe(this.email);
      if (data.code == 0) {
        this.showSubscriptionDialog = true;
        this.email = "";
      } else {
        alert(this.$t(`Common.feedback.error${data.code}`));
      }
    },
    onChangeLocale(locale, langEnabled) {
      this.$emit("change-locale", locale, langEnabled);
    },
    onSearch(searchTerm) {
      this.$emit("on-search", searchTerm);
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  position: fixed;
  top: 42px;
  right: 0;
  left: 0;
  background: var(--v-bgPrimary-base);
  z-index: 1;
  height: calc(100vh - 42px);
  overflow-y: hidden;
  padding-left: 16px;
}

@media (min-width: 1024px) {
  .menu {
    top: 79px;
    padding-left: 40px;
    height: calc(100vh - 79px);
  }
}

.input-email-col {
  margin-bottom: 32px;
}

@media (min-width: 1280px) {
  .input-email-col {
    margin-bottom: 108px;
  }
}

.slide-enter-active {
  transition-property: height;
  transition-property: margin-top;
  transition-duration: 0.675s;
  transition-timing-function: cubic-bezier(0.3, 0.1, 0.3, 1);
}

.slide-leave-active {
  transition-property: height;
  transition-property: margin-top;
  transition-duration: 0.675s;
  transition-timing-function: cubic-bezier(0.3, 0.1, 0.3, 1);
}

.slide-enter-to,
.slide-leave {
  height: 100vh;
  margin-top: 0;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  height: 100vh;
  margin-top: -100vh;
  overflow: hidden;
}
</style>
