import Repository from "@/plugins/axios";

export default {
  // Получить список проектов
  getProjects(params) {
    return Repository.get(`/projects`, { params });
  },

  // Получить страницу «Библиотека»
  getLibrary(params) {
    return Repository.get(`/library`, { params });
  },

  // Получить страницу «Сувениры»
  getSouvenirs(params) {
    return Repository.get(`/souvenirs`, { params });
  },

  // Получить список публикаций
  getPublications(params) {
    return Repository.get(`/publications`, { params });
  },
};
