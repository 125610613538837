<template>
  <div
    class="kk-zuruck"
    :class="{
      'kk-zuruck--hidden': !visible,
      'kk-zuruck--with-aux-bar': withAuxBar,
    }"
  >
    <kk-arrow :rotate="270" @click="onZuruck" />
  </div>
</template>

<script>
import KkArrow from "@/components/KkArrow.vue";
import { scrollTo } from "seamless-scroll-polyfill";

export default {
  name: "Zuruck",
  components: { KkArrow },
  props: {
    withAuxBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      scrollPos: 0,
    };
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    onScroll() {
      const delta = window.scrollY - this.scrollPos;
      this.scrollPos = window.scrollY;
      if (delta > 0 || this.scrollPos == 0) {
        this.visible = false;
      } else if (delta < 0) {
        this.visible = true;
      }
    },
    onZuruck() {
      scrollTo(window, { top: 0, behavior: "smooth" });
    },
  },
};
</script>
<style scoped lang="scss">
.kk-zuruck {
  position: fixed;
  right: 16px;
  bottom: 16px;
  width: 36px;
  height: 36px;
  z-index: 8;
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  &.kk-zuruck--with-aux-bar {
    bottom: 74px;
  }
  &.kk-zuruck--hidden {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
}

@media (min-width: 1024px) {
  .kk-zuruck {
    right: 40px;
    width: 52px;
    height: 52px;
    &,
    &.kk-zuruck--with-aux-bar {
      bottom: 40px;
    }
  }
}
</style>
