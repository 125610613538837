<template>
  <div>
    <kk-dynamic-html
      v-if="blockData.fields.article_before"
      :html="blockData.fields.article_before"
      class="mb-4 mb-md-8"
    />

    <v-row class="mb-4 mb-md-8">
      <v-col
        v-for="(item, index) in blockData.children"
        :key="index"
        cols="12"
        lg="6"
      >
        <person
          :name="item.fields.title"
          :role="item.fields.position"
          :avatar="item.fields.photo"
          :phone="item.fields.phone"
          :email="item.fields.email"
          hide-details
        />
      </v-col>
    </v-row>

    <kk-dynamic-html
      v-if="blockData.fields.article_after"
      :html="blockData.fields.article_after"
      class="mb-4 mb-md-8"
    />
  </div>
</template>
<script>
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Person from "@/components/Person.vue";

export default {
  name: "ShsiContacts",
  components: {
    KkDynamicHtml,
    Person,
  },
  props: {
    blockData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {},
  methods: {},
};
</script>
