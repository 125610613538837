<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M20.1016 40C9.10156 40 0.101562 31 0.101562 20C0.101562 9 9.10156 0 20.1016 0C31.1016 0 40.1016 9 40.1016 20C40.1016 31.1 31.1016 40 20.1016 40ZM20.1016 0.799999C9.50156 0.799999 0.901558 9.4 0.901558 20C0.901558 30.6 9.50156 39.2 20.1016 39.2C30.7016 39.2 39.3016 30.6 39.3016 20C39.3016 9.5 30.7016 0.799999 20.1016 0.799999Z"
        fill="currentColor"
      />
      <path
        d="M22.602 11.8031L18.502 17.5031C19.002 17.3031 19.502 17.3031 20.102 17.3031C23.602 17.3031 25.902 19.9031 25.902 23.2031C25.902 26.8031 23.302 29.4031 19.702 29.4031C15.502 29.4031 13.502 26.1031 13.502 23.3031C13.502 22.6031 13.602 21.1031 15.002 19.2031L21.102 10.7031L22.602 11.8031ZM23.902 23.3031C23.902 20.9031 22.102 19.1031 19.702 19.1031C17.202 19.1031 15.502 20.9031 15.502 23.3031C15.502 25.7031 17.202 27.6031 19.702 27.6031C22.102 27.6031 23.902 25.8031 23.902 23.3031Z"
        fill="currentColor"
      />
      <path
        d="M28.1016 19.2016H33.0016V14.6016H34.9016V19.2016H39.8016V21.0016H34.9016V25.6016H33.0016V21.0016H28.1016V19.2016Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 40,
    },
    height: {
      type: [Number, String],
      default: 40,
    },
  },
};
</script>
