<template>
  <div
    class="header-logo menu-closure"
    :class="logoClass"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div class="header-logo__icon menu-closure">
      <div class="header-logo__icon--circle menu-closure" />
      <div class="header-logo__icon--cube menu-closure" />
    </div>
    <div class="header-logo__text menu-closure">
      <img
        :src="`${baseUrl}images/logo/logo-text-${locale}.svg`"
        height="100%"
        class="menu-closure"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    locale: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
      transitions: ["rotateZ", "mutate", "rotateX"],
      currentTransitionIndex: -1,
    };
  },
  computed: {
    logoClass() {
      const transitionName = `transition-${
        this.transitions[this.currentTransitionIndex]
      }`;
      const transitionClass = {
        hovered: this.hover,
      };
      transitionClass[transitionName] = true;
      return transitionClass;
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
  methods: {
    onMouseEnter() {
      this.shiftTransition();
      this.hover = true;
    },
    onMouseLeave() {
      this.hover = false;
    },
    shiftTransition() {
      if (this.currentTransitionIndex == this.transitions.length - 1) {
        this.currentTransitionIndex = 0;
      } else {
        this.currentTransitionIndex++;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header-logo {
  --w-big: 36px;
  --w-small: 12px;
  --w-middle: 16px;
  --w-middle-offset: 10px;
  width: auto;
  height: var(--w-big);
  display: flex;
  align-items: center;

  .header-logo__icon {
    width: var(--w-big);
    height: var(--w-big);
    position: relative;

    .header-logo__icon--circle {
      position: absolute;
      top: 0;
      left: 0;
      width: var(--w-big);
      height: var(--w-big);
      border-radius: 50%;
      background-color: var(--v-textBase-base);
      transition: all 0.3s ease-in-out;
      z-index: 2;
    }
    .header-logo__icon--cube {
      position: absolute;
      top: var(--w-small);
      left: var(--w-small);
      width: var(--w-small);
      height: var(--w-small);
      background-color: var(--v-bgPrimary-base);
      transition: all 0.3s ease-in-out;
      z-index: 3;
    }
  }

  &.hovered {
    &.transition-rotateZ {
      .header-logo__icon--circle {
        background-color: var(--v-mira-base);
      }
      .header-logo__icon--cube {
        transform: rotateZ(90deg);
      }
    }
    &.transition-mutate {
      .header-logo__icon--circle {
        top: var(--w-middle-offset);
        left: var(--w-middle-offset);
        width: var(--w-middle);
        height: var(--w-middle);
        z-index: 3;
        background-color: var(--v-bgPrimary-base);
      }
      .header-logo__icon--cube {
        top: 0;
        left: 0;
        width: var(--w-big);
        height: var(--w-big);
        z-index: 2;
        background-color: var(--v-mira-base);
      }
    }
    &.transition-rotateX {
      .header-logo__icon--cube,
      .header-logo__icon--circle {
        transform: rotateX(180deg);
      }
      .header-logo__icon--circle {
        background-color: var(--v-mira-base);
      }
    }
  }

  .header-logo__text {
    width: auto;
    height: var(--w-big);
    margin-left: 8px;

    html.inclusive-darktheme & {
      filter: invert(100%) sepia(75%) saturate(0%) brightness(100%)
        contrast(100%);
    }
  }
}

@media (max-width: 1023px) {
  .header-logo {
    --w-big: 24px;
    --w-small: 8px;
    --w-middle: 10px;
    --w-middle-offset: 7px;
    .header-logo__text {
      margin-left: 5px;
    }
  }
}
</style>
