<template>
  <l-map
    v-if="!loading"
    class="map"
    :zoom="17"
    :center="coords"
    :options="{ scrollWheelZoom: false }"
  >
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    <l-marker :lat-lng="coords">
      <l-icon
        :icon-size="[markerSize * 2, markerSize * 2]"
        :icon-anchor="[markerSize, markerSize]"
        :icon-url="`${baseUrl}images/logo/logo-map-marker.svg`"
      />
    </l-marker>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";

export default {
  name: "KkMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
  },
  props: {
    coords: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      url: "https://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}",
      attribution: '<a href="https://2gis.com/">2GIS</a>',
    };
  },
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
    markerSize() {
      return 20;
    },
    loading() {
      return this.$store.getters.getMainLoading;
    },
  },
};
</script>
<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
  z-index: 0;
  color: #000;

  :deep(.leaflet-pane.leaflet-tile-pane) {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
  :deep(.leaflet-control-zoom-in) {
    color: #242424 !important;
  }
  :deep(.leaflet-control-zoom-out) {
    color: #242424 !important;
  }
}
</style>
