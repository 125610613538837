<template>
  <v-list-group
    v-model="expanded"
    color="textBase"
    class="podcast-fix"
    @change="onExpandedChange"
    @mouseenter="avatarHover = true"
    @mouseleave="avatarHover = false"
  >
    <template v-slot:prependIcon>
      <v-avatar
        size="100"
        tile
        class="flex-sm-grow-0 d-block"
        @click.stop="onThumbClick"
      >
        <v-img :src="payload.fields.icon" eager />
        <div v-if="shaderIcon.length > 0" class="podcast__avatar-shader">
          <v-icon class="d-block" size="50">{{ shaderIcon }}</v-icon>
        </div>
      </v-avatar>
    </template>
    <template v-slot:activator>
      <v-list-item-content class="sign-for-preview podcast__title">
        {{ payload.fields.title }}
      </v-list-item-content>
      <audio ref="audiofile" preload="none" class="d-none" />
    </template>
    <template v-slot:appendIcon>
      <v-icon class="chevron mr-n4">$vuetify.icons.ChevronDown</v-icon>
    </template>

    <v-row no-gutters>
      <v-col class="podcast__spacer flex-grow-0"></v-col>
      <v-col class="flex-grow-1">
        <v-row
          no-gutters
          class="mb-6 mt-3 mt-sm-0 justify-space-between justify-sm-start"
        >
          <v-col
            cols="12"
            sm=""
            class="podcast__player flex-grow-1 d-flex align-center justify-stretch mx-sm-3 mb-3 mb-sm-0 clickable"
            @click="onProgressClick"
          >
            <div class="podcast__player_progress flex-grow-1">
              <div
                :style="{ width: percentComplete + '%' }"
                class="podcast__player_progress-fill"
              />
              <div
                :style="{ left: percentComplete + '%' }"
                class="podcast__player_progress-thumb"
              />
            </div>
          </v-col>
          <v-col class="order-sm-first time-col">{{
            formatTime(currentSeconds)
          }}</v-col>
          <v-col
            class="time-col"
            :class="loadingState != 'loaded' ? 'time-col-shaded' : ''"
            >{{ formatTime(durationSeconds) }}</v-col
          >
        </v-row>

        <div class="longread mb-3" v-html="payload.fields.annotation" />

        <div class="textLight--text mb-3">
          {{ formatDate(payload.fields.date) }}
        </div>

        <div class="d-flex justify-space-between">
          <a
            :href="payload.fields.audio"
            class="d-flex align-center button mb-5 mb-sm-10"
            @click="onDownloadClick"
          >
            <span>{{ downloadButton }}</span>
            <v-icon class="ml-2">$vuetify.icons.Download</v-icon>
          </a>

          <router-link
            :to="{ name: 'Podcasts', hash: `#${payload.node_name}` }"
            class="d-flex align-center button mb-5 mb-sm-10"
          >
            <v-icon class="mx-2">mdi-link-variant</v-icon>
          </router-link>
        </div>
      </v-col>
    </v-row>
  </v-list-group>
</template>
<script>
import moment from "moment";

export default {
  name: "Podcast",
  props: {
    payload: {
      type: Object,
      required: true,
    },
    downloadButton: {
      type: String,
      required: true,
    },
    forcePause: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      audio: null,
      expanded: false,
      avatarHover: false,
      loadingState: "none",
      loaded: false,
      playing: false,
      started: false,
      currentSeconds: 0,
      durationSeconds: 0,
      volume: 100,
    };
  },
  computed: {
    formatDate() {
      return (date) => moment(date).format("DD.MM.yyyy");
    },
    formatTime() {
      return (val) => {
        return new Date(val * 1000).toISOString().substring(11, 8);
      };
    },
    percentComplete() {
      return (this.currentSeconds / this.durationSeconds) * 100;
    },
    muted() {
      return this.volume / 100 === 0;
    },
    shaderIcon() {
      if (this.playing) return "$vuetify.icons.PlayerPause";
      if (this.started && !this.playing) return "$vuetify.icons.PlayerPlay";
      if (
        !this.playing &&
        !this.started &&
        (this.avatarHover || this.$vuetify.breakpoint.xsOnly)
      )
        return "$vuetify.icons.PlayerPlay";
      return "";
    },
  },
  watch: {
    forcePause(value) {
      if (value && this.playing) {
        this.doPauseMedia();
      }
    },
    playing(value) {
      if (value) {
        this.doPlayMedia();
      } else {
        this.doPauseMedia();
      }
    },
    volume(value) {
      this.audio.volume = value / 100;
    },
  },
  methods: {
    onExpandedChange() {
      // если только пришли и разворачиваем, то инициируем загрузку
      if (this.loadingState == "none") {
        this.doMediaLoad();
      }
    },
    onThumbClick() {
      // если только пришли, то инициируем загрузку
      if (this.loadingState == "none") {
        this.playing = !this.playing;
        this.doMediaLoad();
        // если уже загружено, то играем или ставим на паузу
      } else if (this.loadingState == "loaded") {
        this.playing = !this.playing;
      }
      // если ещё грузится, то никакой реакции
    },
    onProgressClick(e) {
      if (this.loadingState != "loaded") {
        return;
      }
      if (e.target.tagName === "SPAN") {
        return;
      }
      const el = e.target.getBoundingClientRect();
      const seekPos = (e.clientX - el.left) / el.width;
      this.audio.currentTime = parseInt(this.audio.duration * seekPos, 10);
      if (!this.playing) this.doPlayMedia();
    },
    onTimeUpdate() {
      this.currentSeconds = parseInt(this.audio.currentTime, 10);
    },
    onDownloadClick() {
      this.doPauseMedia();
      return true;
    },
    doMediaLoad() {
      this.loadingState = "loading";
      this.audio = this.$refs.audiofile;
      this.audio.addEventListener("loadeddata", this.afterMediaLoad);
      this.audio.setAttribute("src", this.payload.fields.audio);
      this.audio.load();
    },
    afterMediaLoad() {
      if (this.audio.readyState >= 2) {
        this.audio.addEventListener("timeupdate", this.onTimeUpdate);
        this.audio.addEventListener("pause", () => {
          this.playing = false;
        });
        this.audio.addEventListener("play", () => {
          this.playing = true;
        });
        this.loadingState = "loaded";
        this.durationSeconds = parseInt(this.audio.duration);
        if (this.playing) {
          this.doPlayMedia();
        }
        return false;
      } else {
        throw new Error("Failed to load sound file.");
      }
    },
    doPlayMedia() {
      // передадим наверх что мы решили играть это аудио
      this.$emit("play");

      // если ещё грузится, то никакой реакции
      if (this.loadingState != "loaded") return;

      this.audio.play();
      this.started = true;

      if ("mediaSession" in navigator) {
        let skipTime = 30; // Time to skip in seconds

        navigator.mediaSession.setActionHandler("seekbackward", () => {
          this.audio.currentTime = this.currentSeconds - skipTime;
        });

        navigator.mediaSession.setActionHandler("seekforward", () => {
          this.audio.currentTime = this.currentSeconds + skipTime;
        });

        /* eslint-disable-next-line no-undef */
        navigator.mediaSession.metadata = new MediaMetadata({
          title: this.payload.fields.title,
          artist: "Ploschad Mira",
          artwork: [
            {
              src: `${process.env.BASE_URL}images/icons/mstile-310x310.png`,
              sizes: "310x310",
              type: "image/png",
            },
          ],
        });
      }
    },
    doPauseMedia() {
      // если ещё грузится, то никакой реакции
      if (this.loadingState != "loaded") return;
      this.audio.pause();
    },
    doStopMedia() {
      this.doPauseMedia();
      this.audio.currentTime = 0;
      this.started = false;
    },
    expand() {
      this.expanded = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.podcast-fix {
  margin-top: -80px;
  padding-top: 80px;
}
.podcast__spacer {
  display: none;
  @media (min-width: 1024px) {
    display: block;
    min-width: 124px;
  }
}
.podcast__avatar-shader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(36, 36, 36, 0.54);
  display: flex;
  align-items: center;
  justify-content: center;
}

.podcast__player {
  .podcast__player_progress {
    background-color: var(--v-line-base);
    height: 4px;
    position: relative;
    .podcast__player_progress-fill {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: var(--v-bgDark-base);
      transition: background-color 0.15s ease-in-out;
      pointer-events: none;
    }
    .podcast__player_progress-thumb {
      position: absolute;
      top: -6px;
      width: 16px;
      height: 16px;
      margin-left: -8px;
      border-radius: 8px;
      background-color: var(--v-mira-base);
      transition: opacity 0.15s ease-in-out;
      opacity: 0;
      pointer-events: none;
    }
  }

  &:hover {
    .podcast__player_progress-fill {
      background-color: var(--v-mira-base);
      transition: background-color 0.15s ease-in-out;
    }
    .podcast__player_progress-thumb {
      opacity: 1;
      transition: opacity 0.15s ease-in-out;
    }
  }
}
.time-col {
  min-width: 70px;
  max-width: 70px;
  @media (min-width: 1024px) {
    min-width: 87px;
    max-width: 87px;
  }
  &.time-col-shaded {
    opacity: 0;
  }
}
</style>
