<template>
  <div>
    <div class="border-bottom border-top mt-3">
      <div class="mt-6 mb-3">{{ dic.filters_category }}</div>
      <check-box-list-filter
        v-model="filters.categories"
        :items="meta.categories"
        @change="$emit('change')"
      />
    </div>
    <div class="border-bottom">
      <div class="mt-6 mb-3">{{ dic.filters_year }}</div>
      <year-range-selector
        v-model="filters.years"
        :limits="meta.years"
        @change="$emit('change')"
      />
    </div>
    <div class="border-bottom mt-5">
      <div class="mt-6 mb-3">{{ dic.filters_author }}</div>
      <check-box-list-filter
        v-model="filters.authors"
        :items="meta.authors"
        @change="$emit('change')"
      />
    </div>
  </div>
</template>

<script>
import CheckBoxListFilter from "@/components/Controls/CheckBoxListFilter.vue";
import YearRangeSelector from "@/components/Controls/YearRangeSelector.vue";

export default {
  name: "CollectionFiltersControls",
  components: { CheckBoxListFilter, YearRangeSelector },
  props: {
    value: {
      type: Object,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    dic: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
