<template>
  <div v-if="sectionData">
    <h3 id="management" class="mb-6 mb-sm-12">
      {{ sectionData.fields.title }}
    </h3>
    <v-row>
      <v-col
        v-for="item in sectionData.children"
        :key="item.node_name"
        cols="12"
        lg="6"
      >
        <person
          :name="item.fields.title"
          :role="item.fields.position"
          :avatar="item.fields.photo"
          :phone="item.fields.phone"
          :email="item.fields.email"
          hide-details
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Person from "@/components/Person.vue";

export default {
  name: "ContactsManagement",
  components: { Person },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
};
</script>
