<template>
  <div v-if="sectionData" class="section kk-notification pa-5 pa-sm-10">
    <section-title
      id="contacts"
      :title="sectionData.fields.title"
      :browse-title="sectionData.fields.browse_all"
      :browse-link="{ name: 'Contacts' }"
      class="fix-contents-contacts"
    />
    <v-row class="align-start">
      <v-col cols="12" md="6">
        <kk-dynamic-html :html="sectionData.fields.article_left" />
      </v-col>
      <v-col cols="12" md="6">
        <kk-dynamic-html :html="sectionData.fields.article_right" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "AboutContacts",
  components: { SectionTitle, KkDynamicHtml },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style scoped lang="scss">
.fix-contents-contacts {
  margin-top: -70px;
  padding-top: 70px;
  @media (min-width: 1024px) {
    margin-top: -130px;
    padding-top: 130px;
  }
}
</style>
