<template>
  <div v-if="pageLoaded" class="section">
    <page-title :title="pageData.fields.title" />

    <kk-article
      v-if="pageLoaded"
      ref="refarticle"
      :contents="contactsContents"
      class="subsection"
    >
      <kk-dynamic-html
        v-if="pageData.fields.article"
        :html="pageData.fields.article"
        class="subsection"
      />

      <h3 id="address" class="mb-4 mb-sm-8">
        {{ pageData.fields.addr_title }}
      </h3>

      <kk-dynamic-html :html="pageData.fields.addr_text" class="subsection" />

      <contacts-map :section-data="pageData.contacts_map_section" />

      <contacts-phones :section-data="pageData.contacts_phones_section" />

      <contacts-management
        :section-data="pageData.contacts_management_section"
      />
    </kk-article>

    <zuruck with-aux-bar />
  </div>
</template>

<script>
import ContactsRepository from "@/api/contacts.js";
import KkArticle from "@/components/Article/KkArticle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import PageTitle from "@/components/PageTitle.vue";
import ContactsMap from "./ContactsMap.vue";
import ContactsPhones from "./ContactsPhones.vue";
import ContactsManagement from "./ContactsManagement.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    KkArticle,
    KkDynamicHtml,
    PageTitle,
    ContactsMap,
    ContactsPhones,
    ContactsManagement,
    Zuruck,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        contacts_map_section: { fields: {}, children: [] },
        contacts_phones_section: { fields: {}, children: [] },
        contacts_management_section: { fields: {}, children: [] },
      },
    };
  },
  computed: {
    contactsContents() {
      return {
        address: this.pageData.fields.addr_title,
        map: this.pageData.contacts_map_section.fields.title,
        phones: this.pageData.contacts_phones_section.fields.title,
        management: this.pageData.contacts_management_section.fields.title,
      };
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await ContactsRepository.getContacts();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
  },
};
</script>
