<template>
  <div v-if="sectionData" class="subsection">
    <section-title header-id="building" :title="sectionData.fields.title" />

    <v-row class="align-stretch">
      <v-col cols="12" lg="6" xl="5" class="d-sm-flex flex-column">
        <kk-dynamic-html :html="sectionData.fields.article_opening" />
        <file-attach
          v-if="sectionData.fields.photo_archive"
          :item="{
            f_path: sectionData.fields.photo_archive,
            f_title: sectionData.fields.photo_archive_title,
          }"
          class="mb-3 mb-sm-6"
        />
        <div class="flex-grow-1 d-none d-sm-block" />
        <div>
          <browse-button
            :browse-title="sectionData.fields.browse_all"
            :browse-link="{ name: 'AboutHistory' }"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="6" xl="7">
        <v-img :src="sectionData.fields.image_opening" eager />
      </v-col>
    </v-row>

    <v-row class="d-none d-sm-flex mb-4">
      <v-col cols="4">
        <v-img :src="sectionData.fields.image1" eager />
      </v-col>
      <v-col cols="4">
        <v-img :src="sectionData.fields.image2" eager />
      </v-col>
      <v-col cols="4">
        <v-img :src="sectionData.fields.image3" eager />
      </v-col>
    </v-row>

    <horizontal-scroller
      class="d-block d-sm-none mb-8"
      :items="[1, 2, 3]"
      content-class="mx-n2"
    >
      <template v-slot="{ item }">
        <div class="mx-2">
          <v-img
            :src="sectionData.fields[`image${item}`]"
            width="260"
            height="200"
            eager
          />
        </div>
      </template>
    </horizontal-scroller>

    <v-row class="mt-3 mb-4 mb-lg-15">
      <v-col cols="12" lg="8" xl="9">
        <v-img :src="sectionData.fields.image_architect" eager />
      </v-col>
      <v-col cols="12" lg="4" xl="3" class="longread">
        <kk-dynamic-html :html="sectionData.fields.article_architect" />
      </v-col>
    </v-row>

    <v-row class="mb-0 mb-sm-15">
      <v-col cols="12" lg="6" xl="7">
        <v-img :src="sectionData.fields.image_building" eager />
      </v-col>
      <v-col cols="12" lg="6" xl="5" class="longread">
        <kk-dynamic-html :html="sectionData.fields.article_building" />
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" class="large-txt mb-sm-5">
        <kk-dynamic-html :html="sectionData.fields.freedom_text" />
      </v-col>
      <v-col cols="12" sm="6">
        <v-img :src="sectionData.fields.image_freedom1" eager />
      </v-col>
      <v-col cols="12" sm="6">
        <v-img :src="sectionData.fields.image_freedom2" eager />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import FileAttach from "@/components/FileAttach.vue";
import BrowseButton from "@/components/BrowseButton.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "AboutBuilding",
  components: {
    SectionTitle,
    FileAttach,
    BrowseButton,
    HorizontalScroller,
    KkDynamicHtml,
  },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
