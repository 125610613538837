<template>
  <div>
    <div class="border-bottom">
      <div class="mt-0 mb-3">{{ dic.filters_author }}</div>
      <check-box-list-filter
        v-model="filters.authors"
        :items="metaStatic.authors"
        ext-search
        @search="$emit('search', 'authors', $event)"
        @change="$emit('change')"
      />
    </div>
    <div class="border-bottom">
      <div class="mt-6 mb-3">{{ dic.filters_publisher }}</div>
      <check-box-list-filter
        v-model="filters.publishers"
        :items="metaStatic.publishers"
        ext-search
        @search="$emit('search', 'publishers', $event)"
        @change="$emit('change')"
      />
    </div>
    <div class="">
      <div class="mt-6 mb-3">{{ dic.filters_year }}</div>
      <year-range-selector
        v-model="filters.years"
        :limits="meta.years"
        @change="$emit('change')"
      />
    </div>
    <div class="border-bottom border-top mt-3">
      <div class="mt-6 mb-3">{{ dic.filters_language }}</div>
      <check-box-list-filter
        v-model="filters.languages"
        :items="meta.languages"
        disable-search
        @change="$emit('change')"
      />
    </div>
    <div class="border-bottom">
      <div class="mt-6 mb-3">{{ dic.filters_category }}</div>
      <check-box-list-filter
        v-model="filters.categories"
        :items="meta.categories"
        @change="$emit('change')"
      />
    </div>
  </div>
</template>

<script>
import CheckBoxListFilter from "@/components/Controls/CheckBoxListFilter.vue";
import YearRangeSelector from "@/components/Controls/YearRangeSelector.vue";

export default {
  name: "LibraryFiltersControls",
  components: { CheckBoxListFilter, YearRangeSelector },
  props: {
    value: {
      type: Object,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    metaStatic: {
      type: Object,
      required: true,
    },
    dic: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
