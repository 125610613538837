<template>
  <v-dialog
    ref="kkdatepickerdialog"
    v-model="modal"
    :return-value.sync="date"
    persistent
    width="370px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="dateFormatted"
        readonly
        dense
        outlined
        v-bind="attrs"
        hide-details
        v-on="on"
      >
        <template v-slot:append>
          <v-icon color="base"> $vuetify.icons.Calendar </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      scrollable
      width="370"
      :title-date-format="dateFormat"
      :weekday-format="weekDayFormat"
      :header-date-format="headerFormat"
      :month-format="monthFormat"
      first-day-of-week="1"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-spacer />
      <v-btn tile text @click="modal = false">
        {{ $t("Common.datePickerCancel") }}
      </v-btn>
      <v-btn outlined tile @click="$refs.kkdatepickerdialog.save(date)">
        {{ $t("Common.datePickerSelect") }}
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import moment from "moment";
import upperFirst from "lodash/upperFirst";
export default {
  name: "KkDatePicker",
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modal: false,
    };
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    dateFormatted() {
      return this.dateFormat(this.date);
    },
  },
  methods: {
    headerFormat(date) {
      return date ? upperFirst(moment(date).format("MMMM yyyy")) : "";
    },
    monthFormat(date) {
      return date ? upperFirst(moment(date).format("MMMM")) : "";
    },
    dateFormat(date) {
      return date ? moment(date).format("DD.MM.yyyy") : "";
    },
    weekDayFormat(date) {
      return date ? upperFirst(moment(date).format("dd")) : "";
    },
  },
};
</script>
