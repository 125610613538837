import { render, staticRenderFns } from "./AuxiliaryMenuLink.vue?vue&type=template&id=7290e71e&scoped=true"
import script from "./AuxiliaryMenuLink.vue?vue&type=script&lang=js"
export * from "./AuxiliaryMenuLink.vue?vue&type=script&lang=js"
import style0 from "./AuxiliaryMenuLink.vue?vue&type=style&index=0&id=7290e71e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7290e71e",
  null
  
)

export default component.exports