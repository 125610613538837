<template>
  <a
    :href="item.link"
    target="_blank"
    class="d-block news-list-item kk-hoverable-image mb-5"
    draggable="false"
  >
    <image-extended
      :image="item.image"
      content-class="press-image-container"
      class="image-ext-style mb-3 mb-md-5"
      :class="{ limited }"
      eager
    />
    <div class="d-flex justify-space-between mb-2">
      <div class="textLight--text">
        {{ item.press_name }}
      </div>
      <div class="textLight--text">
        {{ formatDate(item.date) }}
      </div>
    </div>
    <div class="h6-mini mb-1">
      {{ item.title }}
    </div>
  </a>
</template>
<script>
import moment from "moment";
import ImageExtended from "@/components/ImageExtended.vue";

export default {
  name: "ShsiPressItem",
  components: { ImageExtended },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    limited: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formatDate() {
      return (date) => moment(date).format("DD.MM.yyyy");
    },
  },
};
</script>
<style scoped lang="scss">
.image-ext-style {
  height: 45vw;
}
.image-ext-style.limited {
  height: 200px;
  max-width: 320px;
}
@media (min-width: 640px) {
  .image-ext-style {
    height: 35vw;
  }
  .image-ext-style.limited {
    height: 200px;
  }
}
@media (min-width: 1024px) {
  .image-ext-style {
    height: 20vw;
  }
  .image-ext-style.limited {
    max-width: 440px;
    height: 230px;
  }
}
@media (min-width: 1440px) {
  .image-ext-style:not(.limited) {
    height: 15vw;
  }
}
@media (min-width: 1920px) {
  .image-ext-style:not(.limited) {
    height: 12vw;
  }
}
</style>
