export default {
  state: {
    appSearch: "",
  },
  getters: {
    getAppSearch(state) {
      return state.appSearch;
    },
  },
  mutations: {
    setAppSearch(state, appSearch) {
      state.appSearch = appSearch;
    },
  },
};
