var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.sectionData)?_c('div',{staticClass:"section"},[_c('section-title',{attrs:{"header-id":"prices","title":_vm.sectionData.fields.title}}),_vm._v(" "),_c('kk-dynamic-html',{staticClass:"mb-8 mb-sm-16",attrs:{"html":_vm.sectionData.fields.article}}),_vm._v(" "),_c('div',{staticClass:"grid"},_vm._l((_vm.sectionData.children),function(group,groupIndex){return _c('div',{key:groupIndex,staticClass:"subgrid-group"},[_c('div',{staticClass:"cell-full border-bottom pb-2 pb-sm-3"},[_c('h4',[_vm._v(_vm._s(group.fields.title))])]),_vm._v(" "),_vm._l((group.children),function(ticket,ticketIndex){return _c('div',{key:ticketIndex,staticClass:"subgrid",class:ticketIndex == group.children.length - 1 ? 'subgrid--last' : ''},[_c('div'),_vm._v(" "),_c('div',{staticClass:"cell cell-center longread ticket-title d-flex align-center",class:_vm.ticketCellClass(
              ticketIndex == 0,
              ticketIndex == group.children.length - 1
            )},[_c('div',[_vm._v(_vm._s(ticket.fields.title))]),_vm._v(" "),_c('kk-popover',{staticClass:"ml-2",attrs:{"html":ticket.fields.addinfo_title}})],1),_vm._v(" "),_c('div',{staticClass:"cell cell-center d-flex flex-column justify-center",class:_vm.ticketCellClass(
              ticketIndex == 0,
              ticketIndex == group.children.length - 1
            )},[_c('h4',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(ticket.fields.price))]),_vm._v(" "),(ticket.fields.addprice.length > 0)?_c('div',{staticClass:"d-flex mt-3"},[_c('div',[_vm._v(_vm._s(ticket.fields.addprice))]),_vm._v(" "),_c('kk-popover',{staticClass:"ml-2",attrs:{"html":ticket.fields.addinfo_price}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"cell cell-right d-flex align-center",class:_vm.ticketCellClass(
              ticketIndex == 0,
              ticketIndex == group.children.length - 1
            )},[_c('v-btn',{attrs:{"outlined":"","block":"","tile":"","href":ticket.fields.service_url,"target":"_blank"}},[_vm._v("\n            "+_vm._s(_vm.sectionData.fields.button_buy.toLowerCase())+"\n          ")])],1)])}),_vm._v(" "),(group.fields.note.length > 0)?_c('div',{staticClass:"cell-full longread textLight--text mb-8 mb-sm-16"},[_vm._v("\n        "+_vm._s(group.fields.note)+"\n      ")]):_vm._e()],2)}),0),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('kk-dynamic-html',{attrs:{"html":_vm.sectionData.fields.col1_text}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('kk-dynamic-html',{attrs:{"html":_vm.sectionData.fields.col2_text}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }