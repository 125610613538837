export default {
  "/page/2021": "/pushkin-card",
  "/pages/pushkin-card": "/pushkin-card",
  "/page/1669": "/pages/heroes-memory",
  "/page/1670": "/pages/awards-war",
  "/page/1603": "/pages/guide-for-parents-with-strollers",
  "/page/42": "/pages/evaluate-service",
  "/page/1859": "/pages/evaluate-service-results",
  "/page/25": "/pages/inclusive",
  "/page/19": "/pages/biennale",
  "/page/1754": "/pages/pozdeev-among-the-books",
  "/page/1799": "/pages/photo-with-pozdeev",
  "/page/1682": "/pages/dasha-ryabchenko",
  "/page/1714": "/pages/boy-v-serdce-arktiki",
  "/page/1759": "/pages/documents-great-war-from-collection",
  "/page/2095": "/pages/crimea-and-russia-8-years",
  "/page/2153": "/pages/vladimir-zagorov-o-freskah",
  "/page/2155": "/pages/eldar-valeev",
  "/page/2156": "/pages/modern-japanese-photography",
  "/page/2157": "/pages/modern-japanese-photography-started",
  "/page/2159": "/pages/beetle-collection",
  "/page/2114": "/pages/transfer-documents",
  "/page/2160": "/pages/modern-samurai",
  "/page/2162": "/pages/toska-po-realnosti",
  "/page/2164": "/pages/monopoly-for-knowledge",
  "/page/2165": "/pages/exhibition-is-space-ensemble",
  "/page/2166": "/pages/accessible-and-unavailable",
  "/page/2167": "/pages/zlata-svetlichnaya-o-kruzhke",
  "/page/2168": "/pages/leondi-pavlov-o-rabote-v-muzee",
  "/feed": "/news",
  "/feed/:ident": "/news/:ident",
  "/page/37": "/about",
  "/page/38": "/about",
  "/page/1686": "/about",
  "/page/8": "/about/history",
  "/page/43": "/projects",
  "/collections": "/collection",
  "/page/18": "/publications",
  "/page/40": "/documents",
  "/page/1924": "/documents",
  "/show": "/exhibitions",
  "/show/:ident": "/exhibitions/:ident",
  "/page/41": "/exhibitions",
  "/page/10": "/exhibitions",
  "/page/6": "/tours",
  "/page/31": "/library",
  "/page/1969": "/souvenirs",
  "/page/23": "/souvenirs",
  "/page/1664": "/online-projects",
  "/page/1697": "/online-projects",
  "/podcast/:ident": "/podcast",
  "/virtual-tour/:ident": "/virtual-tour",
  "/tickets": "/visitors/#prices",
  "/page/34": "/visitors",
  "/page/33": "/visitors/#operating-hours",
  "/page/35": "/visitors/#faq",
  "/page/26": "/visitors/#rules",
  "/page/2035": "/visitors/#subscriptions",
};
