<template>
  <div class="section">
    <kk-article
      v-if="pageLoaded"
      ref="refarticle"
      :contents="pageData.fields.contents"
      dense
      class="subsection"
    >
      <v-row>
        <v-col>
          <h2>{{ pageData.fields.title }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <kk-dynamic-html :html="pageData.fields.article" />
        </v-col>
      </v-row>
    </kk-article>

    <reference-widget :page-data="pageData" />

    <zuruck with-aux-bar />
  </div>
</template>

<script>
import PushkinCardRepository from "@/api/pushkin-card.js";
import KkArticle from "@/components/Article/KkArticle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ReferenceWidget from "@/components/ReferenceWidget.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    KkArticle,
    KkDynamicHtml,
    ReferenceWidget,
    Zuruck,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        exhibitions_section: { fields: {}, children: [] },
        events_section: { fields: {}, children: [] },
      },
    };
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await PushkinCardRepository.getPushkinCard();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
  },
};
</script>
