<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="white"
      d="M25.0007 0.835938C11.6607 0.835938 0.833984 11.6626 0.833984 25.0026C0.833984 38.3426 11.6607 49.1693 25.0007 49.1693C38.3407 49.1693 49.1673 38.3426 49.1673 25.0026C49.1673 11.6626 38.3407 0.835938 25.0007 0.835938ZM17.7507 34.6693H22.584V15.3359H17.7507V34.6693ZM32.2507 34.6693H27.4173V15.3359H32.2507V34.6693Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 50,
    },
    height: {
      type: [Number, String],
      default: 50,
    },
  },
};
</script>
