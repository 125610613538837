import Repository from "@/plugins/axios";

export default {
  // Получить список экспонатов
  getCollection(params) {
    return Repository.get(`/collection`, { params });
  },

  // Получить страницу экспоната
  getPiece(pieceName, subPieceName, params) {
    return Repository.get(`/collection/${pieceName}/${subPieceName}`, {
      params,
    });
  },

  // Получить страницу о коллекции
  getAbout() {
    return Repository.get(`/collection/about`);
  },
};
