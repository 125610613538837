import { render, staticRenderFns } from "./LogotypeBand.vue?vue&type=template&id=3ddd2ec5&scoped=true"
import script from "./LogotypeBand.vue?vue&type=script&lang=js"
export * from "./LogotypeBand.vue?vue&type=script&lang=js"
import style0 from "./LogotypeBand.vue?vue&type=style&index=0&id=3ddd2ec5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ddd2ec5",
  null
  
)

export default component.exports