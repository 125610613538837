<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 6V5H11V6H13ZM12 12H11V12.618L11.5528 12.8944L12 12ZM15.5528 14.8944L16.4472 15.3416L17.3416 13.5528L16.4472 13.1056L15.5528 14.8944ZM11 6V12H13V6H11ZM11.5528 12.8944L15.5528 14.8944L16.4472 13.1056L12.4472 11.1056L11.5528 12.8944Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
