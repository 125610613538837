<template>
  <div class="lang-container flex-nowrap align-center">
    <div
      v-for="(langEnabled, lang, index) in langs"
      :key="lang"
      class="lang d-flex flex-nowrap align-center"
    >
      <div v-if="index > 0" class="textLight--text mx-2">—</div>
      <div v-if="lang == locale" class="base-3 textBase--text">
        {{ upper(lang) }}
      </div>
      <button
        v-else
        class="kk-button base-2 textLight--text link lang-switcher"
        @click="$emit('change-locale', lang, langEnabled)"
      >
        {{ upper(lang) }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    langs: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  computed: {
    upper() {
      return (lang) => lang.toUpperCase();
    },
  },
};
</script>
<style lang="scss">
.lang-container {
  user-select: none;

  .lang-switcher {
    cursor: pointer;
  }
}
</style>
