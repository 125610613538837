<template>
  <div v-if="sectionData.fields.title" class="section kk-notification pa-0">
    <v-row no-gutters class="align-center">
      <v-col
        cols="12"
        md=""
        class="pushkarta__img order-last order-md-first pt-5 pt-md-10 pb-0 px-4 px-sm-8 align-self-end"
      >
        <image-extended :image="sectionData.fields.image" width="100%" />
      </v-col>
      <v-col cols="12" md="">
        <div class="pushkarta__description">
          <h3 class="mb-4 mb-sm-8">{{ sectionData.fields.title }}</h3>
          <kk-dynamic-html
            class="mb-4 mb-sm-8 base"
            :html="sectionData.fields.text"
          />
          <div class="d-block d-sm-inline-block">
            <v-btn outlined tile block :to="{ name: 'PushkinCard' }">
              {{ sectionData.fields.button }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ImageExtended from "@/components/ImageExtended.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "MainPushkarta",
  components: { ImageExtended, KkDynamicHtml },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.pushkarta__img {
  @media (min-width: 1280px) {
    min-width: 550px;
    max-width: 550px;
  }
  @media (min-width: 1440px) {
    min-width: 900px;
    max-width: 900px;
  }
}

.pushkarta__description {
  padding: 24px 16px 0 16px;
  @media (min-width: 1024px) {
    padding: 44px 32px 0 32px;
  }
  @media (min-width: 1280px) {
    padding: 20px 32px;
  }
}
</style>
