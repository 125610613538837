<template>
  <div class="calendar__day">
    <div class="mb-1 mb-sm-2">{{ weekday(itemData.day) }}</div>
    <h4 class="mb-2 mb-sm-4">{{ verboseDate(itemData.day) }}</h4>
    <kk-link
      v-for="(timeBlock, index) in itemsFiltered"
      :key="index"
      :link="timeBlock.link"
      draggable="false"
      class="calendar__day__time"
      :class="timeBlock.glow == '1' ? 'glow' : ''"
    >
      <h5 class="mb-2 mb-sm-3">{{ timeFormat(timeBlock) }}</h5>
      <div v-if="index == itemsFiltered.length - 1" class="mb-2 mb-sm-3">
        <image-extended :image="timeBlock.image" eager />
      </div>
      <div>{{ timeBlock.title }}</div>
    </kk-link>
  </div>
</template>
<script>
import KkLink from "@/components/KkLink.vue";
import moment from "moment";
import upperFirst from "lodash/upperFirst";
import ImageExtended from "@/components/ImageExtended.vue";

export default {
  name: "MainCalendarDay",
  components: { KkLink, ImageExtended },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    disableClick: {
      type: Boolean,
      default: false,
    },
    headerData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    momentDt() {
      return (dt) => moment(dt);
    },
    itemsFiltered() {
      const day = moment(this.itemData.day).day().toString();
      const dayOpened = !this.headerData.fields.closed.includes(day);
      return this.itemData.times.filter(
        (time) => time.schedule_typ == 1 || dayOpened
      );
    },
  },
  methods: {
    weekday(dt) {
      return upperFirst(this.momentDt(dt).format("dd"));
    },
    verboseDate(dt) {
      return this.momentDt(dt).format("D MMMM");
    },
    timeFormat(timeBlock) {
      if (timeBlock.time_text) {
        return timeBlock.time_text;
      } else if (timeBlock.time_end) {
        return `${timeBlock.time_begin} — ${timeBlock.time_end}`;
      } else {
        return timeBlock.time_begin;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#app.v-application.theme--light .calendar__day {
  min-width: 240px;
  max-width: 240px;
  .calendar__day__time {
    display: block;
    padding: 12px;
    border: 1px solid var(--v-line-base);
    margin-bottom: 12px;
    transition: all 0.15s ease-in-out;

    &:hover {
      transition: all 0.15s ease-in-out;
      border: 1px solid transparent;
      box-shadow: 0px 13px 28px rgba(237, 34, 36, 0.25),
        0px 1px 30px rgba(237, 34, 36, 0.6);
    }

    &.glow {
      background-color: var(--v-bgDark-base);
      color: var(--v-textOnBgDark-base);
      &:hover {
        color: var(--v-textOnBgDark-base) !important;
      }
    }
    &:hover {
      color: var(--v-textBase-base) !important;
    }
  }
}
</style>
