<template>
  <div v-if="pageLoaded" class="section">
    <kk-breadcrumbs
      class="no-marker px-0 mb-6 mb-sm-12 mt-n4 mt-sm-0"
      :items="breadcrumbsItems"
    />

    <h2 class="mb-3 mb-sm-8">{{ pageData.fields.title }}</h2>

    <v-row class="flex-sm-nowrap mt-0 mt-sm-3">
      <v-col class="collection-piece">
        <div class="collection-image mb-5 mb-sm-10" @click="zoom">
          <image-extended
            ref="refcollectionimage"
            :image="pageData.fields.image"
            max-width="100%"
            max-height="calc(100vh - 200px)"
            :contain="true"
            fill="transparent"
            @load="onMainImageLoad"
          />
        </div>
        <kk-dynamic-html :html="pageData.fields.article" />
      </v-col>
      <v-col
        cols="12"
        sm=""
        class="order-first order-sm-last collection-nav ml-0"
      >
        <div class="kk-sticky-container">
          <div class="kk-sticky">
            <v-row
              v-if="(pageData.fields.authors || []).length > 0"
              class="border-top"
            >
              <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
                (pageData.collection_fields.filters_author || "").toLowerCase()
              }}</v-col>
              <v-col class="text-right">
                <span
                  v-for="(author, authorIndex) in pageData.fields.authors || []"
                  :key="authorIndex"
                >
                  <span v-if="authorIndex > 0">, </span>
                  <router-link
                    :to="{
                      name: 'Collection',
                      params: { filterPreset: { author } },
                    }"
                    class="link"
                  >
                    {{ author }}
                  </router-link>
                </span>
              </v-col>
            </v-row>
            <v-row class="border-top">
              <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
                (pageData.collection_fields.filters_title || "").toLowerCase()
              }}</v-col>
              <v-col class="text-right">{{ pageData.fields.title }}</v-col>
            </v-row>
            <v-row
              v-if="pageData.fields.category?.length > 0"
              class="border-top"
            >
              <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
                (
                  pageData.collection_fields.filters_category || ""
                ).toLowerCase()
              }}</v-col>
              <v-col class="text-right">
                <router-link
                  :to="{
                    name: 'Collection',
                    params: {
                      filterPreset: { category: pageData.fields.category },
                    },
                  }"
                  class="link"
                >
                  {{ pageData.fields.category }}
                </router-link>
              </v-col>
            </v-row>
            <v-row v-if="pageData.fields.year?.length > 0" class="border-top">
              <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
                (pageData.collection_fields.filters_year || "").toLowerCase()
              }}</v-col>
              <v-col class="text-right">
                <router-link
                  :to="{
                    name: 'Collection',
                    params: { filterPreset: { years: pageData.fields.year } },
                  }"
                  class="link"
                >
                  {{ pageData.fields.year }}
                </router-link>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- Диалог просмотра изображения элемента с функциями Zoom/Pan -->
    <v-dialog
      v-model="zoomDialog"
      content-class="zoom-dialog"
      :overlay-opacity="0.54"
    >
      <div
        class="zoom-control zoom-close clickable"
        @click="zoomDialog = false"
      >
        <v-icon size="36">$vuetify.icons.Close</v-icon>
      </div>
      <div class="zoom-panel">
        <div id="zoom-modal-zoom-out">
          <div class="zoom-panel-control clickable">
            <v-icon size="32">$vuetify.icons.ZoomOut</v-icon>
          </div>
        </div>
        <div id="zoom-modal-zoom-in" class="ml-5">
          <div class="zoom-panel-control clickable">
            <v-icon size="32">$vuetify.icons.ZoomIn</v-icon>
          </div>
        </div>
      </div>
      <div id="openseadragon-modal" class="zoom-dialog__scrollbox" />
    </v-dialog>

    <!-- Диалог просмотра изображения подэлемента с описанием -->
    <collection-sub-piece-dialog
      :show="subItemDialog"
      :ident="subident"
      :item="pageData.current_sub_item"
      @close-dialog="onSubitemDialogClose"
    />

    <!-- Блок подэлементов группы коллекции -->
    <div
      v-if="pageData.children?.length > 0"
      id="collection-subelements"
      class="subelements"
    >
      <collection-list :items="pageData.children || []" :base-url="ident" />

      <infinite-loader
        :fetch="fetchMore"
        :disabled="disableInfiniteScroll"
        :visible="pageData.more > 0 || !pageLoaded"
        element-id="v-infinite-loader-subcollection"
      />
    </div>
  </div>
</template>

<script>
import CollectionRepository from "@/api/collection.js";
import KkBreadcrumbs from "@/components/Article/KkBreadcrumbs.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ImageExtended from "@/components/ImageExtended.vue";
import OpenSeadragon from "openseadragon";
import CollectionList from "./CollectionList.vue";
import CollectionSubPieceDialog from "./CollectionSubPieceDialog.vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";

export default {
  name: "CollectionPiece",

  components: {
    KkBreadcrumbs,
    KkDynamicHtml,
    ImageExtended,
    CollectionList,
    CollectionSubPieceDialog,
    InfiniteLoader,
  },

  props: {
    ident: {
      type: String,
      required: true,
    },
    subident: {
      type: String,
      default: "",
    },
  },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },

  data() {
    return {
      zoomDialog: false,
      zoomViewer: null,
      subItemDialog: false,
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
        main_fields: {},
        collection_fields: {},
        current_sub_item: null,
      },
      offset: 0,
      more: 0,
      loading: true,
      subItemIndex: {},
    };
  },

  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.collection_fields.title || "",
          to: { name: "Collection" },
          exact: true,
          disabled: false,
        },
      ];
    },

    zoomImageStyle() {
      return {
        width: `${this.zoomNaturalWidth}px`,
        height: `${this.zoomNaturalHeight}px`,
        "transform-origin": "center center",
        transform: `scale(${this.zoomFactor})`,
      };
    },

    disableInfiniteScroll() {
      return this.loading || this.pageData.more == 0;
    },
  },

  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },

    zoomDialog(newV) {
      if (!newV) {
        this.zoomViewer.destroy();
      }
    },

    subident(newV) {
      if (newV?.length > 0) {
        const childByInd = this.pageData.children[this.subItemIndex[newV]];
        if (childByInd) {
          this.pageData.current_sub_item = childByInd;
        } else {
          this.pageData.current_sub_item = null;
        }
        this.subItemDialog = true;
      } else {
        this.subItemDialog = false;
      }
    },
  },

  mounted() {
    this.loadPage();
  },

  methods: {
    async loadPage() {
      const { data } = await CollectionRepository.getPiece(
        this.ident,
        this.subident,
        {}
      );
      this.pageData = data;
      data.children.forEach((element, index) => {
        this.subItemIndex[element.node_name] = index;
      });
      this.offset = data.children.length;
      this.$store.commit("setLangsAvail", data.published);
      this.loading = false;
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },

    async zoom() {
      this.zoomDialog = true;
      await this.$nextTick();

      this.zoomViewer = OpenSeadragon({
        id: "openseadragon-modal",
        tileSources: {
          type: "image",
          url: this.pageData.fields.image.i_main,
        },
        visibilityRatio: 1.0,
        constrainDuringPan: true,
        zoomInButton: "zoom-modal-zoom-in",
        zoomOutButton: "zoom-modal-zoom-out",
      });
      return true;
    },

    async fetchMore() {
      this.loading = true;

      const { data } = await CollectionRepository.getPiece(
        this.ident,
        this.subident,
        { offset: this.offset }
      );

      this.offset += data.children.length;
      data.children.forEach((element) => {
        const index = this.pageData.children.length;
        this.subItemIndex[element.node_name] = index;
        this.$set(this.pageData.children, index, element);
      });
      this.pageData.more = data.more;

      this.loading = false;
    },

    onSubitemDialogClose() {
      this.subItemDialog = false;
      this.$router.push({
        name: "CollectionPiece",
        params: { ident: this.ident },
      });
    },

    onMainImageLoad() {
      if (this.subident) {
        const element = document.getElementById("collection-subelements");
        if (element) {
          element.scrollIntoView({ behavior: "instant" });
        }
        this.subItemDialog = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.collection-nav {
  @media (min-width: 1024px) {
    min-width: 300px;
    max-width: 300px;
  }
  @media (min-width: 1280px) {
    min-width: 336px;
    max-width: 336px;
    margin-left: 48px;
  }
  @media (min-width: 1440px) {
    min-width: 390px;
    max-width: 390px;
  }
}
.collection-piece {
  @media (min-width: 1024px) {
    min-width: calc(100% - 300px);
    max-width: calc(100% - 300px);
  }
  @media (min-width: 1280px) {
    min-width: calc(100% - 336px);
    max-width: calc(100% - 336px);
  }
  @media (min-width: 1440px) {
    min-width: calc(100% - 390px);
    max-width: calc(100% - 390px);
  }

  .collection-image {
    cursor: zoom-in;
  }
}
.zoom-dialog__scrollbox {
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: grab;
}
.kk-sticky .row.border-top:first-child {
  border: none !important;
}
.subelements {
  // Desktop
  @media (min-width: 1024px) {
    margin-top: 80px;
  }
  // Mobile
  @media (max-width: 1024px) {
    margin-top: 40px;
  }
}
</style>
