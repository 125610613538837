<template>
  <v-text-field
    id="form-text-field-search-mobile"
    v-model="searchTerm"
    block
    outlined
    clearable
    color="textBase"
    clear-icon="mdi-close-circle"
    hide-details
    :placeholder="$t('Common.search')"
    @keyup.enter="onSearch"
  >
    <template v-slot:append>
      <div class="" @click="onSearch">
        <v-icon class="link mx-0">$vuetify.icons.Search</v-icon>
      </div>
    </template>
  </v-text-field>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
    };
  },
  methods: {
    onSearch() {
      if (this.searchTerm == "") return false;
      const { searchTerm } = this;
      this.searchTerm = "";
      this.$emit("on-search", searchTerm);
    },
  },
};
</script>
