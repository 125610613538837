<template>
  <div>
    <!-- Блок с информацией о датах и доп. инфой (десктопный вариант расположения) -->
    <kk-article-info
      :settings="settings"
      :schedule="schedule"
      class="mb-5 d-none d-sm-block"
    />

    <!-- Просто кнопка-ссылка на сайт покупки -->
    <div v-if="!!settings.service_url && canBuyTicket" class="mb-2 mb-sm-0">
      <v-btn outlined tile block :href="settings.service_url" target="_blank">
        {{ ticketWidgetSection.fields.button_buy_ticket }}
      </v-btn>
    </div>

    <!-- Виджет с информацией от tonline -->
    <kk-ticket-widget-tonline
      v-else-if="canBuyTicket"
      :service-id="settings.service_id"
      :schedule="schedule"
    />
  </div>
</template>

<script>
import KkTicketWidgetTonline from "./KkTicketWidgetTonline.vue";
import KkArticleInfo from "./KkArticleInfo.vue";
export default {
  name: "KkTicketWidget",
  components: { KkTicketWidgetTonline, KkArticleInfo },
  props: {
    settings: {
      type: Object,
      required: true,
    },
    schedule: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ticketWidgetSection() {
      return this.$store.getters.getTicketWidget;
    },
    canBuyTicket() {
      return this.schedule?.hasAvailableDates();
    },
  },
};
</script>
