<template>
  <div v-if="pageLoaded">
    <page-title :title="pageData.fields.title" />

    <kk-article v-if="pageLoaded" ref="refarticle" :contents="visitorsContents">
      <kk-dynamic-html
        v-if="pageData.fields.article.length > 0"
        class="subsection"
        :html="pageData.fields.article"
      />

      <visitors-operating-hours
        :section-data="pageData.visitors_hour_section"
      />

      <visitors-prices :section-data="pageData.visitors_ticket_section" />

      <visitors-subscriptions
        :section-data="pageData.visitors_subscription_section"
      />

      <visitors-pushkin-card
        :section-data="pageData.visitors_pushkin_card_section"
      />

      <contacts-map :section-data="pageData.contacts_map_section" />

      <visitors-rules :section-data="pageData.visitors_rules_section" />

      <visitors-inclusive :section-data="pageData.visitors_inclusive_section" />

      <visitors-faq :section-data="pageData.visitors_faq_section" />
    </kk-article>

    <zuruck with-aux-bar />
  </div>
</template>

<script>
import VisitorsRepository from "@/api/visitors.js";
import KkArticle from "@/components/Article/KkArticle.vue";
import PageTitle from "@/components/PageTitle.vue";
import VisitorsOperatingHours from "./VisitorsOperatingHours.vue";
import VisitorsPrices from "./VisitorsPrices.vue";
import VisitorsSubscriptions from "./VisitorsSubscriptions.vue";
import ContactsMap from "@/pages/Contacts/ContactsMap.vue";
import VisitorsRules from "./VisitorsRules.vue";
import VisitorsInclusive from "./VisitorsInclusive.vue";
import VisitorsFaq from "./VisitorsFaq.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Zuruck from "@/components/Zuruck.vue";
import VisitorsPushkinCard from "./VisitorsPushkinCard.vue";

const pageSections = () => ({
  "operating-hours": "visitors_hour_section",
  prices: "visitors_ticket_section",
  subscriptions: "visitors_subscription_section",
  "pushkin-card": "visitors_pushkin_card_section",
  map: "contacts_map_section",
  rules: "visitors_rules_section",
  inclusivity: "visitors_inclusive_section",
  faq: "visitors_faq_section",
});

export default {
  components: {
    KkArticle,
    PageTitle,
    VisitorsOperatingHours,
    VisitorsPrices,
    VisitorsSubscriptions,
    ContactsMap,
    VisitorsRules,
    VisitorsInclusive,
    VisitorsFaq,
    KkDynamicHtml,
    Zuruck,
    VisitorsPushkinCard,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        visitors_hour_section: { fields: {} },
        visitors_ticket_section: { fields: {}, children: [] },
        visitors_subscription_section: { fields: {}, children: [] },
        visitors_pushkin_card_section: { fields: {} },
        contacts_map_section: { fields: {}, children: [] },
        visitors_rules_section: { fields: {} },
        visitors_inclusive_section: { fields: {} },
        visitors_faq_section: { fields: {}, children: [] },
      },
    };
  },
  computed: {
    visitorsContents() {
      return Object.entries(pageSections()).reduce((product, entry) => {
        if (this.pageData[entry[1]] !== undefined) {
          product[entry[0]] = this.pageData[entry[1]].fields.title;
        }
        return product;
      }, {});
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await VisitorsRepository.getVisitors();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
  },
};
</script>
