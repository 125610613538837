import Repository from "@/plugins/axios";

export default {
  // Получить список новостей
  getNewsList(params) {
    return Repository.get(`/news`, { params });
  },

  // Получить страницу новости
  getNewsItem(ident) {
    return Repository.get(`/news/${ident}`);
  },
};
