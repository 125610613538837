<template>
  <div v-if="html.length > 0" class="kk-popover-container">
    <v-menu
      v-if="$vuetify.breakpoint.smAndUp"
      v-model="popover"
      :close-on-content-click="false"
      offset-y
      offset-x
      right
      content-class="kk-popover-menu"
      z-index="7"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          size="28"
          class="d-inline-block clickable line--text"
          v-bind="attrs"
          @click.prevent="on.click"
          >{{ icon }}</v-icon
        >
      </template>
      <article
        v-if="!disableArticle"
        class="kk-popover-content pa-5 pa-sm-10"
        v-html="html"
      />
      <div v-else class="kk-popover-content pa-5 pa-sm-10" v-html="html" />
    </v-menu>
    <div v-else>
      <v-icon
        size="28"
        class="d-inline-block clickable line--text"
        @click.prevent="popover = true"
        >{{ icon }}</v-icon
      >
      <v-dialog v-model="popover">
        <div class="kk-popover-content">
          <div class="close clickable" @click="popover = false">
            <v-icon>$vuetify.icons.Close</v-icon>
          </div>
          <article
            v-if="!disableArticle"
            class="pt-10 px-5 pb-5"
            v-html="html"
          />
          <div v-else class="pt-10 px-5 pb-5" v-html="html" />
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "KkPopover",
  props: {
    html: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "$vuetify.icons.Info",
    },
    disableArticle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popover: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.kk-popover-content {
  background-color: var(--v-bgSecondary-base);
  position: relative;

  .close {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
.kk-popover-menu {
  max-width: 320px;
  @media (min-width: 1024px) {
    max-width: 450px;
  }
}
</style>
