<template>
  <div>
    <div v-if="pageLoaded" v-show="isListRoute" class="section">
      <page-title :title="pageData.fields.title" />

      <tabs-filters
        id="content-top"
        ref="reffilter"
        v-model="filters"
        :meta="pageData.stat"
        :dic="dic"
        @change="queryItems"
      />

      <kk-dynamic-html
        v-if="pageData.fields.article && filters.kind == 'actual'"
        :html="pageData.fields.article"
        class="mb-8 mb-sm-16"
      />

      <v-row class="mt-6">
        <v-col
          v-for="(event, index) in pageData.children"
          :key="event.node_name"
          cols="12"
          :sm="getCols(index)"
        >
          <preview
            :payload="event"
            :to="{ name: 'Event', params: { ident: event.node_name } }"
            inner-info
            class="mb-1 mb-sm-9"
            :tag-route="tagRoute"
          />
        </v-col>
      </v-row>

      <infinite-loader
        :fetch="fetchMore"
        :disabled="disableInfiniteScroll"
        :visible="pageData.more > 0 || !pageLoaded"
      />

      <zuruck />
    </div>

    <router-view
      v-show="!isListRoute"
      :key="eventRouteKey"
      @page-loaded="$emit('page-loaded')"
    >
    </router-view>
  </div>
</template>

<script>
import EventsRepository from "@/api/events.js";
import PageTitle from "@/components/PageTitle.vue";
import Preview from "@/components/Preview/Preview.vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";
import TabsFilters from "@/components/TabsFilters.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    PageTitle,
    Preview,
    TabsFilters,
    InfiniteLoader,
    KkDynamicHtml,
    Zuruck,
  },
  props: {
    tag: {
      type: String,
      default: "",
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
        stat: {
          kind: {},
          year: {},
          tags: [],
        },
        more: 0,
      },
      filters: {
        kind: "actual",
        year: null,
        tags: [],
      },
      scrollTop: {
        Events: 0,
        EventsArchive: 0,
        EventsAll: 0,
      },
      offset: 0,
      loading: true,
    };
  },
  computed: {
    eventRouteKey() {
      return this.$route.path.split("/").slice(0, 3).join("/");
    },
    isListRoute() {
      return Object.keys(this.scrollTop).includes(this.routeKind);
    },
    disableInfiniteScroll() {
      return this.loading || this.pageData.more == 0;
    },
    dic() {
      return {
        kind: Object.keys(this.pageData.stat.kind).reduce((product, kind) => {
          product[kind] = this.pageData.fields[`section_${kind}`];
          return product;
        }, {}),
      };
    },
    getCols() {
      return (index) => {
        const row = Math.floor(index / 2);
        if (row % 2 == 0) {
          return 6;
        } else if ((row - 1) % 4 == 0) {
          return index % 2 == 0 ? 5 : 7;
        } else {
          return index % 2 == 0 ? 7 : 5;
        }
      };
    },
    queryParams() {
      const params = { kind: this.filters.kind };
      if (this.filters.year != null) params.year = this.filters.year;
      if (this.filters.tags.length > 0) params.tags = this.filters.tags;
      return params;
    },
    routeKind() {
      return this.$route.meta?.kind || this.$route.name;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    routeKind(newV, oldV) {
      // + disalbed scroll behaviour in router/index.js
      if (Object.keys(this.scrollTop).includes(oldV)) {
        this.scrollTop[oldV] = window.scrollY;
      }
      if (Object.keys(this.scrollTop).includes(newV)) {
        this.$nextTick(() => {
          window.scrollTo(0, this.scrollTop[newV]);
        });
      }
      if (this.tag.length > 0) {
        this.filters.tags = [this.tag];
        this.fixKindByRoute();
      }
    },
    async tag(newV) {
      if (newV.length > 0) {
        this.filters.tags = [newV];
        await this.loadPage();
        this.$vuetify.goTo("#content-top");
      }
    },
  },
  mounted() {
    if (this.tag.length > 0) {
      this.filters.tags = [this.tag];
      this.fixKindByRoute();
    }
    this.loadPage();
  },
  methods: {
    async loadPage() {
      await this.queryItems();
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      if (this.isListRoute) {
        this.$emit("page-loaded");
      }
      await this.$nextTick();
      this.$refs.reffilter.init();
      this.loading = false;
    },
    getRouteName() {
      switch (this.filters.kind) {
        case "archive":
          return "EventsArchiveByTag";
        case "all":
          return "EventsAllByTag";
        default:
          return "EventsByTag";
      }
    },
    tagRoute(tag) {
      return { name: this.getRouteName(), params: { tag: tag.id } };
    },
    fixKindByRoute() {
      const name = this.routeKind;
      if (name == "EventsArchive") {
        this.filters.kind = "archive";
      } else if (name == "Events") {
        this.filters.kind = "actual";
      } else if (name == "EventsAll") {
        this.filters.kind = "all";
      }
    },
    async queryItems() {
      const { data } = await EventsRepository.getEvents(this.queryParams);
      this.offset = data.children.length;
      this.pageData = data;
    },
    async fetchMore() {
      this.loading = true;

      const queryParams = { ...this.queryParams };
      queryParams.offset = this.offset;

      const { data } = await EventsRepository.getEvents(queryParams);

      this.offset += data.children.length;
      data.children.forEach((element) => {
        const index = this.pageData.children.length;
        this.$set(this.pageData.children, index, element);
      });
      this.pageData.more = data.more;

      this.loading = false;
    },
  },
};
</script>
