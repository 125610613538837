import {
  ValidationProvider,
  configure,
  ValidationObserver,
  extend,
} from "vee-validate";
import i18n from "./i18n.js";

import { required, size } from "vee-validate/dist/rules";

configure({
  // Установка способа отображения сообщений у всех Validation Rule
  defaultMessage: (_, values) => i18n.t(`Validations.${values._rule_}`, values),
});

extend("required", required);
extend("email", {
  validate: (value) => {
    const regex = new RegExp(
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    );
    return regex.test(value);
  },
});
extend("size", size);
extend("phone", {
  validate: (value) => {
    const regex = new RegExp(/^\+?[\d\s()-]+$/);
    return regex.test(value);
  },
});

export { ValidationProvider, ValidationObserver };
