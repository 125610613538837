<template>
  <div class="section">
    <section-title
      :title="sectionData.exhibitions_page.fields.title"
      :browse-title="sectionData.exhibitions_page.fields.button_all"
      :browse-link="{ path: '/exhibitions' }"
    />

    <horizontal-scroller
      :items="sectionData.exhibitions_page.children"
      class="subsection"
      content-class="mx-n3"
    >
      <template v-slot:append>
        <horizontal-scroller-end-link
          :browse-title="sectionData.exhibitions_page.fields.button_all"
          :browse-link="{ path: '/exhibitions' }"
        />
      </template>
      <template v-slot="{ item }">
        <preview
          :payload="item"
          :to="{ name: 'Exhibition', params: { ident: item.node_name } }"
          :tag-route="tagRouteInListExhibitions"
          class="mx-3"
        />
      </template>
    </horizontal-scroller>

    <section-title
      :title="sectionData.events_page.fields.title"
      :browse-title="sectionData.events_page.fields.button_all"
      :browse-link="{ path: '/events' }"
    />

    <horizontal-scroller
      :items="sectionData.events_page.children"
      class="subsection"
      content-class="mx-n3"
    >
      <template v-slot:append>
        <horizontal-scroller-end-link
          :browse-title="sectionData.events_page.fields.button_all"
          :browse-link="{ path: '/events' }"
        />
      </template>
      <template v-slot="{ item }">
        <preview
          :payload="item"
          :to="{ name: 'Event', params: { ident: item.node_name } }"
          :tag-route="tagRouteInListEvents"
          inner-info
          class="mx-3"
        />
      </template>
    </horizontal-scroller>

    <section-title
      :title="sectionData.news_page.fields.title"
      :browse-title="sectionData.news_page.fields.button_all"
      :browse-link="{ name: 'NewsList' }"
    />

    <div class="subsection">
      <news-list-item
        v-for="newsItem in sectionData.news_page.children"
        :key="newsItem.node_name"
        :payload="newsItem"
      />
    </div>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import Preview from "@/components/Preview/Preview.vue";
import NewsListItem from "@/components/NewsListItem.vue";
import HorizontalScrollerEndLink from "@/components/HorizontalScrollerEndLink.vue";

export default {
  name: "SearchAlter",
  components: {
    SectionTitle,
    HorizontalScroller,
    HorizontalScrollerEndLink,
    Preview,
    NewsListItem,
  },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    tagRouteInListEvents(tag) {
      return { name: "Events", params: { tag: tag.id } };
    },
    tagRouteInListExhibitions(tag) {
      return { name: "Exhibitions", params: { tag: tag.id } };
    },
  },
};
</script>
