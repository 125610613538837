import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";

// Регистрация компонентов
import "./plugins/components";

// Аналитика и иже с ней
import "./plugins/analytics";

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  i18n,
  router,
  store,
  vuetify,
  components: { App },
  template: "<App/>",
});
