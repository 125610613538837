import BaseRepository from "@/api/base.js";

const baseFactory = () => {
  return {
    system_section: {
      fields: {},
      system_settings_scripts: { fields: {}, children: [] },
    },
    header_section: { fields: {}, children: [] },
    social_section: { fields: {}, children: [] },
    footer_section: { fields: {}, children: [] },
    ticket_widget_section: { fields: {} },
    notfound_page: { fields: {}, fields_names: {}, children: [] },
    lang_code: "",
  };
};

export default {
  state: {
    baseData: baseFactory(),
    isInit: false,
    isInternalNavigation: false,
    isMainLoading: true,
    pageNotFoundIndex: 0,
  },
  actions: {
    async fetchBase({ commit }) {
      try {
        const { data } = await BaseRepository.getBase();
        commit("setBaseData", data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getWindowTitle(state) {
      return (addTitle) => {
        if (!state.baseData.system_section.fields.title) return undefined;
        return addTitle || state.baseData.system_section.fields.title;
      };
    },
    getSystem(state) {
      return state.baseData.system_section;
    },
    getHeader(state) {
      return state.baseData.header_section;
    },
    getSocial(state) {
      return state.baseData.social_section;
    },
    getFooter(state) {
      return state.baseData.footer_section;
    },
    getTicketWidget(state) {
      return state.baseData.ticket_widget_section;
    },
    getPageNotFound(state) {
      return {
        fields: state.baseData.notfound_page.fields,
        fields_names: state.baseData.notfound_page.fields_names,
        item: state.baseData.notfound_page.children[state.pageNotFoundIndex],
      };
    },
    getLang(state) {
      return state.baseData.lang_code;
    },
    getInternalLoading(state) {
      return state.isInternalNavigation;
    },
    getMainLoading(state) {
      return state.isMainLoading;
    },
    isInit(state) {
      return state.isInit;
    },
  },
  mutations: {
    setBaseData(state, payload) {
      const baseData = baseFactory();
      for (let prop in payload) {
        baseData[prop] = payload[prop];
      }
      state.baseData = baseData;
      state.isInit = true;
    },
    setLang(state, payload) {
      state.baseData.lang_code = payload;
    },
    incrementPageNotFoundIndex(state) {
      if (
        state.pageNotFoundIndex >=
        state.baseData.notfound_page.children.length - 1
      ) {
        state.pageNotFoundIndex = 0;
      } else {
        state.pageNotFoundIndex += 1;
      }
    },
    hideNotification(state) {
      state.baseData.system_section.fields.notification = "";
    },
    setStartInternalLoading(state) {
      state.isInternalNavigation = true;
    },
    setFinishInternalLoading(state) {
      state.isInternalNavigation = false;
    },
    setFinishMainLoading(state) {
      state.isMainLoading = false;
    },
  },
};
