<template>
  <div class="section">
    <page-title :title="$t('Common.searchPage.title')" />

    <v-row class="mb-4 mb-md-8">
      <v-col cols="12" sm="9" md="8" lg="6" xl="5">
        <v-text-field
          v-model="term"
          outlined
          append-icon="$vuetify.icons.Search"
          hide-details
          @change="onInputChange"
          @blur="onInputBlur"
        />
      </v-col>
    </v-row>

    <v-row v-if="hasResults" id="search-top-page" class="align-stretch">
      <v-col cols="12" sm="9">
        <div
          v-for="(item, index) in searchData.results"
          :key="index"
          class="border-top mb-3 mb-sm-6 pt-3 pt-sm-6"
        >
          <div class="mb-1 mb-md-3 d-sm-flex align-center">
            <router-link
              :to="item.to"
              class="subtitle1 longread search-link d-block mb-2 mb-sm-0 pr-sm-6"
            >
              {{ item.fields.title }}
            </router-link>
            <v-spacer />
            <div
              v-if="item.fields.date"
              class="longread pr-sm-6 mb-2 mb-sm-0 text-no-wrap"
            >
              {{ item.fields.date }}
            </div>
            <div class="mx-n1 mx-sm-0">
              <tag-chip @click="setCategory(item.category)">
                {{ $t(`Common.searchPage.categories.${item.category}`) }}
              </tag-chip>
            </div>
          </div>
          <div
            class="search-quote mb-2 mb-md-4"
            v-html="item.quote.best_quote"
          />
          <div v-if="item.quote.cnt_more > 0" class="longread textLight--text">
            {{ $tc("Common.searchPage.matches", item.quote.cnt_more) }}
          </div>
        </div>
      </v-col>
      <v-col sm="3" class="d-none d-sm-block pl-16">
        <div class="filter-sticky">
          <search-filter
            v-model="category"
            :items="categories"
            @input="onFilterChange"
          />
        </div>
      </v-col>
    </v-row>

    <v-row v-else-if="hasRequest">
      <v-col>{{
        $t("Common.searchPage.notfound1", { term: searchData.requested })
      }}</v-col>
    </v-row>

    <v-row v-if="hasAlter && hasRequest" class="mb-7 mb-md-14 mt-0">
      <v-col>{{ $t("Common.searchPage.notfound2") }}</v-col>
    </v-row>

    <div v-if="hasAlter && !hasResults">
      <search-alter :section-data="searchData.alter" />
    </div>

    <infinite-loader
      :fetch="fetchMore"
      :disabled="disableInfiniteScroll"
      :visible="searchData.more > 0"
    />

    <auxiliary-menu-bar
      v-if="hasResults"
      v-slot="scope"
      class="justify-space-between"
    >
      <auxiliary-menu
        v-slot="scopeChild"
        :title="$t('Common.searchPage.categoriesTitle')"
        icon="$vuetify.icons.Navigation"
        @toggle="scope.toggle"
      >
        <search-filter
          v-model="category"
          :items="categories"
          @input="
            onFilterChange();
            scopeChild.collapse();
          "
        />
      </auxiliary-menu>
    </auxiliary-menu-bar>
  </div>
</template>

<script>
import SupplementRepository from "@/api/supplement.js";
import TagChip from "@/components/Tags/TagChip.vue";
import PageTitle from "@/components/PageTitle.vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";
import SearchFilter from "./SearchFilter.vue";
import AuxiliaryMenuBar from "@/components/AuxiliaryMenu/AuxiliaryMenuBar.vue";
import AuxiliaryMenu from "@/components/AuxiliaryMenu/AuxiliaryMenu.vue";
import SearchAlter from "./SearchAlter.vue";

const searchDataFactory = () => ({
  stat: {},
  results: [],
  alter: {},
  more: 0,
  requested: "",
});

export default {
  components: {
    TagChip,
    PageTitle,
    InfiniteLoader,
    SearchFilter,
    AuxiliaryMenuBar,
    AuxiliaryMenu,
    SearchAlter,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(
        this.$t("Common.searchPage.title")
      ),
    };
  },
  data() {
    return {
      term: "",
      offset: 0,
      category: "all",
      loading: true,
      searchData: searchDataFactory(),
    };
  },
  computed: {
    hasRequest() {
      return this.searchData.requested.length > 0;
    },
    hasAlter() {
      // вернуть признак, есть ли доп. элементы для показа если ничего не нашли
      return Object.entries(this.searchData.alter).length > 0;
    },
    hasResults() {
      // вернуть признак, нашли ли чего
      return this.searchData.results.length > 0;
    },
    categories() {
      return Object.entries(this.searchData.stat).map((entry) => ({
        id: entry[0],
        text: this.$t(`Common.searchPage.categories.${entry[0]}`),
        count: entry[1],
      }));
    },
    disableInfiniteScroll() {
      return this.loading || this.searchData.more == 0;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.onInputChange();
      }
    },
    "$store.getters.getAppSearch"(newV) {
      this.term = newV;
      this.onInputChange();
    },
  },
  mounted() {
    this.term = this.$store.getters.getAppSearch;
    this.onInputChange();
  },
  methods: {
    async queryItems() {
      this.loading = true;
      this.offset = 0;

      if (this.term && this.term.length > 0) {
        try {
          const queryParams = { term: this.term, category: this.category };

          const { data } = await SupplementRepository.search(queryParams);
          this.searchData = data;
          this.offset = data.results.length;
        } catch (error) {
          if (error.response && error.response.status == 422) {
            this.searchData = searchDataFactory();
          }
        }
      } else {
        this.searchData = searchDataFactory();
      }
      this.loading = false;
      this.$emit("page-loaded");
    },

    async fetchMore() {
      this.loading = true;

      const queryParams = {
        term: this.term,
        category: this.category,
        offset: this.offset,
      };

      const { data } = await SupplementRepository.search(queryParams);

      this.offset += data.results.length;
      data.results.forEach((element) => {
        const index = this.searchData.results.length;
        this.$set(this.searchData.results, index, element);
      });
      this.searchData.more = data.more;

      this.loading = false;
    },

    onInputChange() {
      this.category = "all";
      this.queryItems();
    },

    onInputBlur() {
      if (!(this.term && this.term.length > 0)) {
        this.searchData = searchDataFactory();
      }
    },

    onInputClear() {
      this.term = "";
      this.onInputChange();
    },

    onFilterChange() {
      document
        .getElementById("search-top-page")
        .scrollIntoView({ behavior: "instant" });
      this.queryItems();
    },

    setCategory(category) {
      this.category = category;
      this.onFilterChange();
    },
  },
};
</script>
<style scoped lang="scss">
:deep(.search-quote b) {
  color: var(--v-mira-base);
  font-weight: normal;
}
.filter-sticky {
  position: sticky;
  top: 100px;
}
#search-top-page {
  margin-top: -60px;
  padding-top: 60px;
}
</style>
