<template>
  <div v-if="pageLoaded" class="section">
    <image-header
      ref="refimageheadersub"
      observed-id="image-header-sub"
      :image="pageData.fields.image"
    />

    <kk-article
      ref="refarticlesub"
      :breadcrumbs="breadcrumbsItems"
      :contents="pageData.fields.contents"
      :ticket-settings="pageData.fields"
    >
      <v-row>
        <v-col>
          <h2>{{ pageData.fields.title }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <kk-dynamic-html :html="pageData.fields.article" />
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col>
          <tag-list :tags="pageData.event_fields.tags" :tag-route="tagRoute" />
        </v-col>
      </v-row>
    </kk-article>

    <section-title
      :title="pageData.events_fields.title_other"
      :browse-title="pageData.events_fields.button_all"
      :browse-link="{ path: '/events' }"
    />

    <horizontal-scroller :items="pageData.more" content-class="mx-n3">
      <template v-slot:append>
        <horizontal-scroller-end-link
          :browse-title="pageData.events_fields.button_all"
          :browse-link="{ path: '/events' }"
        />
      </template>
      <template v-slot="{ item }">
        <preview
          :payload="item"
          :to="{ name: 'Event', params: { ident: item.node_name } }"
          :tag-route="tagRouteInList"
          inner-info
          class="mx-3"
        />
      </template>
    </horizontal-scroller>
  </div>
</template>

<script>
import EventsRepository from "@/api/events.js";
import KkArticle from "@/components/Article/KkArticle.vue";
import ImageHeader from "@/components/ImageHeader.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import TagList from "@/components/Tags/TagList.vue";
import Preview from "@/components/Preview/Preview.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import HorizontalScrollerEndLink from "@/components/HorizontalScrollerEndLink.vue";

export default {
  components: {
    KkArticle,
    ImageHeader,
    HorizontalScroller,
    TagList,
    Preview,
    KkDynamicHtml,
    SectionTitle,
    HorizontalScrollerEndLink,
  },
  props: {
    ident: {
      type: String,
      required: true,
    },
    subIdent: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        main_fields: {},
        events_fields: {},
        event_fields: {},
        more: [],
        kind: "actual",
      },
    };
  },
  computed: {
    breadcrumbsItems() {
      const { ident } = this;
      const { kind } = this.pageData;
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.events_fields.title || "",
          to: { path: "/events" },
          exact: true,
          disabled: false,
        },
        ...(kind != "actual"
          ? [
              {
                text: this.pageData.events_fields[`section_${kind}`] || "",
                to: { path: `/events/${kind}` },
                exact: true,
                disabled: false,
              },
            ]
          : []),
        {
          text: this.pageData.event_fields.title || "",
          to: { name: "Event", params: { ident } },
          exact: true,
          disabled: false,
        },
      ];
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await EventsRepository.getSubEvent(
        this.ident,
        this.subIdent
      );
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      await this.$nextTick();
      await this.$nextTick();

      this.$refs.refarticlesub.initObserver();
      this.$refs.refimageheadersub.initObserver();
    },
    tagRoute(tag) {
      const name = this.pageData.kind == "archive" ? "EventsArchive" : "Events";
      return { name, params: { tag: tag.id } };
    },
    tagRouteInList(tag) {
      return { name: "Events", params: { tag: tag.id } };
    },
  },
};
</script>
