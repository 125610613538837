<template>
  <div>
    <div v-if="!disableSearch">
      <v-text-field
        v-model="search"
        dense
        outlined
        append-icon="$vuetify.icons.Search"
        hide-details
      />
    </div>
    <kk-scrollbar v-if="itemsCompiled.length > 0" class="checkbox__container">
      <div
        v-for="item in itemsCompiled"
        :key="item.text"
        class="checkbox__item"
      >
        <v-checkbox
          v-model="selectedItems"
          :value="item.text"
          :ripple="false"
          :label="item.text"
          hide-details
          on-icon="$vuetify.icons.CheckOn"
          off-icon="$vuetify.icons.CheckOff"
          class="mt-0 pt-0"
          @change="$emit('change')"
        />
      </div>
    </kk-scrollbar>
    <div v-else-if="search.length > 0" class="checkbox__container">
      {{ $t("Common.checkboxListNotFound") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckBoxListFilter",

  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    disableSearch: {
      type: Boolean,
      default: false,
    },
    extSearch: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: "",
    };
  },

  computed: {
    selectedItems: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    itemsCompiled() {
      const items = this.items.map((item, index) => ({
        id: index,
        text: item,
      }));
      if (this.extSearch) {
        return items;
      } else {
        return items.filter((item) => {
          return (
            item.text.toLowerCase().includes(this.search.toLowerCase()) ||
            this.search.length == 0
          );
        });
      }
    },
  },

  watch: {
    search(newVal) {
      this.$emit("search", newVal);
    },
  },
};
</script>
<style scoped lang="scss">
.checkbox__container {
  max-height: 312px;
  overflow-y: auto;
  margin-top: 6px;
  margin-bottom: 18px;
  .checkbox__item {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
</style>
