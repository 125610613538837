<template>
  <div v-if="pageLoaded" class="section">
    <page-title :title="pageData.fields.title" />

    <kk-dynamic-html
      v-if="pageData.fields.article"
      :html="pageData.fields.article"
      class="subsection"
    />

    <v-row class="section flex-nowrap">
      <v-col cols="12" lg="9">
        <accordion :items="pageData.categories">
          <template v-slot:header="{ item }">
            {{ item.fields.title }}
          </template>
          <template v-slot="{ item }">
            <div class="mb-6 mt-n4 mt-sm-n6">
              <file-attach
                v-for="document in item.children"
                :key="document.node_name"
                :item="{
                  f_path: document.fields.file,
                  f_title: document.fields.title,
                }"
              />
            </div>
          </template>
        </accordion>

        <file-attach
          v-for="document in pageData.documents"
          :key="document.node_name"
          :item="{
            f_path: document.fields.file,
            f_title: document.fields.title,
          }"
        />
      </v-col>
      <v-col class="d-none d-lg-block" lg="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import DocumentsRepository from "@/api/documents.js";
import PageTitle from "@/components/PageTitle.vue";
import FileAttach from "@/components/FileAttach.vue";
import Accordion from "@/components/Accordion.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  components: {
    PageTitle,
    FileAttach,
    Accordion,
    KkDynamicHtml,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        categories: [],
        documents: [],
      },
    };
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await DocumentsRepository.getDocuments({});
      this.pageData = data;
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
  },
};
</script>
