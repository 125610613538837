<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M20.2012 40C9.20117 40 0.201172 31 0.201172 20C0.201172 9 9.20117 0 20.2012 0C31.2012 0 40.2012 9 40.2012 20C40.2012 31.1 31.2012 40 20.2012 40ZM20.2012 0.799999C9.60117 0.799999 1.00117 9.4 1.00117 20C1.00117 30.6 9.60117 39.2 20.2012 39.2C30.8012 39.2 39.4012 30.6 39.4012 20C39.4012 9.5 30.8012 0.799999 20.2012 0.799999Z"
        fill="currentColor"
      />
      <path
        d="M10.8008 12.9938H7.80078L8.80078 11.0938H12.7008V29.0938H10.7008V12.9938H10.8008Z"
        fill="currentColor"
      />
      <path
        d="M15.001 29.0969L21.501 21.4969C22.001 20.9969 23.201 19.4969 23.601 18.8969C24.401 17.6969 24.601 16.8969 24.601 16.0969C24.601 14.0969 23.201 12.5969 21.001 12.5969C19.701 12.5969 18.701 13.1969 18.001 14.1969C17.701 14.6969 17.401 15.3969 17.401 16.2969H15.401C15.501 14.4969 16.001 13.3969 16.701 12.5969C17.401 11.6969 18.701 10.7969 20.901 10.7969C24.101 10.7969 26.401 13.0969 26.401 16.1969C26.401 17.3969 26.101 18.4969 25.001 20.0969C24.301 21.1969 23.301 22.2969 22.601 23.0969L18.901 27.2969H26.401V29.0969H15.001Z"
        fill="currentColor"
      />
      <path
        d="M28.3008 19.1937H33.1008V14.5938H35.0008V19.1937H39.8008V20.9938H35.0008V25.5938H33.1008V20.9938H28.3008V19.1937Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 40,
    },
    height: {
      type: [Number, String],
      default: 40,
    },
  },
};
</script>
