<template>
  <div>
    <div v-if="pageLoaded" v-show="isListRoute" class="section">
      <page-title :title="pageData.fields.title" />

      <tabs-filters
        id="content-top"
        ref="reffilter"
        v-model="filters"
        :meta="pageData.stat"
        :dic="dic"
        @change="queryItems"
      />

      <kk-dynamic-html
        v-if="pageData.fields.article && filters.kind == 'actual'"
        :html="pageData.fields.article"
        class="mb-8 mb-sm-16"
      />

      <v-row class="mt-6">
        <v-col
          v-for="tour in pageData.children"
          :key="tour.node_name"
          cols="12"
        >
          <preview
            :payload="tour"
            :to="{ name: 'Tour', params: { ident: tour.node_name } }"
            inner-info
            :glow="tour.fields.glow"
            full-width
            class="mb-1 mb-sm-9"
            :tag-route="tagRoute"
          />
        </v-col>
      </v-row>

      <v-row v-if="pageData.more > 0" class="justify-center">
        <v-col class="flex-grow-0">
          <v-btn
            outlined
            tile
            :disabled="disableInfiniteScroll"
            @click="fetchMore"
          >
            {{ $t("Common.loadMore") }}
          </v-btn>
        </v-col>
      </v-row>

      <persons-list
        :title="pageData.fields.section_guides"
        :persons="pageData.guides"
        class="guides-list"
      />

      <zuruck />
    </div>

    <router-view
      v-show="!isListRoute"
      :key="$route.path"
      @page-loaded="$emit('page-loaded')"
    >
    </router-view>
  </div>
</template>

<script>
import ToursRepository from "@/api/tours.js";
import PageTitle from "@/components/PageTitle.vue";
import Preview from "@/components/Preview/Preview.vue";
import TabsFilters from "@/components/TabsFilters.vue";
import PersonsList from "@/components/PersonsList.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    PageTitle,
    Preview,
    TabsFilters,
    PersonsList,
    KkDynamicHtml,
    Zuruck,
  },
  props: {
    tag: {
      type: String,
      default: "",
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
        guides: [],
        stat: {
          kind: {},
          year: {},
          tags: {},
        },
        more: 0,
      },
      filters: {
        kind: "actual",
        year: null,
        tags: [],
      },
      scrollTop: {
        Tours: 0,
        ToursArchive: 0,
        ToursAll: 0,
      },
      offset: 0,
      loading: true,
    };
  },
  computed: {
    disableInfiniteScroll() {
      return this.loading || this.pageData.more == 0;
    },
    isListRoute() {
      return Object.keys(this.scrollTop).includes(this.routeKind);
    },
    dic() {
      return {
        kind: Object.keys(this.pageData.stat.kind).reduce((product, kind) => {
          product[kind] = this.pageData.fields[`section_${kind}`];
          return product;
        }, {}),
      };
    },
    queryParams() {
      const params = { kind: this.filters.kind };
      if (this.filters.year != null) params.year = this.filters.year;
      if (this.filters.tags.length > 0) params.tags = this.filters.tags;
      return params;
    },
    routeKind() {
      return this.$route.meta?.kind || this.$route.name;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    routeKind(newV, oldV) {
      // + disalbed scroll behaviour in router/index.js
      if (Object.keys(this.scrollTop).includes(oldV)) {
        this.scrollTop[oldV] = window.scrollY;
      }
      if (Object.keys(this.scrollTop).includes(newV)) {
        this.$nextTick(() => {
          window.scrollTo(0, this.scrollTop[newV]);
          this.$refs.reffilter.init();
        });
      }
      if (this.tag.length > 0) {
        this.filters.tags = [this.tag];
        this.fixKindByRoute();
      }
    },
    async tag(newV) {
      if (newV.length > 0) {
        this.filters.tags = [newV];
        await this.loadPage();
        await this.$nextTick();
        this.$vuetify.goTo("#content-top");
      }
    },
  },
  mounted() {
    if (this.tag.length > 0) {
      this.filters.tags = [this.tag];
      this.fixKindByRoute();
    }
    this.loadPage();
  },
  methods: {
    async loadPage() {
      await this.queryItems();
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      if (this.isListRoute) {
        this.$emit("page-loaded");
        await this.$nextTick();
        this.$refs.reffilter.init();
      }
      this.loading = false;
    },
    async queryItems() {
      const { data } = await ToursRepository.getTours(this.queryParams);
      this.offset = data.children.length;
      this.pageData = data;
    },
    async fetchMore() {
      this.loading = true;

      const queryParams = { ...this.queryParams };
      queryParams.offset = this.offset;

      const { data } = await ToursRepository.getTours(queryParams);

      this.offset += data.children.length;
      data.children.forEach((element) => {
        const index = this.pageData.children.length;
        this.$set(this.pageData.children, index, element);
      });
      this.pageData.more = data.more;

      this.loading = false;
    },
    fixKindByRoute() {
      const name = this.routeKind;
      if (name == "ToursArchive") {
        this.filters.kind = "archive";
      } else if (name == "ToursAll") {
        this.filters.kind = "all";
      } else if (name == "Tours") {
        this.filters.kind = "actual";
      }
    },
    getRouteName() {
      switch (this.filters.kind) {
        case "archive":
          return "ToursArchiveByTag";
        case "all":
          return "ToursAllByTag";
        default:
          return "ToursByTag";
      }
    },
    tagRoute(tag) {
      return { name: this.getRouteName(), params: { tag: tag.id } };
    },
  },
};
</script>
<style scoped>
.guides-list {
  margin-top: 120px;
}
@media (max-width: 1023px) {
  .guides-list {
    margin-top: 60px;
  }
}
</style>
