<template>
  <div v-if="pageLoaded" class="section">
    <kk-breadcrumbs
      class="no-marker px-0 mb-5 mb-sm-10 mt-n4 mt-sm-0"
      :items="breadcrumbsItems"
    />

    <section-title :title="pageData.fields.title" />

    <v-row class="section flex-nowrap">
      <v-col cols="12" lg="9">
        <article
          v-if="pageData.fields.article.length"
          class="podcast-article"
          v-html="pageData.fields.article"
        />

        <v-list expand flat subheader class="podcast-expansion-list">
          <podcast
            v-for="(podcast, index) in pageData.children"
            :id="podcast.node_name"
            :key="index"
            :ref="`ref${podcast.node_name}`"
            :payload="podcast"
            :force-pause="forcePause(index)"
            :download-button="pageData.fields.button_download"
            :opened="openedId == podcast.node_name"
            @play="playingIndex = index"
          />
        </v-list>
      </v-col>
      <v-col class="d-none d-lg-block" lg="3"> </v-col>
    </v-row>

    <zuruck />
  </div>
</template>

<script>
import OnlineProjectsRepository from "@/api/online-projects.js";
import KkBreadcrumbs from "@/components/Article/KkBreadcrumbs.vue";
import Podcast from "@/components/Podcast.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    KkBreadcrumbs,
    Podcast,
    SectionTitle,
    Zuruck,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      playingIndex: -1,
      openedId: null,
      pageData: {
        fields: {},
        children: [],
        main_fields: {},
        online_projects_fields: {},
      },
    };
  },
  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.online_projects_fields.title || "",
          to: { name: "OnlineProjects" },
          exact: true,
          disabled: false,
        },
      ];
    },
    forcePause() {
      return (index) => {
        return this.playingIndex >= 0 && this.playingIndex !== index;
      };
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  beforeRouteUpdate(to, from, next) {
    this.handleHash(to.hash);
    next();
  },
  methods: {
    async loadPage() {
      const { data } = await OnlineProjectsRepository.getPodcasts({});
      this.pageData = data;
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.$emit("page-loaded");
      this.loading = false;

      await this.$nextTick();
      this.handleHash(this.$route.hash);
    },
    handleHash(hash) {
      if (hash && hash.length > 0) {
        const id = hash.substr(1);
        const ref = this.$refs[`ref${id}`];
        if (ref && ref[0]) {
          ref[0].expand();
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.podcast-article {
  margin-bottom: 40px;
  @media (min-width: 1024px) {
    margin-bottom: 80px;
  }
}
</style>
