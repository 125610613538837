import BaseRepository from "@/api/base.js";

export default {
  state: {
    servicesIndex: {},
    categoriesIndex: {},
    objectsIndex: {},
  },
  actions: {
    async fetchServices({ commit }) {
      try {
        const { data } = await BaseRepository.getTonlineServices();
        commit("setServices", data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getService(state) {
      return (serviceId) => state.servicesIndex[serviceId];
    },
    getAllCategories(state) {
      return state.categoriesIndex;
    },
  },
  mutations: {
    setServices(state, payload) {
      state.servicesIndex = payload.services;
      state.categoriesIndex = payload.categories;
      state.objectsIndex = payload.objects;
    },
  },
};
