import Main from "@/pages/Main/index.vue";
import Visitors from "@/pages/Visitors/index.vue";
import PushkinCard from "@/pages/PushkinCard.vue";
import Contacts from "@/pages/Contacts/index.vue";
import CustomPage from "@/pages/CustomPage";
import Documents from "@/pages/Documents";
import Projects from "@/pages/Projects";
import Podcasts from "@/pages/Podcasts";
import VirtualTours from "@/pages/VirtualTours";
import Souvenirs from "@/pages/Souvenirs";
import Publications from "@/pages/Publications";
import Search from "@/pages/Search/index.vue";
import PageNotFound from "@/pages/CrossPages/PageNotFound.vue";

import aboutRoutes from "./routes/about.js";
import shsiRoutes from "./routes/shsi.js";
import collectionRoutes from "./routes/collection.js";
import libraryRoutes from "./routes/library.js";
import exhibitionsRoutes from "./routes/exhibitions.js";
import eventsRoutes from "./routes/events.js";
import toursRoutes from "./routes/tours.js";
import pupilToursRoutes from "./routes/tours-pupil.js";
import newsRoutes from "./routes/news.js";
import onlineProjectsRoutes from "./routes/online-projects.js";
import redirectRoutes from "./routes/redirects.js";

export default [
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    path: "/visitors",
    name: "Visitors",
    component: Visitors,
  },
  {
    path: "/pushkin-card",
    name: "PushkinCard",
    component: PushkinCard,
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
  },
  ...aboutRoutes,
  ...shsiRoutes,
  ...collectionRoutes,
  ...libraryRoutes,
  ...exhibitionsRoutes,
  ...eventsRoutes,
  ...toursRoutes,
  ...pupilToursRoutes,
  ...newsRoutes,
  ...onlineProjectsRoutes,
  {
    path: "/documents",
    name: "Documents",
    component: Documents,
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects,
  },
  {
    path: "/podcast",
    name: "Podcasts",
    component: Podcasts,
  },
  {
    path: "/virtual-tour",
    name: "VirtualTours",
    component: VirtualTours,
    props: true,
    meta: {
      group: "VirtualTours",
    },
    children: [
      {
        path: ":ident",
        meta: {
          group: "VirtualTours",
        },
      },
    ],
  },
  {
    path: "/souvenirs",
    name: "Souvenirs",
    component: Souvenirs,
  },
  {
    path: "/publications",
    name: "Publications",
    component: Publications,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  ...Object.entries(redirectRoutes).map((entry, index) => ({
    path: entry[0],
    name: `Redirect${index}`,
    redirect: () => {
      return entry[1];
    },
  })),
  {
    path: "/pages/:ident",
    name: "CustomPage",
    component: CustomPage,
    props: true,
  },
  {
    path: "*",
    name: "NoRoute",
    redirect: () => {
      return `/404`;
    },
  },
];
