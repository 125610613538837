import "vue-custom-scrollbar/dist/vueScrollbar.css";
import "leaflet/dist/leaflet.css";

import { ValidationProvider, ValidationObserver } from "./validate.js";
import Vue from "vue";
import { Plugin as VueFragment } from "vue-fragment";
import VueMeta from "vue-meta";
import VueLazyLoad from "vue-lazyload";
import VueDragscroll from "vue-dragscroll";
import InfiniteScroll from "vue-infinite-scroll";
import LoadScript from "vue-plugin-load-script";
import KkScrollbar from "@/components/KkScrollbar.vue";

Vue.component("kk-scrollbar", KkScrollbar);
Vue.component("validation-provider", ValidationProvider);
Vue.component("validation-observer", ValidationObserver);
Vue.use(VueFragment);
Vue.use(VueMeta);
Vue.use(VueLazyLoad);
Vue.use(VueDragscroll);
Vue.use(InfiniteScroll);
Vue.use(LoadScript);
