<template>
  <div>
    <section-title v-if="title.length > 0" :title="title" />

    <horizontal-scroller
      :items="persons"
      content-class="mx-n6 pb-sm-8"
      unlimited-item-width
    >
      <template v-slot="{ item }">
        <person
          :name="item.fields.title"
          :role="item.fields.role"
          :avatar="item.fields.photo"
          :description="item.fields.dsc"
          :hide-details="hideDetails"
          :disable-wrap-name="disableWrapName"
          class="mx-6"
        />
      </template>
    </horizontal-scroller>
  </div>
</template>

<script>
import HorizontalScroller from "./HorizontalScroller.vue";
import Person from "./Person.vue";
import SectionTitle from "./SectionTitle.vue";
export default {
  name: "PersonsList",
  components: {
    HorizontalScroller,
    SectionTitle,
    Person,
  },
  props: {
    persons: {
      type: Array,
      required: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    disableWrapName: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
