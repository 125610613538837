<template>
  <div v-if="sectionData">
    <section-title
      header-id="projects"
      :title="sectionData.fields.title"
      :browse-title="sectionData.fields.browse_all"
      :browse-link="{ name: 'Projects' }"
    />

    <horizontal-scroller :items="sectionData.children" content-class="mx-n6">
      <template v-slot="{ item }">
        <div class="mx-6 project-item">
          <div class="mb-3 mb-sm-6">
            <v-avatar
              :size="$vuetify.breakpoint.smAndUp ? 288 : 250"
              class="project-avatar"
            >
              <image-clipped
                :image="item.fields.image"
                :fit="$vuetify.breakpoint.smAndUp ? 288 : 250"
              />
            </v-avatar>
          </div>
          <h4 class="mb-3 mb-sm-6 selectable-text">{{ item.fields.title }}</h4>
          <kk-dynamic-html
            :html="item.fields.article"
            class="selectable-text"
          />
        </div>
      </template>
    </horizontal-scroller>
  </div>
</template>
<script>
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ImageClipped from "@/components/ImageClipped.vue";

export default {
  name: "AboutProjects",
  components: {
    HorizontalScroller,
    SectionTitle,
    KkDynamicHtml,
    ImageClipped,
  },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
.project-item {
  min-width: 264px;
  @media (min-width: 1024px) {
    min-width: 442px;
  }

  .project-avatar {
    pointer-events: none;
  }
}
</style>
