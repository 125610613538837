<template>
  <div class="kk-menu-button" @click="$emit('click')">
    <div class="kk-menu-button__inner">
      <v-icon
        v-if="icon.length > 0"
        color="textBase"
        class="kk-menu-button__icon"
        >{{ icon }}</v-icon
      >
      <div class="text-no-wrap">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuxiliaryMenuButton",
  props: {
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped lang="scss">
.kk-menu-button {
  z-index: 9;
  flex-grow: 1;
  color: var(--v-textBase-base);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;

  .kk-menu-button__inner {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media (min-width: 430px) {
      flex-direction: row;
    }

    .kk-menu-button__icon {
      margin-right: 0 !important;
      @media (min-width: 430px) {
        margin-right: 8px !important;
      }
    }

    .v-icon {
      margin-right: 8px;
    }
  }
}
</style>
