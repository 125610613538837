<template>
  <div v-if="sectionData" class="subsection">
    <section-title
      header-id="collection"
      :title="sectionData.fields.title"
      :browse-title="sectionData.fields.browse_all"
      :browse-link="{ name: 'Collection' }"
    />
    <fluid-collection :items="sectionData.children" class="mb-5 mb-sm-10" />
    <kk-dynamic-html :html="sectionData.fields.article" />
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import FluidCollection from "@/pages/Collection/FluidCollection.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "AboutCollection",
  components: { SectionTitle, KkDynamicHtml, FluidCollection },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
