<template>
  <div v-if="pageLoaded" class="section">
    <page-title :title="pageData.fields.title" />

    <kk-dynamic-html
      v-if="pageData.fields.article"
      :html="pageData.fields.article"
      class="mb-5 mb-sm-10"
    />

    <v-row>
      <v-col
        v-for="(item, index) in pageData.children"
        :key="index"
        cols="12"
        sm=""
        :style="{ width: `${item.fields.width}%` }"
      >
        <image-extended :image="item.fields.image" class="souvenir__image" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SimplePagesRepository from "@/api/simple.js";
import PageTitle from "@/components/PageTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ImageExtended from "@/components/ImageExtended.vue";

export default {
  components: {
    PageTitle,
    KkDynamicHtml,
    ImageExtended,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
      },
    };
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await SimplePagesRepository.getSouvenirs({});
      this.pageData = data;
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
  },
};
</script>
<style scoped lang="scss">
.souvenir__image {
  @media (min-width: 1024px) {
    height: 45vw;
  }
}
</style>
