<template>
  <div class="section__gallery">
    <div ref="reftouchpanel" class="mb-5 mb-sm-8 gallery-touch-panel">
      <div class="scroller__container">
        <div class="scroller">
          <div class="scroller__item left" :style="leftItem">
            <image-extended :image="leftElement" class="scroller__image" />
          </div>
          <div class="scroller__item this" :style="thisItem">
            <image-extended :image="thisElement" class="scroller__image" />
          </div>
          <div class="scroller__item right" :style="rightItem">
            <image-extended :image="rightElement" class="scroller__image" />
          </div>
        </div>
        <div class="ear ear--left clickable">
          <kk-arrow :rotate="180" @click.stop.prevent="scrollLeft" />
        </div>
        <div class="ear ear--right clickable">
          <kk-arrow :rotate="0" @click.stop.prevent="scrollRight" />
        </div>
      </div>
    </div>

    <div class="horz-scroller-container">
      <horizontal-scroller
        ref="refscroller"
        :items="items"
        content-class="mx-n3"
        :disabled="items.length == 1"
        stick-to="left"
      >
        <template v-slot="{ item, index }">
          <div
            class="kk-hoverable-image mx-3 mb-10 thumb-container"
            @click="scrollTo(index)"
          >
            <image-extended
              :image="item"
              :width="thumbWidth"
              :height="thumbHeight"
            />
            <div
              class="glower"
              :class="index == activeItemIndex ? 'glower--active' : ''"
            />
          </div>
        </template>
      </horizontal-scroller>

      <div class="fog" />
    </div>
  </div>
</template>
<script>
import KkArrow from "@/components/KkArrow.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import ImageExtended from "@/components/ImageExtended.vue";
export default {
  name: "ImageGallery",
  components: { KkArrow, HorizontalScroller, ImageExtended },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // constant props
      animationDuration: 0.5,
      animationTimingFunction: "ease-in-out",
      thumbWidth: 290,
      thumbHeight: 190,
      swipeDistance: 80,
      // vars
      activeItemIndex: 0,
      movingLeft: false,
      movingRight: false,
      touchstartX: 0,
      touchstartY: 0,
    };
  },
  computed: {
    moving() {
      return this.movingLeft || this.movingRight;
    },
    leftElement() {
      const index =
        this.activeItemIndex > 0
          ? this.activeItemIndex - 1
          : this.items.length - 1;
      return this.items[index];
    },
    thisElement() {
      return this.items[this.activeItemIndex];
    },
    rightElement() {
      const index =
        this.activeItemIndex < this.items.length - 1
          ? this.activeItemIndex + 1
          : 0;
      return this.items[index];
    },
    leftItem() {
      const transition = this.movingLeft
        ? `all ${this.animationDuration}s ${this.animationTimingFunction}`
        : "none";
      const left = this.movingLeft ? "0%" : "-100%";
      return { transition, left };
    },
    thisItem() {
      const transition = this.moving
        ? `all ${this.animationDuration}s ${this.animationTimingFunction}`
        : "none";
      const rightSide = this.movingRight ? "-50%" : "0%";
      const left = this.movingLeft ? "50%" : rightSide;
      return { transition, left };
    },
    rightItem() {
      const transition = this.movingRight
        ? `all ${this.animationDuration}s ${this.animationTimingFunction}`
        : "none";
      const left = this.movingRight ? "0%" : "100%";
      return { transition, left };
    },
  },
  mounted() {
    const zone = this.$refs.reftouchpanel;
    zone.addEventListener("pointerdown", this.onPointerDown, false);
    zone.addEventListener("pointerup", this.onPointerUp, false);
  },
  destroyed() {
    const zone = this.$refs.reftouchpanel;
    if (zone) {
      zone.removeEventListener("pointerdown", this.onPointerDown);
      zone.removeEventListener("pointerup", this.onPointerUp);
    }
  },
  methods: {
    onPointerDown(event) {
      this.touchstartX = event.screenX;
      this.touchstartY = event.screenY;
    },

    onPointerUp(event) {
      if (this.touchstartX < event.screenX - this.swipeDistance) {
        this.scrollLeft();
      } else if (this.touchstartX > event.screenX + this.swipeDistance) {
        this.scrollRight();
      }
    },

    async scrollLeft() {
      if (this.moving) return;

      this.movingLeft = true;
      await new Promise((resolve) =>
        setTimeout(resolve, this.animationDuration * 1000)
      );

      if (this.activeItemIndex == 0) {
        this.activeItemIndex = this.items.length - 1;
      } else {
        this.activeItemIndex--;
      }

      this.moveThumbToCurrent();

      this.movingLeft = false;
    },

    async scrollRight() {
      if (this.moving) return;

      this.movingRight = true;
      await new Promise((resolve) =>
        setTimeout(resolve, this.animationDuration * 1000)
      );

      if (this.activeItemIndex == this.items.length - 1) {
        this.activeItemIndex = 0;
      } else {
        this.activeItemIndex++;
      }

      this.moveThumbToCurrent();

      this.movingRight = false;
    },

    scrollTo(index) {
      this.activeItemIndex = index;
    },

    moveThumbToCurrent() {
      const el = this.$refs.refscroller.$el;
      const rect = el.getBoundingClientRect();
      el.scrollLeft =
        (this.thumbWidth - rect.width) / 2 +
        (this.thumbWidth + 24) * this.activeItemIndex -
        12;
    },
  },
};
</script>
<style lang="scss" scoped>
.section__gallery {
  .gallery-touch-panel {
    display: none;
    @media (min-width: 640px) {
      display: block;
    }
  }

  --height: 0;
  @media (min-width: 640px) {
    --height: 60vw;
  }
  @media (min-width: 1024px) {
    --height: 40vw;
  }
  @media (min-width: 1280px) {
    --height: 45vw;
  }
  @media (min-width: 1440px) {
    --height: 45vw;
  }
  @media (min-width: 1920px) {
    --height: 45vw;
  }

  .scroller__container {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    height: var(--height);

    .ear {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      position: absolute;
      top: 50%;
      margin-top: -26px;
      z-index: 4;
      &.ear--left {
        left: 20px;
      }
      &.ear--right {
        right: 20px;
      }
    }

    &:hover .ear {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    .scroller {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      height: var(--height);
      overflow-x: visible;

      .scroller__item {
        overflow-x: hidden;
        overflow-y: hidden;
        position: absolute;
        width: 100%;
        height: var(--height);
        &.this {
          z-index: 1;
        }
        &.left,
        &.right {
          z-index: 2;
        }

        .scroller__image {
          position: absolute;
          min-width: 100%;
          max-width: none;
          width: 100%;
          height: var(--height);
          left: 0;
          top: 0;
        }
      }
    }
  }

  .thumb-container {
    position: relative;
    .glower {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      background-color: var(--v-bgSecondary-base);
      &.glower--active {
        opacity: 0.5;
        transition: opacity 0.3s ease-in-out;
      }
      @media (max-width: 640px) {
        display: none;
      }
    }
  }

  .horz-scroller-container {
    position: relative;

    .fog {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 130px;
      background: linear-gradient(
        270deg,
        var(--v-bgPrimary-base) 0%,
        rgba(247, 246, 246, 0) 65.12%
      );
      pointer-events: none;
      @media (max-width: 640px) {
        display: none;
      }
    }
  }
}
</style>
