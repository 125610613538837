import Repository from "@/plugins/axios";

export default {
  // Получить стартовую (главную) страницу
  getMain() {
    return Repository.get("/main");
  },
  // Подгрузить ещё дни в календарь на главной
  getCalendar(dtb) {
    return Repository.get("/main/calendar", { params: { dtb } });
  },
};
