import Tours from "@/pages/Tours";
import Tour from "@/pages/Tour";

export default [
  {
    path: "/tours",
    component: Tours,
    props: true,
    children: [
      {
        path: "tag/:tag",
        name: "ToursByTag",
        props: true,
        meta: {
          kind: "Tours",
          group: "Tours",
        },
      },
      {
        path: "",
        name: "Tours",
        meta: {
          kind: "Tours",
          group: "Tours",
        },
      },
      {
        path: "archive/tag/:tag",
        name: "ToursArchiveByTag",
        props: true,
        meta: {
          kind: "ToursArchive",
          group: "Tours",
        },
      },
      {
        path: "archive",
        name: "ToursArchive",
        meta: {
          kind: "ToursArchive",
          group: "Tours",
        },
      },
      {
        path: "all/tag/:tag",
        name: "ToursAllByTag",
        props: true,
        meta: {
          kind: "ToursAll",
          group: "Tours",
        },
      },
      {
        path: "all",
        name: "ToursAll",
        meta: {
          kind: "ToursAll",
          group: "Tours",
        },
      },
      {
        path: ":ident",
        name: "Tour",
        component: Tour,
        props: true,
      },
    ],
  },
];
