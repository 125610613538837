<template>
  <div v-if="pageLoaded">
    <page-title :title="pageData.fields.title" />

    <kk-article
      v-if="pageLoaded"
      ref="refarticle"
      :contents="publicationsContents"
      class="subsection"
      dense
    >
      <kk-dynamic-html
        v-if="pageData.fields.article"
        :html="pageData.fields.article"
        class="subsection"
      />

      <div
        v-for="(item, index) in pageData.children"
        :key="index"
        class="publication-item"
      >
        <h3 :id="item.node_name" class="mb-3 mb-sm-6">
          {{ item.fields.title }}
        </h3>
        <kk-dynamic-html :html="item.fields.article" />
      </div>
    </kk-article>
  </div>
</template>

<script>
import SimplePagesRepository from "@/api/simple.js";
import PageTitle from "@/components/PageTitle.vue";
import KkArticle from "@/components/Article/KkArticle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  components: { PageTitle, KkArticle, KkDynamicHtml },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
      },
    };
  },
  computed: {
    publicationsContents() {
      return this.pageData.children.reduce((product, item) => {
        product[item.node_name] = item.fields.title;
        return product;
      }, {});
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await SimplePagesRepository.getPublications({});
      this.pageData = data;
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
  },
};
</script>
<style scoped lang="scss">
.publication-item:not(:last-of-type) {
  margin-bottom: 20px;
  @media (min-width: 1024px) {
    margin-bottom: 40px;
  }
}
</style>
