<template>
  <a class="kk-aux-menu" :href="href" target="_blank">
    <auxiliary-menu-button :title="title" :icon="icon" />
  </a>
</template>

<script>
import AuxiliaryMenuButton from "./AuxiliaryMenuButton.vue";
export default {
  name: "AuxiliaryMenuLink",
  components: { AuxiliaryMenuButton },
  props: {
    href: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped lang="scss">
.kk-aux-menu {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
}
</style>
