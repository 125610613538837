<template>
  <div v-if="sectionData" class="subsection">
    <section-title header-id="awards" :title="sectionData.fields.title" />

    <v-row no-gutters class="align-stretch">
      <v-col cols="12" xl="4" class="pb-5 pb-sm-10">
        <kk-dynamic-html :html="sectionData.fields.text_la_femme" />
      </v-col>
      <v-col cols="12" sm="9" xl="6" class="pb-5 pb-sm-10 pr-sm-6 px-xl-6">
        <v-img
          :src="sectionData.fields.image1_la_femme"
          class="img-femme"
          eager
        />
      </v-col>
      <v-col cols="12" sm="3" xl="2" class="pb-5 pb-sm-10">
        <v-img
          :src="sectionData.fields.image2_la_femme"
          class="img-femme"
          eager
        />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" xl="5" class="pr-xl-6 pb-5 pb-sm-10">
        <v-row no-gutters>
          <v-col cols="12" sm="" class="flex-grow-0 pb-5 pb-sm-0 text-center">
            <img :src="sectionData.fields.logo_emya" />
          </v-col>
          <v-col cols="12" sm="" class="pb-5 pb-sm-10 pb-xl-0 pl-0 pl-sm-6">
            <kk-dynamic-html :html="sectionData.fields.text_emya" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xl="7" class="pl-xl-6 pb-5 pb-sm-10">
        <v-row no-gutters>
          <v-col cols="12" md="" class="flex-grow-0 pb-5 pb-md-0 text-center">
            <img :src="sectionData.fields.logo_ema" />
          </v-col>
          <v-col cols="12" md="" class="pl-md-6">
            <kk-dynamic-html :html="sectionData.fields.text_ema" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" xl="3" class="pb-5 pr-xl-6">
        <v-row no-gutters>
          <v-col cols="12" md="8" xl="12" class="pb-5 pb-sm-10">
            <kk-dynamic-html :html="sectionData.fields.text_dal" />
          </v-col>
          <v-col cols="12" md="4" xl="12" class="text-center">
            <img :src="sectionData.fields.logo_dal" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xl="9">
        <v-img :src="sectionData.fields.image_dal" eager />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "AboutAwards",
  components: { SectionTitle, KkDynamicHtml },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
.img-femme {
  height: 100%;
}
</style>
