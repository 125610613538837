import Repository from "@/plugins/axios";

export default {
  // Получить список экскурсий
  getTours(params) {
    return Repository.get(`/tours`, { params });
  },

  // Получить страницу экскурсии
  getTour(ident) {
    return Repository.get(`/tours/${ident}`);
  },

  // Получить список экскурсий для школьников
  getPupilTours(params) {
    return Repository.get(`/pupil-tours`, { params });
  },

  // Получить страницу экскурсии для школьников
  getPupilTour(ident) {
    return Repository.get(`/pupil-tours/${ident}`);
  },
};
