<template>
  <div id="virtual-tour"></div>
</template>

<script>
export default {
  props: {
    tourPath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentScript: null,
      noScript: [],
    };
  },
  watch: {
    tourPath(newVal) {
      if (newVal) {
        this.loadTour();
      }
    },
  },
  mounted() {
    this.loadTour();
  },
  beforeDestroy() {
    this.destroyPano();
  },
  methods: {
    createPano() {
      this.destroyPano();

      const vt = document.getElementById("virtual-tour");
      const pano = document.createElement("div");
      pano.setAttribute("id", "pano");
      vt.appendChild(pano);

      /* eslint-disable-next-line no-undef */
      embedpano({
        swf: `${this.tourPath}/tour.swf`,
        xml: `${this.tourPath}/tour.xml`,
        target: "pano",
        html5: "only",
        mobilescale: 1.0,
        passQueryParameters: true,
      });
    },

    destroyPano() {
      const panoTest = document.getElementById("pano");
      if (panoTest) {
        /* eslint-disable-next-line no-undef */
        removepano("pano");
      }
    },

    async loadTour() {
      const newScript = `${this.tourPath}/tour.js`;
      if (this.currentScript !== null) {
        await this.$unloadScript(this.currentScript);
      }

      if (this.noScript.includes(this.tourPath)) {
        this.destroyPano();
        this.currentScript = null;
        return;
      }

      this.$loadScript(newScript)
        .then(() => {
          this.currentScript = newScript;
          this.createPano();
        })
        .catch(() => {
          this.destroyPano();
          this.currentScript = null;
          this.noScript.push(this.tourPath);
        });
    },
  },
};
</script>

<style lang="scss">
#virtual-tour {
  width: 100%;
}
#pano {
  width: 100%;
  height: 250px;
  min-height: 250px;
  z-index: 1;
  @media (min-width: 640px) {
    height: 350px;
    min-height: 350px;
  }
  @media (min-width: 1024px) {
    height: 450px;
    min-height: 450px;
  }
  @media (min-width: 1280px) {
    height: 520px;
    min-height: 520px;
  }
  @media (min-width: 1440px) {
    height: 600px;
    min-height: 600px;
  }
  @media (min-width: 1920px) {
    height: 700px;
    min-height: 700px;
  }
}
</style>
