<template>
  <div>
    <kk-article
      v-if="pageLoaded"
      ref="refarticle"
      :breadcrumbs="breadcrumbsItems"
      :contents="contents"
      class="mt-n4 mt-sm-0"
    >
      <h2 class="mb-3 mb-sm-5">{{ pageData.fields.title }}</h2>

      <div
        v-for="item in pageData.children"
        :id="item.node_name"
        :key="item.node_name"
        class="mt-5 mt-sm-10"
      >
        <kk-dynamic-html :html="html(item)" />
      </div>
    </kk-article>

    <zuruck with-aux-bar />
  </div>
</template>

<script>
import AboutRepository from "@/api/about.js";
import KkArticle from "@/components/Article/KkArticle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: { KkArticle, KkDynamicHtml, Zuruck },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        main_fields: {},
        about_fields: {},
        children: [],
      },
    };
  },
  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.about_fields.title || "",
          to: { name: "About" },
          exact: true,
          disabled: false,
        },
      ];
    },
    contents() {
      return this.pageData.children.reduce((product, item) => {
        product[`p${item.node_name}`] = item.fields.title;
        return product;
      }, {});
    },
    html() {
      return (item) =>
        `<h3 id="p${item.node_name}">${item.fields.title}</h3>${item.fields.article}`;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await AboutRepository.getHistory();
      this.pageData = data;
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
  },
};
</script>
