<template>
  <horizontal-scroller
    :items="blockData.children"
    content-class="mx-n3"
    stick-to="left"
    class="mb-5 mb-sm-10"
  >
    <template v-slot="{ item }">
      <shsi-press-item :item="item.fields" class="mx-3" limited />
    </template>
  </horizontal-scroller>
</template>
<script>
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import ShsiPressItem from "./ShsiPressItem.vue";

export default {
  name: "ShsiPress",
  components: {
    ShsiPressItem,
    HorizontalScroller,
  },
  props: {
    blockData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
