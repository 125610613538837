<template>
  <v-row no-gutters :style="rowStyle">
    <v-col
      v-for="(social, index) in socialData.children"
      :key="index"
      class="flex-grow-0"
      :style="colStyle"
    >
      <masked-icon-link
        :mask-icon="social.fields.icon"
        :title="social.fields.title"
        :href="social.fields.link"
        :height="height"
        :inverted="inverted"
      />
    </v-col>
  </v-row>
</template>
<script>
import MaskedIconLink from "@/components/MaskedIconLink.vue";

export default {
  name: "SocialButtons",
  components: {
    MaskedIconLink,
  },
  props: {
    height: {
      type: [String, Number],
      required: true,
    },
    spaceBetween: {
      type: [String, Number],
      required: true,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    socialData() {
      return this.$store.getters.getSocial;
    },
    halfSb() {
      return Math.floor(parseInt(this.spaceBetween) / 2);
    },
    colStyle() {
      return {
        "padding-top": `${this.halfSb}px`,
        "padding-right": `${this.spaceBetween}px`,
      };
    },
    rowStyle() {
      return {
        "margin-top": `-${this.halfSb}px`,
      };
    },
  },
};
</script>
