<template>
  <div v-if="sectionData" class="subsection">
    <section-title header-id="rules" :title="sectionData.fields.title" />

    <kk-dynamic-html :html="sectionData.fields.article" class="mb-8 mb-sm-16" />
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "VisitorRules",
  components: { SectionTitle, KkDynamicHtml },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
