<template>
  <kk-article
    v-if="pageLoaded"
    ref="refarticle"
    :breadcrumbs="breadcrumbsItems"
    :contents="pageData.library_fields.contents"
    class="mt-n4 mt-sm-0"
  >
    <h2 class="mb-7 mb-sm-8">{{ pageData.library_fields.title_about }}</h2>

    <kk-dynamic-html :html="pageData.library_fields.article" />
  </kk-article>
</template>

<script>
import LibraryRepository from "@/api/library.js";
import KkArticle from "@/components/Article/KkArticle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  components: { KkArticle, KkDynamicHtml },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(
        this.pageData.library_fields.title_about
      ),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        main_fields: {},
        library_fields: {},
      },
    };
  },
  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.library_fields.title || "",
          to: { name: "LibraryBooks" },
          exact: true,
          disabled: false,
        },
      ];
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await LibraryRepository.getAbout();
      this.pageData = data;
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
  },
};
</script>
