<template>
  <div
    v-infinite-scroll="fetchFix"
    infinite-scroll-disabled="disabled"
    infinite-scroll-immediate-check="false"
    :infinite-scroll-distance="distance"
  >
    <div v-if="visible" :id="elementId" class="infinite-loader">
      <div class="infinite-loader__icon">
        <div class="infinite-loader__icon--circle animated" />
        <div class="infinite-loader__icon--cube animated" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfiniteLoader",
  props: {
    fetch: {
      type: Function,
      default: () => () => {},
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    distance: {
      type: Number,
      default: 200,
    },
    elementId: {
      type: String,
      default: "v-infinite-loader",
    },
    direction: {
      type: String,
      default: "",
    },
  },
  methods: {
    isInViewport() {
      const element = document.getElementById(this.elementId);
      if (!element) {
        return false;
      }
      const rect = element.getBoundingClientRect();
      const bottomLimit =
        window.innerHeight || document.documentElement.clientHeight;
      if (this.direction === "right") {
        const rightLimit =
          window.innerWidth || document.documentElement.clientWidth;
        return (
          rect.top >= 100 &&
          rect.bottom <= bottomLimit &&
          rect.right <= rightLimit + 200
        );
      } else {
        return rect.top >= 100 && rect.bottom - 200 <= bottomLimit;
      }
    },
    fetchFix() {
      if (!this.isInViewport()) {
        return false;
      }
      this.fetch();
    },
  },
};
</script>
<style lang="scss" scoped>
.infinite-loader {
  --w-big: 90px;
  --w-small: 30px;
  width: auto;
  height: var(--w-big);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;

  .infinite-loader__icon {
    width: var(--w-big);
    height: var(--w-big);
    position: relative;

    .animated {
      animation-delay: 0s;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }

    .infinite-loader__icon--circle {
      position: absolute;
      top: 0;
      left: 0;
      width: var(--w-big);
      height: var(--w-big);
      border-radius: 50%;
      background-color: var(--v-mira-base);
      z-index: 2;
    }
    .infinite-loader__icon--cube {
      position: absolute;
      top: var(--w-small);
      left: var(--w-small);
      width: var(--w-small);
      height: var(--w-small);
      background-color: var(--v-bgPrimary-base);
      z-index: 3;
      animation-name: animate-rotation;
    }
  }

  &.hovered {
    .infinite-loader__icon--circle {
      background-color: var(--v-mira-base);
    }
    .infinite-loader__icon--cube {
      transform: rotateZ(90deg);
    }
  }
}

@media (max-width: 1023px) {
  .infinite-loader {
    --w-big: 48px;
    --w-small: 16px;
    margin-top: 40px;
  }
}

@keyframes animate-rotation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
</style>
