<template>
  <div>
    <v-row
      no-gutters
      class="flex-nowrap flex-sm-wrap flex-lg-nowrap align-center"
    >
      <v-col class="flex-grow-0 mr-3 icon-col">
        <v-icon color="iconSocial" class="d-block" size="24"
          >$vuetify.icons.Calendar</v-icon
        >
      </v-col>
      <v-col class="flex-grow-0 flex-sm-grow-1 dates my-3">
        <schedule-dates-interval :schedule="settings.schedule" />
      </v-col>
      <v-col
        sm="12"
        lg=""
        class="flex-grow-0 ml-5 ml-sm-0 mb-sm-2 mb-lg-0 order-sm-first order-lg-last text-right"
      >
        <v-icon color="textLight" class="" size="40">{{
          `$vuetify.icons.AgeRating${ageRating}`
        }}</v-icon>
      </v-col>
    </v-row>
    <v-row v-if="times.length > 0" dense class="flex-nowrap align-start">
      <v-col class="flex-grow-0 mr-1 icon-col">
        <v-icon color="iconSocial" class="d-block" size="24"
          >$vuetify.icons.Clock</v-icon
        >
      </v-col>
      <v-col>
        <div v-for="(time, index) in times" :key="index">
          <span v-if="time.time_text">{{ time.time_text }}</span>
          <span v-else-if="time.time_end"
            >{{ time.time_begin }} — {{ time.time_end }}</span
          >
          <span v-else>{{ time.time_begin }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row v-else-if="!schedule.isPast" dense class="flex-nowrap align-start">
      <v-col class="flex-grow-0 mr-1 icon-col">
        <v-icon color="iconSocial" class="d-block" size="24"
          >$vuetify.icons.Clock</v-icon
        >
      </v-col>
      <v-col>{{ ticketWidgetSection.fields.operating_hours }}</v-col>
    </v-row>
    <v-row v-if="!!settings.place" dense class="flex-nowrap align-start mt-3">
      <v-col class="flex-grow-0 mr-1 icon-col">
        <v-icon color="iconSocial" class="d-block" size="24"
          >$vuetify.icons.MapPin</v-icon
        >
      </v-col>
      <v-col>
        <article class="base article-info" v-html="settings.place" />
      </v-col>
    </v-row>
    <v-row v-if="!!settings.addinfo" dense class="flex-nowrap align-start mt-3">
      <v-col class="flex-grow-0 mr-1 icon-col">
        <v-icon color="iconSocial" class="d-block" size="24"
          >$vuetify.icons.InfoOutline</v-icon
        >
      </v-col>
      <v-col>
        <article class="base article-info" v-html="settings.addinfo" />
      </v-col>
    </v-row>
    <v-row
      v-if="!!settings.biennale"
      dense
      class="flex-nowrap align-start mt-3"
    >
      <v-col class="flex-grow-0 mr-1 icon-col">
        <v-icon color="iconSocial" class="d-block" size="24"
          >$vuetify.icons.StarOutline</v-icon
        >
      </v-col>
      <v-col>
        <div class="base">{{ settings.biennale.subtitle }}</div>
        <div class="base">
          <b>«{{ settings.biennale.title }}»</b>
        </div>
        <div class="base">({{ settings.biennale.direction_title }})</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ScheduleDatesInterval from "@/components/ScheduleDatesInterval.vue";

export default {
  name: "KkArticleInfo",
  components: { ScheduleDatesInterval },
  props: {
    settings: {
      type: Object,
      required: true,
    },
    schedule: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ticketWidgetSection() {
      return this.$store.getters.getTicketWidget;
    },
    ageRating() {
      return this.settings.age_rating || "00";
    },
    times() {
      const times = [...(this.settings.schedule.times || [])];
      times.sort((a, b) =>
        (a.time_begin || "").localeCompare(b.time_begin || "")
      );
      return times;
    },
  },
};
</script>
<style scoped lang="scss">
.icon-col {
  padding-top: 6px;
}
.dates {
  white-space: nowrap;
}
@media (min-width: 1024px) {
  .dates {
    white-space: normal;
  }
}
.article-info {
  ::v-deep(> *) {
    margin-bottom: 0;
  }
}
</style>
