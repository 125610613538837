<template>
  <router-link
    :to="{ name: 'NewsItem', params: { ident: payload.node_name } }"
    class="d-block border-top news-list-item"
  >
    <v-row no-gutters class="align-center">
      <v-col
        cols="12"
        sm=""
        class="flex-grow-0 py-3 py-sm-6 align-self-start align-self-sm-center"
      >
        <v-avatar :size="$vuetify.breakpoint.smAndUp ? 100 : 200">
          <image-clipped
            :image="payload.fields.image"
            :fit="$vuetify.breakpoint.smAndUp ? 100 : 200"
          />
        </v-avatar>
      </v-col>
      <v-col
        cols="12"
        sm=""
        class="flex-grow-1 py-3 py-sm-6 pl-3 pl-sm-6 news d-sm-flex align-center"
      >
        <div class="pr-2 pr-sm-6 flex-grow-1 mb-3 mb-sm-0">
          {{ payload.fields.title }}
        </div>
        <div class="flex-grow-0">
          {{ formatDate(payload.fields.date) }}
        </div>
      </v-col>
    </v-row>
  </router-link>
</template>
<script>
import moment from "moment";
import ImageClipped from "./ImageClipped.vue";

export default {
  name: "NewsListItem",
  components: { ImageClipped },
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formatDate() {
      return (date) => moment(date).format("DD.MM.yyyy");
    },
  },
};
</script>
<style lang="scss" scoped>
.news-list-item:last-of-type {
  border-bottom: 1px solid var(--v-line-base);
}
</style>
