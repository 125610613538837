<template>
  <v-dialog v-model="dialog" content-class="small-dialog">
    <v-card class="card">
      <v-row no-gutters>
        <v-col class="text-right">
          <div class="pa-2 mb-1 mr-n2" @click="$emit('input', false)">
            <v-icon class="link" size="40">$vuetify.icons.Close</v-icon>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="text-center">
          <h4>{{ header.fields.subscribe_success_title }}</h4>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-6">
        <div clas="col body1" v-html="header.fields.subscribe_success_text" />
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-btn outlined tile @click="$emit('input', false)"
            >{{ header.fields.subscribe_success_button }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "NewsSubscribeModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    header() {
      return this.$store.getters.getHeader;
    },
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
