import Vue from "vue";
import VueI18n from "vue-i18n";
import appLangMessages from "@/lang";
import pluralizationRules from "./i18n-pluralization";

let messages = {};

// Словарь приложения
for (let [lang, langSection] of Object.entries(appLangMessages)) {
  messages[lang] = {};
  for (let [section, sectionObject] of Object.entries(langSection)) {
    messages[lang][section] = {
      ...sectionObject,
    };
  }
}
Vue.use(VueI18n);
// Создадим объект Vuei18n для использования в приложении
const i18n = new VueI18n({
  locale: "ru",
  fallbackLocale: "ru",
  messages,
  pluralizationRules,
});

export default i18n;
