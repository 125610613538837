const models = () => {
  return ["fontsize", "fontkerning", "darktheme"];
};

export default {
  state: {
    inclusiveData: models().reduce((product, modelName) => {
      product[modelName] = false;
      return product;
    }, {}),
  },
  actions: {
    initInclusive({ commit }) {
      models().forEach((model) => {
        const storeData = localStorage.getItem(`_incl_${model}`) || "0";
        if (storeData == "1") {
          commit("setInclusiveModel", model);
        } else {
          commit("unsetInclusiveModel", model);
        }
      });
    },
    setInclusive({ commit }, model) {
      commit("setInclusiveModel", model);
      localStorage.setItem(`_incl_${model}`, "1");
    },
    unsetInclusive({ commit }, model) {
      commit("unsetInclusiveModel", model);
      localStorage.setItem(`_incl_${model}`, "0");
    },
  },
  getters: {
    getInclusiveModels(state) {
      return state.inclusiveData;
    },
  },
  mutations: {
    setInclusiveModel(state, model) {
      state.inclusiveData[model] = true;
      const className = `inclusive-${model}`;
      const html = document.querySelector("html");
      if (!html.classList.contains(className)) {
        html.classList.add(className);
      }
    },
    unsetInclusiveModel(state, model) {
      state.inclusiveData[model] = false;
      const className = `inclusive-${model}`;
      const html = document.querySelector("html");
      if (html.classList.contains(className)) {
        html.classList.remove(className);
      }
    },
  },
};
