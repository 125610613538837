<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <path
      d="M2.5 2.29688L17 16.7969L31.5 2.29688"
      stroke="currentColor"
      stroke-width="3"
      stroke-linecap="square"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 34,
    },
    height: {
      type: [Number, String],
      default: 19,
    },
  },
};
</script>
