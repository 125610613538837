<template>
  <v-card tile elevation="0" color="bgDark" height="100%" class="footer">
    <v-container class="pa-0" fluid>
      <v-row class="align-stretch mb-5">
        <v-col cols="12" sm="6" class="d-flex flex-column">
          <v-row no-gutters class="flex-grow-0 mb-lg-16">
            <v-col cols="8" sm="10" lg="8" class="footer__org">
              <h4>{{ sectionData.fields.org }}</h4>
            </v-col>
          </v-row>
          <div class="flex-grow-1 d-lg-none" />
          <footer-evaluate-links
            :section-data="sectionData"
            class="d-none d-sm-flex flex-grow-0 mb-14"
          />
          <social-buttons
            height="45"
            space-between="37"
            inverted
            class="d-none d-sm-flex flex-grow-0"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="12" lg="6" class="mb-4 mb-sm-14 pr-lg-10">
              <h4 class="mb-4 mb-sm-6">
                {{ sectionData.fields.title_schedule }}
              </h4>
              <div class="base-2" v-html="sectionData.fields.text_schedule" />
            </v-col>
            <v-col cols="12" lg="6" class="mb-4 mb-sm-0">
              <h4 class="mb-4 mb-sm-6">
                {{ sectionData.fields.title_contacts }}
              </h4>
              <div class="base-2" v-html="sectionData.fields.text_contacts" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <footer-evaluate-links
        :section-data="sectionData"
        class="d-flex d-sm-none mb-6"
      />
      <social-buttons
        height="45"
        space-between="37"
        inverted
        class="d-flex d-sm-none mb-5"
      />

      <footer-partners :items="sectionData.children" class="mb-5" />

      <footer-bottom :section-data="sectionData" />
    </v-container>
  </v-card>
</template>

<script>
import SocialButtons from "@/components/SocialButtons.vue";
import FooterEvaluateLinks from "./FooterEvaluateLinks.vue";
import FooterPartners from "./FooterPartners.vue";
import FooterBottom from "./FooterBottom.vue";

export default {
  components: {
    SocialButtons,
    FooterEvaluateLinks,
    FooterPartners,
    FooterBottom,
  },
  computed: {
    sectionData() {
      return this.$store.getters.getFooter;
    },
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  padding: 64px 40px 40px 40px;
}

@media (max-width: 1023px) {
  .footer {
    padding: 40px 16px;

    .footer__org {
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 639px) {
  .footer__org {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
</style>
