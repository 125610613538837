<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <path
      d="M14.0001 27.3346C6.63608 27.3346 0.666748 21.3653 0.666748 14.0013C0.666748 6.6373 6.63608 0.667969 14.0001 0.667969C21.3641 0.667969 27.3334 6.6373 27.3334 14.0013C27.3334 21.3653 21.3641 27.3346 14.0001 27.3346ZM12.6667 12.668V20.668H15.3334V12.668H12.6667ZM12.6667 7.33464V10.0013H15.3334V7.33464H12.6667Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 28,
    },
    height: {
      type: [Number, String],
      default: 28,
    },
  },
};
</script>
