import Repository from "@/plugins/axios";

export default {
  // Искать на сайте по строке
  search(params) {
    return Repository.get("/search", { params });
  },

  // Подписаться на новости
  subscribe(email) {
    return Repository.post("/mglwnafh", { email });
  },
};
