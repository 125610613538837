<template>
  <div v-if="sectionData" class="section">
    <kk-link
      v-for="menuItem in sectionData.children"
      :key="menuItem.id"
      class="menu-item"
      :link="menuItem.fields.link"
      draggable="false"
    >
      <div class="menu-item__image">
        <v-avatar
          v-if="menuItem.fields.image.i_main"
          :size="$vuetify.breakpoint.smAndUp ? 100 : 40"
          class="mr-2 mr-sm-3 person-avatar"
        >
          <image-clipped
            :image="menuItem.fields.image"
            :fit="$vuetify.breakpoint.smAndUp ? 100 : 40"
          />
        </v-avatar>
        <div class="menu-item__logo-circle">
          <div class="menu-item__logo-square" />
        </div>
      </div>
      <div class="menu-item__title kk-accent ml-3 mb-1 ml-sm-10 mb-sm-3">
        {{ menuItem.fields.title.toLowerCase() }}
      </div>
      <v-spacer />
      <div class="menu-item__arrow">
        <v-icon :size="iconSize" class="arrow-icon"
          >$vuetify.icons.Arrrow</v-icon
        >
      </div>
    </kk-link>
  </div>
</template>
<script>
import KkLink from "@/components/KkLink.vue";
import ImageClipped from "@/components/ImageClipped.vue";
export default {
  name: "MainMenu",
  components: { KkLink, ImageClipped },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    iconSize() {
      return this.$vuetify.breakpoint.smAndUp ? 58 : 24;
    },
  },
};
</script>
<style lang="scss" scoped>
#app.v-application.theme--light .menu-item {
  --transition-duration: 0.3s;

  display: flex;
  align-items: center;
  height: 160px;
  border-top: 1px solid var(--v-line-base);
  box-shadow: inset 0 0px 0 -1px var(--v-bgDark-base);
  transition: box-shadow var(--transition-duration) ease-in-out;

  @media (max-width: 1023px) {
    height: 50px;
  }

  &:last-of-type {
    border-bottom: 1px solid var(--v-line-base);
  }

  .menu-item__image {
    margin-left: 0px;
    transition: margin-left var(--transition-duration) ease-in-out;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;

    @media (max-width: 1023px) {
      width: 40px;
      height: 40px;
    }

    .menu-item__image-content {
      opacity: 1;
      transition: opacity var(--transition-duration) ease-in-out;
    }

    .menu-item__logo-circle {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: var(--v-textOnBgDark-base);
      opacity: 0;
      transition: opacity var(--transition-duration) ease-in-out;

      .menu-item__logo-square {
        position: relative;
        left: 33.33%;
        top: 33.33%;
        width: 33.34%;
        height: 33.34%;
        background-color: var(--v-mira-base);
      }
    }
  }

  .menu-item__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .menu-item__title,
  .menu-item__arrow .arrow-icon {
    color: var(--v-textBase-base) !important;
    transition: color var(--transition-duration) ease-in-out !important;
  }

  .menu-item__arrow {
    margin-right: 0px;
    transition: margin-right var(--transition-duration) ease-in-out !important;
  }

  &:hover {
    box-shadow: inset 0 -160px 0 -1px var(--v-bgDark-base);
    transition: box-shadow var(--transition-duration) ease-in-out;

    .menu-item__image {
      transition: margin-left var(--transition-duration) ease-in-out;
      margin-left: 40px;
      @media (max-width: 1023px) {
        margin-left: 12px;
      }

      .menu-item__image-content {
        opacity: 0;
        transition: opacity var(--transition-duration) ease-in-out;
      }

      .menu-item__logo-circle {
        opacity: 1;
        transition: opacity var(--transition-duration) ease-in-out;
      }
    }

    .menu-item__title,
    .menu-item__arrow .arrow-icon {
      color: var(--v-textOnBgDark-base) !important;
      transition: color var(--transition-duration) ease-in-out !important;
    }

    .menu-item__arrow {
      margin-right: 45px;
      transition: margin-right var(--transition-duration) ease-in-out !important;
      @media (max-width: 1023px) {
        margin-right: 12px;
      }
    }
  }
}
</style>
