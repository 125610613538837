<template>
  <div v-if="pageLoaded" class="section">
    <div v-show="isListRoute">
      <page-title :title="pageData.fields.title" />

      <v-row no-gutters class="mb-5 mb-sm-10">
        <v-col>
          <kk-dynamic-html :html="pageData.fields.annotation" />
        </v-col>
        <v-col class="d-none d-sm-block" lg="3"></v-col>
      </v-row>

      <v-row v-if="pageData.fields.button_more" class="mb-4 mt-0">
        <v-col>
          <v-btn outlined tile :to="{ name: 'LibraryAbout' }">
            {{ pageData.fields.button_more }}
          </v-btn>
        </v-col>
        <v-col cols="12" lg="3"></v-col>
      </v-row>

      <library-tabs id="content-top" :library-fields="pageData.fields" />
    </div>

    <router-view
      :key="libraryTab"
      @page-loaded="$emit('page-loaded')"
    ></router-view>
  </div>
</template>

<script>
import LibraryRepository from "@/api/library.js";
import PageTitle from "@/components/PageTitle.vue";
import LibraryTabs from "./LibraryTabs.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  components: {
    PageTitle,
    LibraryTabs,
    KkDynamicHtml,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
      },
      loading: true,
      scrollTop: {
        LibraryBooks: 0,
        LibrarySets: 0,
      },
    };
  },
  computed: {
    isListRoute() {
      return Object.keys(this.scrollTop).includes(this.routeKind);
    },
    routeKind() {
      return this.$route.meta?.kind || this.$route.name;
    },
    libraryTab() {
      return this.$route.meta?.tab || this.routeKind;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    routeKind(newV, oldV) {
      // + disabled scroll behaviour in router/index.js
      if (Object.keys(this.scrollTop).includes(oldV)) {
        this.scrollTop[oldV] = window.scrollY;
      }
      if (Object.keys(this.scrollTop).includes(newV)) {
        this.$nextTick(() => {
          window.scrollTo(0, this.scrollTop[newV]);
        });
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await LibraryRepository.getLibrary();
      this.$store.commit("setLangsAvailAll");
      this.pageData = data;
      this.pageLoaded = true;
      this.loading = false;
      this.$emit("page-loaded");
    },
  },
};
</script>
