<template>
  <div id="header" class="header" :class="{ 'menu-opened': menuOpened }">
    <div class="header__background">
      <div class="header__scroll-container" :style="headerScrollStyle">
        <router-link :to="{ name: 'Main' }" class="mr-5 mr-sm-10 menu-closure">
          <header-logo :locale="locale" />
        </router-link>

        <header-hours
          :section-data="sectionData"
          class="d-none d-md-block mb-05"
          :class="{ 'header-item-hidden': showSearch }"
        />

        <v-spacer />

        <header-buy-ticket
          :section-data="sectionData"
          class="d-none d-sm-block mr-12 mb-05"
          :class="{ 'header-item-hidden': showSearch }"
        />

        <header-search
          reset-on-blur
          :active="showSearch"
          class="d-none d-sm-block mr-6"
          style="height: 36px"
          @on-search="doSearch"
          @on-activate="showSearch = true"
          @on-blur="showSearch = false"
        />

        <button
          class="kk-button mr-2 mr-sm-4 py-1 px-2 pa-sm-2 mb-1"
          @click="doSwitchInclusive"
        >
          <v-icon class="link">$vuetify.icons.LowVision</v-icon>
        </button>

        <header-lang-selector
          class="d-none d-sm-flex mr-4 mb-05"
          :langs="langs"
          :locale="locale"
          @change-locale="doChangeLocale"
        />

        <button
          class="kk-button py-1 px-2 pa-sm-2 mb-1 mr-n2"
          @click="onMenuToggle"
        >
          <v-icon v-if="menuOpened" class="link">$vuetify.icons.Close</v-icon>
          <v-icon v-else class="link">$vuetify.icons.Burger</v-icon>
        </button>
      </div>
    </div>

    <header-inclusive-modal v-model="inclusiveModal" />

    <header-menu
      :opened="menuOpened"
      :section-data="sectionData"
      :langs="langs"
      :locale="locale"
      :add-padding="scrollWidth"
      @change-locale="doChangeLocale"
      @on-search="doSearch"
    />
  </div>
</template>

<script>
import HeaderLogo from "./HeaderLogo.vue";
import HeaderHours from "./HeaderHours.vue";
import HeaderBuyTicket from "./HeaderBuyTicket.vue";
import HeaderSearch from "./HeaderSearch.vue";
import HeaderLangSelector from "./HeaderLangSelector.vue";
import HeaderMenu from "./HeaderMenu.vue";
import BaseRepository from "@/api/base.js";
import HeaderInclusiveModal from "./HeaderInclusiveModal.vue";

export default {
  name: "Header",
  components: {
    HeaderLogo,
    HeaderHours,
    HeaderBuyTicket,
    HeaderSearch,
    HeaderLangSelector,
    HeaderMenu,
    HeaderInclusiveModal,
  },
  data() {
    return {
      showSearch: false,
      menuOpened: false,
      inclusiveModal: false,
      scrollWidth: 0,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    sectionData() {
      return this.$store.getters.getHeader;
    },
    langs() {
      return this.$store.getters.getAllLangs;
    },
    headerScrollStyle() {
      return { "padding-right": `${this.scrollWidth}px` };
    },
  },
  watch: {
    menuOpened(newV) {
      if (newV) {
        this.scrollWidth = window.innerWidth - document.body.offsetWidth;
        document.querySelector("html").classList.add("popup-header-menu");
        window.addEventListener("keyup", this.onKeyUp);
      } else {
        this.scrollWidth = 0;
        document.querySelector("html").classList.remove("popup-header-menu");
        window.removeEventListener("keyup", this.onKeyUp);
      }
    },
  },
  mounted() {
    const local = this;
    window.addEventListener("click", function (event) {
      if (event.target.classList.contains("menu-closure")) {
        local.menuOpened = false;
      }
    });
  },
  methods: {
    doSearch(searchTerm) {
      this.menuOpened = false;
      this.$store.commit("setAppSearch", searchTerm);
      if (this.$route.name != "Search") {
        this.$nextTick(() => {
          this.$router.push({ name: "Search" });
        });
      }
    },
    async doChangeLocale(locale, langEnabled) {
      if (!langEnabled) return false;
      if (this.$i18n.locale == locale) return false;
      await BaseRepository.setLang(locale);
      this.$store.commit("setLang", locale);
    },
    doSwitchInclusive() {
      this.inclusiveModal = true;
    },
    onMenuToggle() {
      this.menuOpened = !this.menuOpened;
      if (this.menuOpened) {
        this.$emit("menu-open");
      } else {
        this.$emit("menu-close");
      }
    },
    onKeyUp(e) {
      if (e.keyCode == 27) {
        this.menuOpened = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 15;
  height: 79px;
  transition: top 0.15s ease-in-out;

  #app.gosuslugi-widget--active & {
    top: 100px;
    transition: top 0.15s ease-in-out;
  }

  background: var(--v-bgPrimary-base);
  border-bottom: 1px solid var(--v-line-base);

  .header__background {
    position: absolute;
    background: var(--v-bgPrimary-base);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding: 20px 40px;
    .header__scroll-container {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  @media (min-width: 1023px) {
    &.menu-opened {
      border-bottom: 1px solid transparent;
    }
  }
}

.mb-05 {
  margin-bottom: 2px;
}

.header-item-hidden {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
@media (max-width: 1280px) {
  .header {
    #app.gosuslugi-widget--active & {
      top: 170px;
      transition: top 0.15s ease-in-out;
    }
  }
}

@media (max-width: 1023px) {
  .header {
    height: 42px;
    .header__background {
      padding: 0px 16px;
    }

    #app.gosuslugi-widget--active & {
      top: 230px;
      transition: top 0.15s ease-in-out;
    }
  }
}
</style>
