<template>
  <div
    class="kk-aux-menu-bar d-sm-none"
    :class="{
      'kk-aux-menu-bar--hidden': !visible,
      'kk-aux-menu-bar--expanded': expanded,
    }"
  >
    <div class="kk-aux-menu-bar__bg" />
    <slot :toggle="onChildToggle" :expanded="expanded" />
  </div>
</template>

<script>
export default {
  name: "AuxiliaryMenuBar",
  data() {
    return {
      expanded: false,
      visible: true,
      scrollPos: 0,
    };
  },

  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    onChildToggle(state) {
      this.expanded = state;
    },
    onScroll() {
      if (this.expanded) return;
      const delta = window.scrollY - this.scrollPos;
      this.scrollPos = window.scrollY;
      if (delta > 0) {
        this.visible = false;
      } else if (delta < 0) {
        this.visible = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.kk-aux-menu-bar {
  min-width: 320px;
  min-height: 56px;
  width: 100%;
  background-color: var(--v-bgSecondary-base);
  height: auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  opacity: 1;
  transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);

  .kk-aux-menu-bar__bg {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--v-bgSecondary-base);
    border-top: 1px solid var(--v-line-base);
  }

  &.kk-aux-menu-bar--hidden {
    pointer-events: none;
    transform: translateY(100%);
    transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
}

.kk-aux-menu-bar.kk-aux-menu-bar--expanded {
  :deep(.kk-aux-menu:not(.kk-aux-menu--expanded)) {
    display: none !important;
  }
}
</style>
