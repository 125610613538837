import Repository from "@/plugins/axios";

export default {
  // Получить список онлайн-проектов
  getOnlineProjects(params) {
    return Repository.get(`/online-projects`, { params });
  },

  // Получить список подкастов
  getPodcasts(params) {
    return Repository.get(`/podcasts`, { params });
  },

  // Получить список виртуальных туров
  getVirtualTours(params) {
    return Repository.get(`/virtual-tours`, { params });
  },
};
