<template>
  <div>
    <div class="d-flex align-start" :class="{ 'limit-width': !hideDetails }">
      <v-avatar
        v-if="avatar?.i_main"
        :size="$vuetify.breakpoint.smAndUp ? 100 : 50"
        class="mr-2 mr-sm-3 person-avatar"
      >
        <image-clipped
          :image="avatar"
          :fit="$vuetify.breakpoint.smAndUp ? 100 : 50"
        />
      </v-avatar>
      <div
        v-else
        :style="{ 'min-width': $vuetify.breakpoint.smAndUp ? '100px' : '50px' }"
        class="mr-2 mr-sm-3"
      />
      <div>
        <div
          class="sign-for-preview mb-sm-1 mt-sm-3 selectable-text"
          :class="disableWrapName ? 'text-no-wrap' : ''"
        >
          {{ name }}
        </div>
        <div class="selectable-text">{{ role }}</div>
        <div v-if="phone.length > 0">
          <a :href="`tel:${stripPhone(phone)}`" class="longread link">{{
            phone
          }}</a>
        </div>
        <div v-if="email.length > 0">
          <a :href="`mailto:${email}`" class="longread link">{{ email }}</a>
        </div>
      </div>
    </div>
    <div
      v-if="description && !hideDetails"
      class="mt-3 mt-sm-6 limit-width selectable-text"
      v-html="description"
    />
  </div>
</template>

<script>
import ImageClipped from "@/components/ImageClipped.vue";
export default {
  name: "Person",
  components: { ImageClipped },
  props: {
    name: {
      type: String,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    avatar: {
      type: Object,
      default: null,
    },
    phone: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    disableWrapName: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stripPhone() {
      return (phone) => {
        return phone.replace(/\s|-|\(|\)/g, "");
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.person-avatar {
  pointer-events: none;
}
.limit-width {
  min-width: 460px;
  max-width: 460px;
  @media (max-width: 480px) {
    min-width: 300px;
    max-width: 300px;
  }
}
</style>
