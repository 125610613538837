<template>
  <vue-custom-scrollbar :settings="settings" v-bind="$attrs" v-on="$listeners">
    <slot />
  </vue-custom-scrollbar>
</template>

<script>
import VueCustomScrollbar from "vue-custom-scrollbar";
export default {
  name: "KkScrollbar",
  components: { VueCustomScrollbar },
  data() {
    return {
      defaultSettings: {
        suppressScrollX: true,
        minScrollbarLength: 100,
        maxScrollbarLength: 300,
      },
    };
  },
  computed: {
    settings() {
      return {
        ...this.defaultSettings,
        ...this.$attrs.settings,
      };
    },
  },
};
</script>
