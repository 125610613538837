<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M19 21V22H21V21H19ZM16 15V14V15ZM8 15V14V15ZM4 19H3H4ZM3 21V22H5V21H3ZM21 21V19H19V21H21ZM21 19C21 17.6739 20.4732 16.4021 19.5355 15.4645L18.1213 16.8787C18.6839 17.4413 19 18.2044 19 19H21ZM19.5355 15.4645C18.5979 14.5268 17.3261 14 16 14V16C16.7956 16 17.5587 16.3161 18.1213 16.8787L19.5355 15.4645ZM16 14H8V16H16V14ZM8 14C6.67392 14 5.40215 14.5268 4.46447 15.4645L5.87868 16.8787C6.44129 16.3161 7.20435 16 8 16V14ZM4.46447 15.4645C3.52678 16.4021 3 17.6739 3 19H5C5 18.2044 5.31607 17.4413 5.87868 16.8787L4.46447 15.4645ZM3 19V21H5V19H3Z"
        fill="currentColor"
      />
      <path
        d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
