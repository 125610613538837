<template>
  <div class="list-row">
    <v-row>
      <v-col
        v-for="(menuItem, index) in sectionData.children"
        :key="index"
        cols="12"
        sm="3"
      >
        <h4 class="list-title">{{ menuItem.fields.title }}</h4>
        <div
          v-for="(menuSubItem, indexSub) in menuItem.children"
          :key="indexSub"
          class="list-item"
        >
          <kk-link class="menu-closure" :link="menuSubItem.fields.href">
            {{ menuSubItem.fields.title }}
          </kk-link>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import KkLink from "@/components/KkLink.vue";
export default {
  components: { KkLink },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
};
</script>
<style lang="scss" scoped>
.list-row {
  margin-top: 80px;
  padding-bottom: 122px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--v-line-base);
  .list-title {
    margin-bottom: 24px;
  }
  .list-item {
    margin-bottom: 12px;
  }
}
</style>
