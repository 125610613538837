import Collection from "@/pages/Collection/index.vue";
import CollectionAbout from "@/pages/Collection/CollectionAbout";
import CollectionPiece from "@/pages/Collection/CollectionPiece";

export default [
  {
    path: "/collection/about",
    name: "CollectionAbout",
    component: CollectionAbout,
  },
  {
    path: "/collection",
    name: "Collection",
    component: Collection,
    props: true,
    children: [
      {
        path: ":ident",
        name: "CollectionPiece",
        component: CollectionPiece,
        props: true,
        children: [
          {
            path: ":subident",
            name: "CollectionPieceSubItem",
            component: CollectionPiece,
            props: true,
          },
        ],
      },
    ],
  },
];
