<template>
  <div v-if="sectionData" class="subsection">
    <section-title
      header-id="subscriptions"
      :title="sectionData.fields.title"
      style="margin-bottom: 0 !important"
    />

    <div class="grid">
      <div
        v-for="(ticket, ticketIndex) in sectionData.children"
        :key="ticketIndex"
        class="subgrid"
        :class="
          ticketIndex == sectionData.children.length - 1 ? 'subgrid--last' : ''
        "
      >
        <div />
        <div
          class="cell cell-center longread ticket-title d-flex align-center"
          :class="
            ticketCellClass(
              ticketIndex == 0,
              ticketIndex == sectionData.children.length - 1
            )
          "
        >
          <div>{{ ticket.fields.title }}</div>
          <kk-popover :html="ticket.fields.addinfo_title" class="ml-2" />
        </div>
        <div
          class="cell cell-center d-flex flex-column justify-center"
          :class="
            ticketCellClass(
              ticketIndex == 0,
              ticketIndex == sectionData.children.length - 1
            )
          "
        >
          <h4 class="text-no-wrap">{{ ticket.fields.price }}</h4>
        </div>
        <div
          class="cell cell-right d-flex align-center"
          :class="
            ticketCellClass(
              ticketIndex == 0,
              ticketIndex == sectionData.children.length - 1
            )
          "
        >
          <v-btn
            outlined
            block
            tile
            :href="ticket.fields.service_url"
            target="_blank"
          >
            {{ sectionData.fields.button_buy.toLowerCase() }}
          </v-btn>
        </div>
      </div>
    </div>

    <v-row class="mb-6 mb-sm-12">
      <v-col cols="12" md="6">
        <v-img :src="sectionData.fields.image1" class="mb-1 mb-sm-3" eager />
        <kk-dynamic-html :html="sectionData.fields.image1_text" />
      </v-col>
      <v-col cols="12" md="6">
        <v-img :src="sectionData.fields.image2" class="mb-1 mb-sm-3" eager />
        <kk-dynamic-html :html="sectionData.fields.image2_text" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import KkPopover from "@/components/KkPopover.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "VisitorsSubscriptions",
  components: { SectionTitle, KkPopover, KkDynamicHtml },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ticketCellClass() {
      return (first, last) => ({
        "border-top-640": !first,
        "ticket-margin": last,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.grid {
  @media (max-width: 639px) {
    .subgrid {
      margin-top: 8px;
      padding-bottom: 10px;
      &:not(.subgrid--last) {
        border-bottom: 1px solid var(--v-line-base);
      }
      &.subgrid--last {
        margin-bottom: 10px;
      }
    }
  }

  @media (min-width: 640px) {
    display: grid;
    align-items: stretch;
    grid-template-columns: 0 5fr 2fr min-content;
    .subgrid {
      grid-column-start: 1;
      grid-column-end: 5;
      display: contents;
    }
    .ticket-margin {
      margin-bottom: 32px;
    }
    .border-top-640 {
      border-top: 1px solid var(--v-line-base);
    }
  }
  @media (min-width: 1024px) {
    grid-template-columns: 0 auto min-content min-content;
    .ticket-margin {
      margin-bottom: 64px;
    }
  }
  @media (min-width: 1280px) {
    grid-template-columns: 2fr 5fr 2fr min-content;
  }
  @media (min-width: 1440px) {
    grid-template-columns: 3fr 5fr 220px min-content;
  }
  @media (min-width: 1920px) {
    grid-template-columns: 3fr 3fr 220px min-content;
  }

  .cell {
    padding-top: 4px;
    padding-bottom: 10px;
  }
  @media (min-width: 640px) {
    .cell {
      padding-top: 20px;
      padding-bottom: 12px;
      &.cell-center {
        padding-left: 6px;
        padding-right: 6px;
      }
      &.cell-right {
        padding-left: 6px;
      }
    }
  }
  @media (min-width: 1024px) {
    .cell {
      padding-top: 40px;
      padding-bottom: 24px;
      &.cell-center {
        padding-left: 12px;
        padding-right: 12px;
      }
      &.cell-right {
        padding-left: 12px;
      }
    }
  }

  .cell-full {
    grid-column-start: 1;
    grid-column-end: 5;
  }
}

.ticket-title {
  @media (min-width: 1280px) {
    white-space: nowrap;
  }
}
</style>
