<template>
  <div>
    <page-title :title="pageData.fields.title" />

    <v-row class="section align-stretch ff__row flex-sm-nowrap">
      <v-col cols="12" class="d-sm-none">
        <h3 class="mb-5 mb-sm-10">{{ pageData.fields.subtitle }}</h3>
        <kk-dynamic-html v-if="pageData.item" :html="pageData.fields.article" />
      </v-col>

      <v-col
        v-if="pageData.item"
        cols="12"
        sm="7"
        md="8"
        class="ff__img-col d-sm-flex align-stretch justify-stretch order-sm-last"
      >
        <v-img
          :src="pageData.item.fields.image.i_main"
          contain
          position="center top"
        />
      </v-col>

      <v-col
        cols="12"
        sm="5"
        md="4"
        class="d-flex flex-column justify-space-between"
      >
        <div class="ff__titles d-none d-sm-block">
          <h3 class="mb-5 mb-sm-10">{{ pageData.fields.subtitle }}</h3>
          <kk-dynamic-html
            v-if="pageData.item"
            :html="pageData.fields.article"
          />
        </div>

        <div v-if="pageData.item">
          <v-row>
            <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
              (pageData.fields_names.filters_author || "").toLowerCase()
            }}</v-col>
            <v-col class="text-right">{{
              (pageData.item.fields.authors || []).join(", ")
            }}</v-col>
          </v-row>
          <v-row class="border-top">
            <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
              (pageData.fields_names.filters_title || "").toLowerCase()
            }}</v-col>
            <v-col class="text-right">{{ pageData.item.fields.title }}</v-col>
          </v-row>
          <v-row class="border-top">
            <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
              (pageData.fields_names.filters_category || "").toLowerCase()
            }}</v-col>
            <v-col class="text-right">{{
              pageData.item.fields.category
            }}</v-col>
          </v-row>
          <v-row class="border-top">
            <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
              (pageData.fields_names.filters_year || "").toLowerCase()
            }}</v-col>
            <v-col class="text-right">{{ pageData.item.fields.year }}</v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  components: { PageTitle, KkDynamicHtml },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {};
  },
  computed: {
    pageData() {
      return this.$store.getters.getPageNotFound;
    },
  },
  mounted() {
    this.$store.commit("setLangsAvailAll");
    this.$store.commit("incrementPageNotFoundIndex");
    this.$emit("page-loaded");
  },
};
</script>
<style scoped lang="scss">
@media (max-width: 1023px) {
  img {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .ff__titles {
    margin-bottom: 120px;
  }
  .ff__row {
    max-height: 60vw;
    .ff__img-col {
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>
