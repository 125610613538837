<template>
  <div v-if="sectionData">
    <h3 id="map" class="mb-4 mb-sm-8 fix-contents">
      {{ sectionData.fields.title }}
    </h3>
    <kk-dynamic-html
      :html="sectionData.fields.text_before_stops"
      class="mb-5 mb-sm-10"
    />

    <v-row class="mb-8 mb-sm-16">
      <v-col
        v-for="item in sectionData.children"
        :key="item.node_name"
        cols="12"
        sm="6"
      >
        <h4>{{ item.fields.title }}</h4>
        <div class="longread">{{ item.fields.buses }}</div>
      </v-col>
    </v-row>

    <kk-dynamic-html
      :html="sectionData.fields.text_after_stops"
      class="mb-5 mb-sm-10"
    />

    <div class="subsection contacts__map">
      <kk-map :coords="[56.010652, 92.893604]" />
    </div>
  </div>
</template>
<script>
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import KkMap from "@/components/KkMap.vue";

export default {
  name: "ContactsMap",
  components: { KkDynamicHtml, KkMap },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.contacts__map {
  height: 300px;
  @media (min-width: 640px) {
    height: 570px;
  }
  @media (min-width: 1024px) {
    height: 470px;
  }
  @media (min-width: 1280px) {
    height: 550px;
  }
  @media (min-width: 1440px) {
    height: 730px;
  }
  @media (min-width: 1920px) {
    height: 800px;
  }
  @media (min-width: 2200px) {
    height: 1000px;
  }
}
</style>
