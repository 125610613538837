<template>
  <div v-if="hasFilters">
    <div class="clickable mt-1 d-block d-sm-none" @click="clearFilters">
      <span class="current-link">{{ $t("Common.filtersClear") }}</span>
    </div>
    <div class="mx-n2">
      <tag-chip
        v-for="(tag, index) in tags"
        :key="index"
        :input-value="true"
        class="mx-2 my-2"
        @click="tag.callback(tag.text)"
      >
        {{ tag.text }}
        <v-icon color="textOnBgDark" right> $vuetify.icons.Close </v-icon>
      </tag-chip>
    </div>
    <div class="clickable mt-1 d-none d-sm-inline-block" @click="clearFilters">
      <span class="current-link">{{ $t("Common.filtersClear") }}</span>
    </div>
  </div>
</template>

<script>
import TagChip from "@/components/Tags/TagChip.vue";

export default {
  name: "LibraryFiltersLine",
  components: { TagChip },
  props: {
    value: {
      type: Object,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    dic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    filters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    hasFilters() {
      return (
        this.hasYearFilter ||
        this.filters.categories.length > 0 ||
        this.filters.publishers.length > 0 ||
        this.filters.languages.length > 0 ||
        this.filters.authors.length > 0
      );
    },
    hasYearFilter() {
      const { years } = this.filters;
      const limits = this.meta.years;
      return years[0] > limits[0] || years[1] < limits[1];
    },
    tags() {
      const { authors, categories, languages, publishers, years } =
        this.filters;
      return [
        ...authors.map((cat) => ({
          text: cat,
          callback: this.closeAuthorClick,
        })),
        ...publishers.map((cat) => ({
          text: cat,
          callback: this.closePublisherClick,
        })),
        ...(this.hasYearFilter
          ? [{ text: `${years[0]}—${years[1]}`, callback: this.closeYearClick }]
          : []),
        ...languages.map((cat) => ({
          text: cat,
          callback: this.closeLanguageClick,
        })),
        ...categories.map((cat) => ({
          text: cat,
          callback: this.closeCategoryClick,
        })),
      ];
    },
  },
  methods: {
    closeAuthorClick(itemClicked) {
      const setFilters = this.filters;
      setFilters.authors = setFilters.authors.filter(
        (item) => item != itemClicked
      );
      this.$emit("input", setFilters);
      this.$emit("change");
    },
    closePublisherClick(itemClicked) {
      const setFilters = this.filters;
      setFilters.publishers = setFilters.publishers.filter(
        (item) => item != itemClicked
      );
      this.$emit("input", setFilters);
      this.$emit("change");
    },
    closeYearClick() {
      const setFilters = this.filters;
      setFilters.years = this.meta.years;
      this.$emit("input", setFilters);
      this.$emit("change");
    },
    closeLanguageClick(itemClicked) {
      const setFilters = this.filters;
      setFilters.languages = setFilters.languages.filter(
        (item) => item != itemClicked
      );
      this.$emit("input", setFilters);
      this.$emit("change");
    },
    closeCategoryClick(itemClicked) {
      const setFilters = this.filters;
      setFilters.categories = setFilters.categories.filter(
        (item) => item != itemClicked
      );
      this.$emit("input", setFilters);
      this.$emit("change");
    },
    clearFilters() {
      const setFilters = this.filters;
      setFilters.authors = [];
      setFilters.publishers = [];
      setFilters.years = this.meta.years;
      setFilters.languages = [];
      setFilters.categories = [];
      this.$emit("input", setFilters);
      this.$emit("change");
    },
  },
};
</script>
