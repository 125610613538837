<template>
  <v-row class="align-stretch">
    <v-col class="grid-col-1 d-flex flex-column justify-stretch">
      <v-row class="grig-col-1-row align-stretch">
        <v-col class="grid-col-1-small d-flex flex-column justify-stretch">
          <v-row class="align-stretch">
            <v-col>
              <online-project-list-item :payload="withImage(0)" full-height />
            </v-col>
          </v-row>
          <v-row class="align-stretch">
            <v-col>
              <online-project-list-item :payload="noImage(0)" full-height />
            </v-col>
          </v-row>
        </v-col>
        <v-col class="grid-col-1-big">
          <online-project-list-item :payload="withImage(1)" full-height />
        </v-col>
      </v-row>
      <v-row class="grig-col-1-row align-stretch">
        <v-col class="grid-col-1-big">
          <online-project-list-item :payload="withImage(2)" full-height />
        </v-col>
        <v-col class="grid-col-1-small d-flex flex-column justify-stretch">
          <v-row>
            <v-col>
              <online-project-list-item :payload="noImage(1)" full-height />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <online-project-list-item :payload="withImage(3)" full-height />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      class="grid-col-2 d-flex flex-wrap flex-md-nowrap flex-md-column justify-stretch align-stretch"
    >
      <div class="grid-col-2-txt grid-col-2-txt-1 flex-md-grow-0">
        <online-project-list-item :payload="noImage(2)" full-height />
      </div>
      <div
        class="grid-col-2-img flex-md-grow-1 py-md-6 pt-6 order-last order-md-0"
      >
        <online-project-list-item :payload="withImage(4)" full-height />
      </div>
      <div class="grid-col-2-txt grid-col-2-txt-2 flex-md-grow-0">
        <online-project-list-item :payload="noImage(3)" full-height />
      </div>
    </v-col>

    <v-col
      class="grid-col-3 d-flex flex-column flex-sm-row flex-lg-column justify-stretch"
    >
      <div class="flex-grow-1 grid-col-31">
        <online-project-list-item :payload="withImage(5)" full-height />
      </div>
      <div
        class="flex-grow-1 grid-col-32 d-flex flex-column pl-0 pl-sm-6 pl-lg-0"
      >
        <div class="flex-grow-0 pt-6 pt-sm-0 pt-lg-6">
          <online-project-list-item :payload="noImage(4)" full-height />
        </div>
        <div class="flex-grow-1 pt-6">
          <online-project-list-item :payload="withImage(6)" full-height />
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import OnlineProjectListItem from "@/components/OnlineProjectListItem.vue";

export default {
  name: "MainWhatsNewGrid",
  components: { OnlineProjectListItem },
  props: {
    itemsWithImage: {
      type: Array,
      required: true,
    },
    itemsNoImage: {
      type: Array,
      required: true,
    },
  },
  computed: {
    withImage() {
      return (index) => this.itemsWithImage[index] || {};
    },
    noImage() {
      return (index) => this.itemsNoImage[index] || {};
    },
  },
};
</script>
<style scoped lang="scss">
.grid-col-1 {
  width: 42.02%;
  flex: 1 1 42.02%;

  .grid-col-1-row {
    flex-wrap: nowrap;
  }

  .grid-col-1-small {
    width: 41.31%;
    flex: 1 1 41.31%;
  }
  .grid-col-1-big {
    width: 58.69%;
    flex: 1 1 58.69%;
  }

  @media (max-width: 639px) {
    .grid-col-1-small,
    .grid-col-1-big {
      width: 100%;
      flex: 1 1 100%;
    }
    .grid-col-1-row {
      flex-wrap: wrap;
    }
  }
}

.grid-col-2 {
  @media (min-width: 1280px) {
    width: 24.66%;
    flex: 1 1 24.66%;
  }

  @media (max-width: 1279px) {
    width: 100%;
    flex: 1 1 100%;

    .grid-col-2-img {
      width: 100%;
    }
    .grid-col-2-txt {
      width: 50%;
    }
    .grid-col-2-txt-1 {
      padding-right: 12px;
    }
    .grid-col-2-txt-2 {
      padding-left: 12px;
    }
  }

  @media (max-width: 639px) {
    .grid-col-2-txt {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    .grid-col-2-txt-2 {
      padding-top: 24px;
    }
  }
}

.grid-col-3 {
  width: 100%;
  flex: 1 1 100%;
  @media (min-width: 1920px) {
    width: 33.32%;
    flex: 1 1 33.32%;
  }

  .grid-col-31 {
    @media (min-width: 1024px) and (max-width: 1439px) {
      width: 57.98%;
      flex: 1 1 57.98%;
    }
  }

  .grid-col-32 {
    @media (min-width: 1024px) and (max-width: 1439px) {
      width: 42.02%;
      flex: 1 1 42.02%;
    }
  }
}
</style>
