import Vue from "vue";
import Router from "vue-router";
import Store from "@/store";
import { sync as RouterSync } from "vuex-router-sync";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import routes from "./routes.js";

Vue.use(Router);

const sameParentView = [
  { from: "OnlineProjects", to: "OnlineProject" },
  { from: "OnlineProject", to: "OnlineProjects" },
  { from: "CollectionPiece", to: "Collection" },
  { from: "CollectionPiece", to: "CollectionPieceSubItem" },
  { from: "CollectionPieceSubItem", to: "CollectionPiece" },
  { from: "Event", to: "Events" },
  { from: "Event", to: "EventsArchive" },
  { from: "Event", to: "EventsAll" },
  { from: "EventSub", to: "EventsArchive" },
  { from: "EventSub", to: "Events" },
  { from: "EventSub", to: "EventsAll" },
  { from: "EventSub", to: "Event" },
  { from: "Exhibition", to: "Exhibitions" },
  { from: "Exhibition", to: "ExhibitionsArchive" },
  { from: "Exhibition", to: "ExhibitionsFuture" },
  { from: "Exhibition", to: "ExhibitionsAll" },
  { from: "Tour", to: "Tours" },
  { from: "Tour", to: "ToursArchive" },
  { from: "Tours", to: "ToursArchive" },
  { from: "NewsItem", to: "NewsList" },
  { from: "LibraryBook", to: "LibraryBooks" },
  { from: "LibrarySet", to: "LibrarySets" },
  { from: "LibrarySet", to: "LibrarySetsByTag" },
];

const isSameParentView = function (to, from) {
  return (
    (to.meta?.group || "none1") === (from.meta?.group || "none2") ||
    sameParentView.some(
      (entry) => entry.from === from.name && entry.to === to.name
    )
  );
};

// Создание экземпляра Router
const SvRouter = new Router({
  mode: "history",
  base: process.env.VUE_APP_CLI_PUBLIC_PATH || undefined,

  // При переходе скролить к верху страницы или к Хэшу / ID
  scrollBehavior(to) {
    if (to.hash) {
      const element = document.getElementById(to.hash.substring(1));
      if (element) {
        elementScrollIntoView(element, { behavior: "smooth" });
        return {};
      }
    }
  },

  routes,
});

// Функция вызывемая после того, как поход на новый маршрут стал неизбежным
SvRouter.afterEach(async (to, from) => {
  if (
    to.name != "Search" &&
    !isSameParentView(to, from) &&
    to.path != from.path
  ) {
    Store.commit("setStartInternalLoading");
  }
});

RouterSync(Store, SvRouter);
Vue.router = SvRouter;

export default SvRouter;
