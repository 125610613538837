<template>
  <v-list flat subheader class="menu-expansion-list border-top">
    <v-list-group
      v-for="(menuItem, index) in sectionData.children"
      :key="index"
      color="textBase"
    >
      <template v-slot:activator>
        <v-list-item-content>
          <h4>{{ menuItem.fields.title }}</h4>
        </v-list-item-content>
      </template>
      <template v-slot:appendIcon>
        <v-icon class="chevron mx-0">$vuetify.icons.ChevronDown</v-icon>
      </template>
      <v-list-item
        v-for="(menuSubItem, indexSub) in menuItem.children"
        :key="indexSub"
        dense
      >
        <v-list-item-content>
          <v-list-item-title>
            <kk-link class="base menu-closure" :link="menuSubItem.fields.href">
              {{ menuSubItem.fields.title }}
            </kk-link>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
import KkLink from "@/components/KkLink.vue";
export default {
  components: { KkLink },
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
  },
};
</script>
