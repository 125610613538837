import Vue from "vue";
import router from "./../router";
import VueYandexMetrika from "vue-yandex-metrika";

// Yandex metrika
Vue.use(VueYandexMetrika, {
  id: 89808791,
  router,
  env: process.env.NODE_ENV,
});
