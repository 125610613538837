<template>
  <div v-if="pageLoaded">
    <page-title :title="pageData.fields.title" />

    <kk-article
      v-if="pageLoaded"
      ref="refarticle"
      :contents="projectsContents"
      class="subsection"
    >
      <kk-dynamic-html
        v-if="pageData.fields.article"
        :html="pageData.fields.article"
        class="subsection"
      />

      <div
        v-for="(item, index) in pageData.children"
        :key="index"
        class="project-item"
      >
        <div :id="item.node_name" class="fix-contents mb-5 mb-sm-10">
          <v-avatar
            :size="$vuetify.breakpoint.smAndUp ? 288 : 250"
            class="project-avatar"
          >
            <image-clipped
              :image="item.fields.image"
              :fit="$vuetify.breakpoint.smAndUp ? 288 : 250"
            />
          </v-avatar>
        </div>
        <h3 class="mb-3 mb-sm-6">
          {{ item.fields.title }}
        </h3>
        <kk-dynamic-html :html="item.fields.article" />
      </div>
    </kk-article>

    <zuruck with-aux-bar />
  </div>
</template>

<script>
import SimplePagesRepository from "@/api/simple.js";
import PageTitle from "@/components/PageTitle.vue";
import KkArticle from "@/components/Article/KkArticle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ImageClipped from "@/components/ImageClipped.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: { PageTitle, KkArticle, KkDynamicHtml, ImageClipped, Zuruck },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
      },
    };
  },
  computed: {
    projectsContents() {
      return this.pageData.children.reduce((product, item) => {
        product[item.node_name] = item.fields.title;
        return product;
      }, {});
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await SimplePagesRepository.getProjects({});
      this.pageData = data;
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
      });
    },
  },
};
</script>
<style scoped lang="scss">
.project-item {
  margin-bottom: 55px;
  @media (min-width: 1024px) {
    margin-bottom: 110px;
  }
}
</style>
