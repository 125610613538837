<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="white"
      transform="translate(0 1)"
      d="M25.0007 0C11.6607 0 0.833984 10.8267 0.833984 24.1667C0.833984 37.5067 11.6607 48.3333 25.0007 48.3333C38.3407 48.3333 49.1673 37.5067 49.1673 24.1667C49.1673 10.8267 38.3407 0 25.0007 0ZM34.6673 24.1667L20.1673 35.0417V13.2917L34.6673 24.1667Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 50,
    },
    height: {
      type: [Number, String],
      default: 50,
    },
  },
};
</script>
