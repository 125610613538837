<template>
  <div>
    <v-row class="flex-nowrap" :class="dense ? '' : 'section'">
      <v-col class="kk-article" :class="manualH3 ? '' : 'fix-h3'">
        <kk-breadcrumbs
          v-if="hasBreadcrumbs"
          class="no-marker px-0 mb-5 mb-sm-10"
          :items="breadcrumbs"
        />
        <slot></slot>
      </v-col>

      <v-col class="d-none d-sm-block kk-nav">
        <div class="kk-sticky-container" @wheel="onStickyMouseWheel">
          <div class="kk-sticky">
            <kk-ticket-widget
              v-if="!!schedule"
              :settings="tSettings"
              :schedule="schedule"
            />
            <div class="kk-article-contents mt-10">
              <v-row
                v-for="(content, index) in contents"
                :id="`contents-link-${index}`"
                :key="index"
                no-gutters
                class="mt-5"
              >
                <v-col class="pl-0">
                  <div class="kk-chapter-title pl-2">
                    <router-link
                      :to="{ hash: index }"
                      class="base d-block"
                      :class="{
                        'mira--text': isActive(index),
                        'textBase--text': !isActive(index),
                      }"
                    >
                      <div v-html="content" />
                    </router-link>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <auxiliary-menu-bar
      v-if="canNavigate || canBuyTicket || canShowInfo"
      v-slot="scope"
    >
      <auxiliary-menu
        v-if="canNavigate"
        v-slot="scopeChild"
        :title="ticketWidgetSection.fields.menu_navigation"
        icon="$vuetify.icons.Navigation"
        @toggle="scope.toggle"
      >
        <h4 class="mb-2">{{ ticketWidgetSection.fields.menu_navigation }}</h4>
        <div
          v-for="(content, index) in contents"
          :key="index"
          class="clickable py-2"
          @click.stop="
            scopeChild.collapse();
            $router.push(`#${index}`).catch(() => {});
          "
          v-html="content"
        ></div>
      </auxiliary-menu>

      <auxiliary-menu
        v-if="canShowInfo"
        :title="ticketWidgetSection.fields.menu_info"
        icon="$vuetify.icons.InfoOutline"
        @toggle="scope.toggle"
      >
        <kk-article-info
          v-if="!!schedule"
          :settings="tSettings"
          :schedule="schedule"
          class="mb-5 d-sm-none"
        />
      </auxiliary-menu>

      <!-- Внешняя ссылка на покупку -->
      <auxiliary-menu-link
        v-if="!!tSettings.service_url && canBuyTicket"
        :href="tSettings.service_url"
        :title="ticketWidgetSection.fields.menu_tickets"
        icon="$vuetify.icons.Ticket"
      />

      <auxiliary-menu
        v-else-if="!!tSettings.service_id && canBuyTicket"
        :title="ticketWidgetSection.fields.menu_tickets"
        icon="$vuetify.icons.Ticket"
        @toggle="scope.toggle"
      >
        <!-- Виджет с информацией от tonline -->
        <kk-ticket-widget-tonline
          :service-id="tSettings.service_id"
          :schedule="schedule"
        />
      </auxiliary-menu>
    </auxiliary-menu-bar>
  </div>
</template>

<script>
import KkTicketWidget from "./KkTicketWidget.vue";
import KkBreadcrumbs from "./KkBreadcrumbs.vue";
import { Schedule } from "@/components/Utilities.js";
import AuxiliaryMenuBar from "@/components/AuxiliaryMenu/AuxiliaryMenuBar.vue";
import AuxiliaryMenu from "@/components/AuxiliaryMenu/AuxiliaryMenu.vue";
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import KkArticleInfo from "./KkArticleInfo.vue";
import AuxiliaryMenuLink from "@/components/AuxiliaryMenu/AuxiliaryMenuLink.vue";

export default {
  name: "KkArticle",
  components: {
    KkBreadcrumbs,
    KkTicketWidget,
    AuxiliaryMenuBar,
    AuxiliaryMenuLink,
    AuxiliaryMenu,
    KkArticleInfo,
  },
  props: {
    contents: {
      type: Object,
      default: () => ({}),
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    ticketSettings: {
      type: Object,
      default: () => ({}),
    },
    dense: {
      type: Boolean,
      default: false,
    },
    manualH3: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      observerOptions: {
        rootMargin: "0px 0px -50% 0px",
        threshold: 1,
      },
      observer: null,
      activeId: "",
    };
  },
  computed: {
    ticketWidgetSection() {
      return this.$store.getters.getTicketWidget;
    },
    headerData() {
      return this.$store.getters.getHeader;
    },
    schedule() {
      if (this.tSettings) {
        if (this.tSettings.service_id) {
          const service = this.$store.getters.getService(
            this.tSettings.service_id
          );
          if (service) {
            return Schedule(service, this.headerData.fields.closed);
          }
        }
        if (this.tSettings.schedule) {
          return Schedule(
            this.tSettings.schedule,
            this.headerData.fields.closed
          );
        }
      }
      return null;
    },
    canNavigate() {
      return Object.keys(this.contents).length > 0;
    },
    tSettings() {
      return this.ticketSettings || {};
    },
    canBuyTicket() {
      return this.tSettings.service_url || this.tSettings.service_id;
    },
    canShowInfo() {
      return (
        !!this.tSettings.age_rating ||
        (!!this.tSettings.schedule && !!this.tSettings.schedule.dates) ||
        (!!this.tSettings.schedule && !!this.tSettings.schedule.dt_begin) ||
        (!!this.tSettings.schedule && !!this.tSettings.schedule.dt_end) ||
        (!!this.tSettings.schedule && !!this.tSettings.schedule.ts_begin) ||
        (!!this.tSettings.schedule && !!this.tSettings.schedule.ts_end) ||
        !!this.tSettings.addinfo ||
        !!this.tSettings.place
      );
    },
    isActive() {
      return (id) => id == this.activeId;
    },
    hasBreadcrumbs() {
      return this.breadcrumbs.length > 0;
    },
    contentKeys() {
      return Object.keys(this.contents);
    },
    hasContents() {
      return this.contentKeys.length > 0;
    },
  },
  methods: {
    initObserver() {
      const local = this;

      this.observer = new IntersectionObserver((entries) => {
        entries.some((entry) => {
          const targetId = entry.target.getAttribute("id");
          // entry уже внутри, значит активен именно он
          if (entry.isIntersecting) {
            local.activeId = targetId;
            return true;
            // entry на нижней границе, значит активен предыдущий
          } else if (
            entry.boundingClientRect &&
            entry.rootBounds &&
            entry.boundingClientRect.bottom > entry.rootBounds.bottom
          ) {
            const targetIndex = this.contentKeys.indexOf(targetId);
            local.activeId =
              targetIndex > 0 ? this.contentKeys[targetIndex - 1] : "";
            return true;
          }
          return false;
        });
      }, this.observerOptions);

      this.contentKeys.forEach((key) => {
        const target = document.querySelector("#" + key);
        if (target) {
          local.observer.observe(target);
        }
      });

      if (this.$route.hash) {
        const element = document.getElementById(this.$route.hash.substring(1));
        if (element) {
          elementScrollIntoView(element, { behavior: "instant" });
        } else {
          console.log(`element ${this.$route.hash} not found!`);
        }
      }
    },

    onStickyMouseWheel(event) {
      const target = document.querySelector(".kk-sticky");
      if (target) {
        target.scrollTop += event.deltaY;
        const scrolledToTop = target.scrollTop <= 0;
        const scrolledToBottom =
          target.scrollTop + target.clientHeight >= target.scrollHeight;
        if (
          (event.deltaY < 0 && !scrolledToTop) ||
          (event.deltaY > 0 && !scrolledToBottom)
        ) {
          event.preventDefault();
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.kk-chapter-title {
  transition: all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);

  &.kk-menu-active a {
    color: var(--v-mira-base);
  }
}
</style>
