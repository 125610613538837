import Shsi from "@/pages/Shsi/index.vue";
import ShsiPressPage from "@/pages/Shsi/ShsiPressPage.vue";

export default [
  {
    path: "/school",
    name: "Shsi",
    component: Shsi,
  },
  {
    path: "/school/press",
    name: "ShsiPressPage",
    component: ShsiPressPage,
  },
];
