const requireLang = require.context("../lang", true, /\.json$/);

const messages = {};

for (const file of requireLang.keys()) {
  if (file === "./index.js") continue;

  const path = file.replace(/(\.\/|\.json$)/g, "").split("/");

  path.reduce((product, s, i) => {
    if (product[s]) return product[s];

    product[s] = i + 1 === path.length ? requireLang(file) : {};

    return product[s];
  }, messages);
}

export default messages;
