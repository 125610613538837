<template>
  <div class="scroller-end-link__cover">
    <div
      class="scroller-end-link__hover"
      @mouseover="hovered = true"
      @mouseleave="hovered = false"
    >
      <router-link
        :to="browseLink"
        class="scroller-end-link kk-hoverable"
        draggable="false"
      >
        <kk-arrow class="arrow" :class="hovered ? 'hover' : ''" />
        <div class="button px-4 text-no-wrap">
          <span>{{ browseTitle }}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import KkArrow from "@/components/KkArrow.vue";
export default {
  name: "HorizontalScrollerEndLinkBook",
  components: { KkArrow },
  props: {
    browseTitle: {
      type: String,
      required: true,
    },
    browseLink: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hovered: false,
    };
  },
};
</script>
<style scoped lang="scss">
.scroller-end-link__cover {
  padding-right: 36px;
  overflow-x: visible;
  margin-right: 12px;
  margin-left: 12px;

  .scroller-end-link {
    min-width: 227px;
    height: 404px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
      height: 302px;
    }

    html.inclusive-fontsize &,
    html.inclusive-fontkerning & {
      min-width: 320px;
    }

    html.inclusive-fontsize.inclusive-fontkerning & {
      min-width: 360px;
    }

    .arrow {
      margin-top: 40px;

      @media (max-width: 1024px) {
        margin-top: 48px;
      }
      @media (max-width: 640px) {
        margin-top: 32px;
      }
    }
  }
}
</style>
