<template>
  <div v-if="pageLoaded" class="section">
    <image-header
      ref="refimageheader"
      :image="pageData.fields.image"
      :video="pageData.fields.video"
    />

    <kk-article
      ref="refarticle"
      :breadcrumbs="breadcrumbsItems"
      :contents="pageData.fields.contents"
      :ticket-settings="pageData.fields"
      :dense="hasPersons"
    >
      <h2>{{ pageData.fields.title }}</h2>
      <kk-dynamic-html :html="allHtml" />
      <tag-list :tags="pageData.fields.tags" :tag-route="tagRoute" />
    </kk-article>

    <v-row v-if="hasPersons" no-gutters class="mt-4 mt-sm-8">
      <v-col class="section">
        <persons-list
          :persons="pageData.children"
          hide-details
          disable-wrap-name
        />
      </v-col>
    </v-row>

    <section-title
      v-if="pageData.library_set"
      :title="pageData.library_set.fields.activity_title"
      :browse-title="pageData.library_set.fields.activity_browse"
      :browse-link="{
        name: 'LibrarySet',
        params: { ident: pageData.library_set.set_node_name },
      }"
    />

    <horizontal-scroller
      v-if="pageData.library_set"
      :items="pageData.library_set.children"
      content-class="mx-n3"
      class="subsection"
    >
      <template v-slot:append>
        <horizontal-scroller-end-link-book
          :browse-title="pageData.library_set.fields.activity_browse_link"
          :browse-link="{
            name: 'LibrarySet',
            params: { ident: pageData.library_set.set_node_name },
          }"
        />
      </template>
      <template v-slot="{ item }">
        <library-book-preview
          :book="item"
          :width="$vuetify.breakpoint.smAndUp ? 286 : 214"
          in-set-list
          class="mx-3"
        />
      </template>
    </horizontal-scroller>

    <section-title
      :title="pageData.exhibitions_fields.title_other"
      :browse-title="pageData.exhibitions_fields.button_all"
      :browse-link="{ path: '/exhibitions' }"
    />

    <horizontal-scroller :items="pageData.more" content-class="mx-n3">
      <template v-slot:append>
        <horizontal-scroller-end-link
          :browse-title="pageData.exhibitions_fields.button_all"
          :browse-link="{ path: '/exhibitions' }"
        />
      </template>
      <template v-slot="{ item }">
        <preview
          :payload="item"
          :to="{ name: 'Exhibition', params: { ident: item.node_name } }"
          class="mx-3"
          :tag-route="tagRouteInList"
        />
      </template>
    </horizontal-scroller>
  </div>
</template>

<script>
import ExhibitionsRepository from "@/api/exhibitions.js";
import KkArticle from "@/components/Article/KkArticle.vue";
import ImageHeader from "@/components/ImageHeader.vue";
import TagList from "@/components/Tags/TagList.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import Preview from "@/components/Preview/Preview.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import PersonsList from "@/components/PersonsList.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import HorizontalScrollerEndLink from "@/components/HorizontalScrollerEndLink.vue";
import HorizontalScrollerEndLinkBook from "@/components/HorizontalScrollerEndLinkBook.vue";
import LibraryBookPreview from "./Library/LibraryBookPreview.vue";

export default {
  components: {
    KkArticle,
    ImageHeader,
    TagList,
    Preview,
    SectionTitle,
    KkDynamicHtml,
    PersonsList,
    HorizontalScroller,
    HorizontalScrollerEndLink,
    HorizontalScrollerEndLinkBook,
    LibraryBookPreview,
  },
  props: {
    ident: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
        main_fields: {},
        exhibitions_fields: {},
        more: [],
        kind: "actual",
      },
    };
  },
  computed: {
    allHtml() {
      return `${this.pageData.exhibitions_fields.all_child_text}${this.pageData.fields.article}`;
    },
    breadcrumbsItems() {
      const { kind } = this.pageData;
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.exhibitions_fields.title || "",
          to: { path: "/exhibitions" },
          exact: true,
          disabled: false,
        },
        ...(kind != "actual"
          ? [
              {
                text: this.pageData.exhibitions_fields[`section_${kind}`] || "",
                to: { path: `/exhibitions/${kind}` },
                exact: true,
                disabled: false,
              },
            ]
          : []),
      ];
    },
    hasPersons() {
      return this.pageData.children && this.pageData.children.length > 0;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await ExhibitionsRepository.getExhibition(this.ident);
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
        this.$refs.refimageheader.initObserver();
      });
    },
    getRouteName() {
      switch (this.pageData.kind) {
        case "archive":
          return "ExhibitionsArchiveByTag";
        case "future":
          return "ExhibitionsFutureByTag";
        case "all":
          return "ExhibitionsAllByTag";
        default:
          return "ExhibitionsByTag";
      }
    },
    tagRoute(tag) {
      return { name: this.getRouteName(), params: { tag: tag.id } };
    },
    tagRouteInList(tag) {
      return { name: "ExhibitionsByTag", params: { tag: tag.id } };
    },
  },
};
</script>
