<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M20.4004 40C9.40039 40 0.400391 31 0.400391 20C0.400391 9 9.40039 0 20.4004 0C31.4004 0 40.4004 9 40.4004 20C40.4004 31.1 31.4004 40 20.4004 40ZM20.4004 0.799999C9.80039 0.799999 1.20038 9.4 1.20038 20C1.20038 30.6 9.80039 39.2 20.4004 39.2C31.0004 39.2 39.6004 30.6 39.6004 20C39.6004 9.5 31.0004 0.799999 20.4004 0.799999Z"
        fill="currentColor"
      />
      <path
        d="M10.1006 13.0016H7.10059L8.20059 11.1016H12.2006V29.1016H10.2006V13.0016H10.1006Z"
        fill="currentColor"
      />
      <path
        d="M26.4004 24.1984C26.4004 26.9984 24.6004 29.4984 20.9004 29.4984C17.2004 29.4984 15.4004 26.9984 15.4004 24.1984C15.4004 22.2984 16.3004 20.5984 18.1004 19.7984C16.7004 18.9984 15.9004 17.4984 15.9004 15.8984C15.9004 13.2984 17.6004 10.8984 20.9004 10.8984C24.1004 10.8984 25.9004 13.2984 25.9004 15.8984C25.9004 17.5984 25.1004 18.9984 23.7004 19.7984C25.5004 20.5984 26.4004 22.1984 26.4004 24.1984ZM24.4004 24.0984C24.4004 22.0984 22.9004 20.6984 20.9004 20.6984C18.8004 20.6984 17.4004 22.0984 17.4004 24.0984C17.4004 26.1984 18.8004 27.5984 20.9004 27.5984C23.0004 27.5984 24.4004 26.1984 24.4004 24.0984ZM24.0004 15.7984C24.0004 13.7984 22.6004 12.5984 20.9004 12.5984C19.3004 12.5984 17.8004 13.7984 17.8004 15.7984C17.8004 17.5984 19.1004 18.8984 20.9004 18.8984C22.7004 18.8984 24.0004 17.5984 24.0004 15.7984Z"
        fill="currentColor"
      />
      <path
        d="M28.4004 19.2016H33.3004V14.6016H35.2004V19.2016H40.1004V21.0016H35.2004V25.6016H33.3004V21.0016H28.4004V19.2016Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 40,
    },
    height: {
      type: [Number, String],
      default: 40,
    },
  },
};
</script>
