<template>
  <div class="kkg-notification">
    <div class="kkg-close clickable" @click="$emit('close', false)">
      <v-icon>$vuetify.icons.Close</v-icon>
    </div>
    <kk-dynamic-html :html="notificationData" />
  </div>
</template>

<script>
import KkDynamicHtml from "./Article/KkDynamicHtml.vue";
export default {
  name: "KkgNotification",
  components: { KkDynamicHtml },
  props: {
    notificationData: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.kkg-notification {
  position: fixed;
  left: 16px;
  right: 16px;
  bottom: 0;
  height: auto;
  padding: 20px 70px 20px 20px;
  background-color: var(--v-bgSecondary-base);
  z-index: 270;
  box-shadow: 0px 13px 28px rgba(237, 34, 36, 0.25),
    0px 1px 30px rgba(237, 34, 36, 0.6);

  .kkg-close {
    position: absolute;
    right: 30px;
    top: 30px;
  }

  @media (min-width: 1024px) {
    padding: 40px 70px 40px 40px;
    left: 40px;
    right: 40px;
  }
}
</style>
