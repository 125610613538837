import PupilTours from "@/pages/ToursPupil";
import PupilTour from "@/pages/TourPupil";

export default [
  {
    path: "/pupil-tours",
    component: PupilTours,
    props: true,
    children: [
      {
        path: "tag/:tag",
        name: "PupilToursByTag",
        props: true,
        meta: {
          kind: "PupilTours",
          group: "PupilTours",
        },
      },
      {
        path: "",
        name: "PupilTours",
        meta: {
          kind: "PupilTours",
          group: "PupilTours",
        },
      },
      {
        path: "archive/tag/:tag",
        name: "PupilToursArchiveByTag",
        props: true,
        meta: {
          kind: "PupilToursArchive",
          group: "PupilTours",
        },
      },
      {
        path: "archive",
        name: "PupilToursArchive",
        meta: {
          kind: "PupilToursArchive",
          group: "PupilTours",
        },
      },
      {
        path: "all/tag/:tag",
        name: "PupilToursAllByTag",
        props: true,
        meta: {
          kind: "PupilToursAll",
          group: "PupilTours",
        },
      },
      {
        path: "all",
        name: "PupilToursAll",
        meta: {
          kind: "PupilToursAll",
          group: "PupilTours",
        },
      },
      {
        path: ":ident",
        name: "PupilTour",
        component: PupilTour,
        props: true,
      },
    ],
  },
];
