<template>
  <div
    class="d-sm-flex flex-wrap flex-md-nowrap align-center justify-space-between mb-5 mb-sm-10"
  >
    <div class="flex-grow-1 flex-shrink-3 pb-3 pb-sm-2 pr-1 pr-sm-3">
      <h3 v-if="headerId.length > 0" :id="headerId" class="fix-contents">
        {{ title }}
      </h3>
      <h3 v-else>{{ title }}</h3>
    </div>
    <div v-if="browseTitle" class="flex-grow-0 text-no-wrap pl-1 pl-sm-3">
      <browse-button :browse-title="browseTitle" :browse-link="browseLink" />
    </div>
  </div>
</template>

<script>
import BrowseButton from "./BrowseButton.vue";
export default {
  name: "PageTitle",
  components: { BrowseButton },
  props: {
    title: {
      type: String,
      default: "",
    },
    browseTitle: {
      type: String,
      default: "",
    },
    browseLink: {
      type: Object,
      default: () => {},
    },
    headerId: {
      type: String,
      default: "",
    },
  },
};
</script>
