<template>
  <div v-if="pageData" class="section">
    <h2 class="subsection">{{ pageData.fields.title }}</h2>

    <v-row>
      <v-col
        v-for="(pressItem, index) in pageData.children"
        :key="index"
        cols="12"
        sm="6"
        lg="4"
        xl="3"
      >
        <shsi-press-item :item="pressItem.fields" />
      </v-col>
    </v-row>

    <zuruck with-aux-bar />
  </div>
</template>
<script>
import ShsiRepository from "@/api/shsi.js";
import Zuruck from "@/components/Zuruck.vue";
import ShsiPressItem from "./ShsiPressItem.vue";

export default {
  components: {
    ShsiPressItem,
    Zuruck,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
      },
    };
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await ShsiRepository.getShsiPress();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
  },
};
</script>
