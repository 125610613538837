<template>
  <div v-if="pageLoaded">
    <image-header ref="refimageheader" :image="pageData.fields.image" />

    <kk-article
      v-if="pageLoaded"
      ref="refarticle"
      :contents="aboutContents"
      dense
      manual-h3
      class="subsection"
    >
      <page-title
        id="about"
        :title="pageData.fields.title"
        class="fix-contents"
      />

      <v-row no-gutters>
        <v-col class="subsection">
          <kk-dynamic-html :html="pageData.fields.article" />
        </v-col>
      </v-row>

      <about-contacts :section-data="pageData.about_contacts_section" />

      <about-building :section-data="pageData.about_building_section" />

      <about-halls :section-data="pageData.about_halls_section" />

      <about-names :section-data="pageData.about_names_section" />

      <about-logo :section-data="pageData.about_logo_section" />

      <about-awards :section-data="pageData.about_awards_section" />

      <about-exhibitions :section-data="pageData.about_exhibitions_section" />

      <about-collection :section-data="pageData.about_collection_section" />

      <about-public-art :section-data="pageData.about_public_art_section" />
    </kk-article>

    <about-projects
      :section-data="pageData.about_projects_section"
      class="section"
    />

    <zuruck with-aux-bar />
  </div>
</template>

<script>
import AboutRepository from "@/api/about.js";
import KkArticle from "@/components/Article/KkArticle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ImageHeader from "@/components/ImageHeader.vue";
import PageTitle from "@/components/PageTitle.vue";
import AboutContacts from "./AboutContacts.vue";
import AboutBuilding from "./AboutBuilding.vue";
import AboutHalls from "./AboutHalls.vue";
import AboutNames from "./AboutNames.vue";
import AboutLogo from "./AboutLogo.vue";
import AboutAwards from "./AboutAwards.vue";
import AboutExhibitions from "./AboutExhibitions.vue";
import AboutCollection from "./AboutCollection.vue";
import AboutPublicArt from "./AboutPublicArt.vue";
import AboutProjects from "./AboutProjects.vue";
import Zuruck from "@/components/Zuruck.vue";

const pageSections = () => ({
  contacts: "about_contacts_section",
  building: "about_building_section",
  halls: "about_halls_section",
  names: "about_names_section",
  logo: "about_logo_section",
  awards: "about_awards_section",
  exhibitions: "about_exhibitions_section",
  collection: "about_collection_section",
  "public-art": "about_public_art_section",
  projects: "about_projects_section",
});

export default {
  components: {
    KkArticle,
    KkDynamicHtml,
    ImageHeader,
    PageTitle,
    AboutContacts,
    AboutBuilding,
    AboutHalls,
    AboutNames,
    AboutLogo,
    AboutAwards,
    AboutExhibitions,
    AboutCollection,
    AboutPublicArt,
    AboutProjects,
    Zuruck,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        about_contacts_section: { fields: {} },
        about_building_section: { fields: {} },
        about_halls_section: { fields: {}, children: [] },
        about_names_section: { fields: {} },
        about_logo_section: { fields: {} },
        about_awards_section: { fields: {} },
        about_exhibitions_section: { fields: {} },
        about_collection_section: {
          fields: {},
          links: { collection_piece: [] },
        },
        about_public_art_section: { fields: {} },
        about_projects_section: { fields: {} },
      },
    };
  },
  computed: {
    aboutContents() {
      return Object.entries(pageSections()).reduce(
        (product, entry) => {
          if (
            this.pageData[entry[1]] !== undefined &&
            this.pageData[entry[1]] !== null
          ) {
            product[entry[0]] = this.pageData[entry[1]].fields.title;
          }
          return product;
        },
        {
          about: this.pageData.fields.title,
        }
      );
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await AboutRepository.getAbout();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
        this.$refs.refimageheader.initObserver();
      });
    },
  },
};
</script>
