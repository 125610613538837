<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <rect
        width="2"
        height="2"
        transform="matrix(0 -1 -1 0 6 7)"
        fill="currentColor"
      />
      <rect
        width="2"
        height="2"
        transform="matrix(0 -1 -1 0 6 13)"
        fill="currentColor"
      />
      <rect
        width="2"
        height="2"
        transform="matrix(0 -1 -1 0 6 19)"
        fill="currentColor"
      />
      <rect
        width="2"
        height="12"
        transform="matrix(0 -1 -1 0 20 7)"
        fill="currentColor"
      />
      <rect
        width="2"
        height="12"
        transform="matrix(0 -1 -1 0 20 13)"
        fill="currentColor"
      />
      <rect
        width="2"
        height="12"
        transform="matrix(0 -1 -1 0 20 19)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
