<template>
  <div>
    <section-title
      v-if="pageData.exhibitions_section.children.length > 0"
      :title="pageData.exhibitions_section.fields.title"
    />

    <horizontal-scroller
      v-if="pageData.exhibitions_section.children.length > 0"
      :items="pageData.exhibitions_section.children"
      class="subsection"
      content-class="mx-n3"
    >
      <template v-slot:append>
        <horizontal-scroller-end-link
          :browse-title="
            pageData.exhibitions_section.fields.button_all_refwidget
          "
          :browse-link="{ path: '/exhibitions' }"
        />
      </template>
      <template v-slot="{ item }">
        <preview
          :payload="item"
          :to="{ name: 'Exhibition', params: { ident: item.node_name } }"
          class="mx-3"
          disabled-tags
        />
      </template>
    </horizontal-scroller>

    <section-title
      v-if="pageData.events_section.children.length > 0"
      :title="pageData.events_section.fields.title"
    />

    <horizontal-scroller
      v-if="pageData.events_section.children.length > 0"
      :items="pageData.events_section.children"
      class="subsection"
      content-class="mx-n3"
    >
      <template v-slot:append>
        <horizontal-scroller-end-link
          :browse-title="pageData.events_section.fields.button_all_refwidget"
          :browse-link="{ path: '/events' }"
        />
      </template>
      <template v-slot="{ item }">
        <preview
          :payload="item"
          :to="{ name: 'Event', params: { ident: item.node_name } }"
          inner-info
          class="mx-3 pb-6"
          disabled-tags
        />
      </template>
    </horizontal-scroller>
  </div>
</template>

<script>
import Preview from "@/components/Preview/Preview.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import HorizontalScrollerEndLink from "@/components/HorizontalScrollerEndLink.vue";

export default {
  name: "ReferenceWidget",
  components: {
    Preview,
    SectionTitle,
    HorizontalScroller,
    HorizontalScrollerEndLink,
  },
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
};
</script>
