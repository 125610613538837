<template>
  <v-row v-if="items.length > 0">
    <v-col v-for="col in colCount" :key="col" :cols="colWidth">
      <div
        v-for="piece in childrenOrganized[col - 1]"
        :key="piece.node_name"
        class="py-3"
      >
        <kk-link :link="`${baseUrl}/${piece.node_name}`" class="d-block">
          <img
            :src="piece.fields.image.i_size_500 || piece.fields.image.i_main"
            width="100%"
            class="mb-2"
            alt=""
          />
          <div v-if="piece.fields.year?.length > 0" class="textBase--text mb-1">
            {{ piece.fields.title }}. {{ piece.fields.year }}
          </div>
          <div v-else class="textBase--text mb-1">
            {{ piece.fields.title }}
          </div>
          <div
            v-if="piece.fields.authors?.length > 0"
            class="linksOnBgDark--text mb-2"
          >
            {{ piece.fields.authors.join(", ") }}
          </div>
        </kk-link>
      </div>
    </v-col>
  </v-row>
  <v-row v-else-if="notFoundText.length > 0">
    <v-col>
      <kk-dynamic-html :html="notFoundText" />
    </v-col>
  </v-row>
</template>

<script>
import KkLink from "@/components/KkLink.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  components: { KkLink, KkDynamicHtml },
  props: {
    items: {
      type: Array,
      required: true,
    },
    baseUrl: {
      type: String,
      default: ".",
    },
    filtersVisible: {
      type: Boolean,
      default: false,
    },
    viewType: {
      type: Number,
      default: 0,
    },
    notFoundText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      heightFix: 0.2,
    };
  },
  computed: {
    colCount() {
      const cnt = this.colCountWithoutFilters;
      if (cnt == 1) return 1;
      return this.filtersVisible ? cnt - 1 : cnt;
    },
    colCountWithoutFilters() {
      if (this.viewType == 1) return 1;
      if (this.$vuetify.breakpoint.xlOnly) return 4;
      if (this.$vuetify.breakpoint.lgAndUp) return 3;
      return 2;
    },
    colWidth() {
      return Math.floor(12 / this.colCount);
    },
    childrenOrganized() {
      const cols = new Array(this.colCount).fill(null).map(() => new Array());
      const lengths = new Array(this.colCount).fill(0);
      this.items.forEach((item) => {
        const ar = item.fields.image.i_aspect_ratio || 1;
        const ap = 1 / ar + this.heightFix;
        const minLength = Math.min(...lengths);
        const colIndex = lengths.indexOf(minLength);
        lengths[colIndex] += ap;
        cols[colIndex].push({ ...item });
      });
      return cols;
    },
  },
};
</script>
