<template>
  <div class="kk-arrow" :style="btnStyle" v-on="$listeners">
    <div class="kk-arrow-curtain" />
    <v-icon :size="$vuetify.breakpoint.smAndUp ? 32 : 22" color="textOnBgDark"
      >$vuetify.icons.Arrrow</v-icon
    >
  </div>
</template>
<script>
export default {
  name: "KkLink",
  props: {
    rotate: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    btnStyle() {
      return {
        transform: `rotateZ(${this.rotate}deg)`,
      };
    },
  },
};
</script>
<style scoped lang="scss">
.kk-arrow {
  width: 36px;
  height: 36px;
  background-color: #242424;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .kk-arrow-curtain {
    position: absolute;
    left: -39px;
    top: -1;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: var(--v-mira-base);
    transition: left 0.3s ease-in-out;
  }

  &:hover,
  &.hover {
    .kk-arrow-curtain {
      left: -1px;
      transition: left 0.3s ease-in-out;
    }
  }
}

@media (min-width: 1024px) {
  .kk-arrow {
    width: 52px;
    height: 52px;

    .kk-arrow-curtain {
      left: -55px;
      top: -1;
      width: 54px;
      height: 54px;
    }
  }
}
</style>
