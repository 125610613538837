<template>
  <div class="preloader-internal">
    <infinite-loader disabled />
  </div>
</template>

<script>
import InfiniteLoader from "./InfiniteLoader.vue";
export default {
  name: "PreloaderInternal",
  components: { InfiniteLoader },
};
</script>
<style lang="scss" scoped>
.preloader-internal {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 120px;
}
</style>
