<template>
  <div v-if="pageLoaded">
    <h1 class="mira--text mt-n5 mt-sm-n12 mt-xl-n14 mb-5 mb-sm-8">
      {{ (pageData.fields.page_title || "").toLowerCase() }}
    </h1>

    <main-image-gallery
      v-if="$vuetify.breakpoint.smAndUp && pageData.main_heading_section"
      ref="refmainimagegallery"
      :items="pageData.main_heading_section.children"
      :height="galleryHeight"
    />

    <main-image-scroller
      v-else-if="pageData.main_heading_section"
      :items="pageData.main_heading_section.children"
    />

    <main-menu :section-data="pageData.main_menu_section" />

    <main-calendar
      :section-data="pageData.calendar_section"
      :loading="loadingCalendar"
      @request-more="fetchMoreCalendar"
    />

    <div
      v-if="
        pageData.collection_section &&
        pageData.collection_section.children.length > 0
      "
      class="section"
    >
      <page-title
        :title="pageData.collection_section.fields.title"
        :browse-title="pageData.fields.browse_all"
        :browse-link="{ name: 'Collection' }"
      />
      <fluid-collection :items="pageData.collection_section.children" />
    </div>

    <main-pushkin-card
      v-if="pageData.pushkarta_section"
      :section-data="pageData.pushkarta_section"
    />

    <main-whats-new
      :section-data="pageData.whatsnew_section"
      :browse-title="pageData.fields.browse_all || ''"
    />

    <main-about :section-data="pageData.about_section" />

    <zuruck />
  </div>
</template>

<script>
import MainRepository from "@/api/main.js";
import MainImageGallery from "./MainImageGallery.vue";
import MainImageScroller from "./MainImageScroller.vue";
import MainMenu from "./MainMenu.vue";
import MainCalendar from "./MainCalendar.vue";
import FluidCollection from "@/pages/Collection/FluidCollection.vue";
import MainPushkinCard from "./MainPushkinCard.vue";
import MainWhatsNew from "./MainWhatsNew.vue";
import MainAbout from "./MainAbout.vue";
import PageTitle from "@/components/PageTitle.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    MainImageGallery,
    MainImageScroller,
    MainMenu,
    MainCalendar,
    FluidCollection,
    MainPushkinCard,
    MainWhatsNew,
    MainAbout,
    PageTitle,
    Zuruck,
  },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(null),
    };
  },

  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        main_heading_section: { fields: {}, children: [] },
        main_menu_section: { fields: {}, children: [] },
        calendar_section: { fields: {}, children: [], more: 0, next: "" },
        collection_section: { fields: {}, children: [] },
        pushkarta_section: { fields: {} },
        whatsnew_section: {
          fields: {},
          events: { fields: {}, children: [] },
          exhibitions: { fields: {}, children: [] },
          news: { fields: {}, children: [] },
          online_projects: { fields: {}, with_image: [], no_image: [] },
        },
      },
      loadingCalendar: true,
    };
  },

  computed: {
    galleryHeight() {
      if (this.$vuetify.breakpoint.xlOnly) return 750;
      if (this.$vuetify.breakpoint.lgAndUp) return 680;
      if (this.$vuetify.breakpoint.mdAndUp) return 600;
      if (this.$vuetify.breakpoint.smAndUp) return 500;
      return 400;
    },
  },

  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },

  mounted() {
    this.loadPage();
  },

  methods: {
    async loadPage() {
      this.loadingCalendar = true;
      const { data } = await MainRepository.getMain();
      this.pageData = data;
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.loadingCalendar = false;
      this.$emit("page-loaded");
      this.$nextTick(() => {
        if (this.$refs.refmainimagegallery) {
          this.$refs.refmainimagegallery.initObserver();
        }
      });
    },

    async fetchMoreCalendar() {
      if (this.loadingCalendar) {
        return;
      }
      this.loadingCalendar = true;
      const { data } = await MainRepository.getCalendar(
        this.pageData.calendar_section.next
      );
      data.children.forEach((element) => {
        const index = this.pageData.calendar_section.children.length;
        this.$set(this.pageData.calendar_section.children, index, element);
      });
      this.pageData.calendar_section.more = data.more;
      this.pageData.calendar_section.next = data.next;
      setTimeout(() => {
        this.loadingCalendar = false;
      }, 1000);
    },
  },
};
</script>
