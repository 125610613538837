<template>
  <div id="gosuslugi-wrapper">
    <div class="gosuslugi--close clickable" @click.stop="$emit('close')">
      <v-icon>$vuetify.icons.Close</v-icon>
    </div>
    <div id="js-show-iframe-wrapper">
      <div
        class="gosuslugi__content d-flex flex-column flex-sm-row align-sm-center"
      >
        <div class="gosuslugi__content--content d-md-flex align-center">
          <div class="gosuslugi__content--logo mr-md-8 mb-2 mb-sm-4 mb-md-0">
            <v-img
              :src="sectionData.logo"
              width="auto"
              height="70"
              position="left center"
              contain
              eager
            />
          </div>
          <div
            class="gosuslugi__content--text base-2 mb-3 mb-sm-0"
            v-html="sectionData.note"
          />
        </div>
        <div class="flex-grow-1" />
        <div class="gosuslugi__content--controls">
          <v-btn outlined tile>
            {{ sectionData.button_title }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.loadWidget();
  },
  methods: {
    async loadWidget() {
      const script = "https://pos.gosuslugi.ru/bin/script.min.js";
      await this.$loadScript(script);
      /* eslint-disable-next-line no-undef */
      Widget("https://pos.gosuslugi.ru/form", 261565);
    },
  },
};
</script>

<style lang="scss" scoped>
#gosuslugi-wrapper {
  position: fixed;
  top: -230px;
  left: 0;
  right: 0;
  width: 100%;
  height: 230px;
  background-color: #aadddd;
  z-index: 50;
  transition: top 0.15s ease-in-out;

  @media (min-width: 1024px) {
    height: 170px;
    top: -170px;
  }

  @media (min-width: 1280px) {
    height: 100px;
    top: -100px;
  }

  #app.gosuslugi-widget--active & {
    top: 0;
    transition: top 0.15s ease-in-out;
  }

  .gosuslugi--close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 18;
    @media (min-width: 1024px) {
      right: 40px;
      top: 50%;
      margin-top: -16px;
    }
  }

  .gosuslugi__content {
    width: 100%;
    height: 100%;
    background-color: var(--v-bgSecondary-base);
    color: var(--v-textBase-base);
    padding: 20px 20px 20px 20px;
    @media (min-width: 1024px) {
      padding: 15px 80px 15px 40px;
    }
  }
}
#js-show-iframe-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-width: 293px;
  max-width: 100%;
  background: linear-gradient(138.4deg, #38bafe 26.49%, #2d73bc 79.45%);
  color: #fff;
  cursor: pointer;
}
</style>
