<template>
  <kk-link
    :link="`/library/${book.node_name}`"
    class="d-block kk-hoverable-image kk-scale-on-hover"
    draggable="false"
    :style="bookstyle"
  >
    <image-extended
      v-if="book.fields.poster"
      :image="book.fields.poster"
      width="100%"
      :aspect-ratio="coverAspectRatio"
      class="mb-2"
      alt=""
    />
    <v-img
      v-else
      :src="`${basePublicUrl}images/book-cover-blank.svg`"
      width="100%"
      class="kk-hoverable scaling-on-hover mb-2"
      draggable="false"
      alt=""
    />
    <div v-if="inSetList">
      <div v-if="book.fields.is_periodic == 0">
        <div class="caption-common textBase--text mb-1">
          {{ book.fields.title }}
        </div>
        <div
          v-if="book.fields.authors?.length > 0"
          class="caption-common linksOnBgDark--text mb-2"
        >
          {{ book.fields.authors.slice(0, 3).join(", ") }}
        </div>
      </div>
      <div v-else>
        <div class="caption-common textBase--text mb-1">
          {{ book.fields.periodic_title }}
        </div>
        <div class="caption-common linksOnBgDark--text mb-2">
          {{ book.fields.title }}
        </div>
      </div>
    </div>
    <div v-else>
      <div class="caption-common textBase--text mb-1">
        {{ book.fields.title }}
      </div>
      <div
        v-if="book.fields.authors?.length > 0 && book.fields.is_periodic == 0"
        class="caption-common linksOnBgDark--text mb-2"
      >
        {{ book.fields.authors.slice(0, 3).join(", ") }}
      </div>
    </div>
  </kk-link>
</template>

<script>
import KkLink from "@/components/KkLink.vue";
import ImageExtended from "@/components/ImageExtended.vue";

export default {
  name: "LibraryBookPreview",
  components: {
    KkLink,
    ImageExtended,
  },
  props: {
    book: {
      type: Object,
      required: true,
    },
    inSetList: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    basePublicUrl() {
      return process.env.BASE_URL;
    },
    coverAspectRatio() {
      return 286 / 404;
    },
    bookstyle() {
      const w = parseInt(this.width);
      if (w > 0) {
        return { "min-width": `${w}px`, "max-width": `${w}px` };
      } else {
        return {};
      }
    },
  },
};
</script>
