import Library from "@/pages/Library/index.vue";
import LibraryTabBooks from "@/pages/Library/LibraryTabBooks.vue";
import LibraryTabSets from "@/pages/Library/LibraryTabSets.vue";
import LibraryAbout from "@/pages/Library/LibraryAbout";
import LibraryBook from "@/pages/Library/LibraryBook";
import LibrarySet from "@/pages/Library/LibrarySet";

export default [
  {
    path: "/library/about",
    name: "LibraryAbout",
    component: LibraryAbout,
  },
  {
    path: "/library",
    component: Library,
    props: true,
    children: [
      {
        path: "sets",
        component: LibraryTabSets,
        props: true,
        children: [
          {
            path: "tag/:tag",
            name: "LibrarySetsByTag",
            props: true,
            meta: {
              group: "Library",
              kind: "LibrarySets",
              tab: "LibrarySets",
            },
          },
          {
            path: "",
            name: "LibrarySets",
            meta: {
              group: "Library",
              kind: "LibrarySets",
              tab: "LibrarySets",
            },
          },
          {
            path: ":ident",
            name: "LibrarySet",
            component: LibrarySet,
            props: true,
            meta: {
              tab: "LibrarySets",
            },
          },
        ],
      },
      {
        path: "",
        name: "LibraryBooks",
        component: LibraryTabBooks,
        props: true,
        meta: {
          group: "Library",
          kind: "LibraryBooks",
          tab: "LibraryBooks",
        },
        children: [
          {
            path: ":ident",
            name: "LibraryBook",
            component: LibraryBook,
            props: true,
            meta: {
              tab: "LibraryBooks",
            },
          },
        ],
      },
    ],
  },
];
