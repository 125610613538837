<template>
  <v-row no-gutters class="align-end mb-8">
    <v-col class="flex-grow-1">
      <v-tabs
        ref="reftabs"
        v-model="tab"
        color="textLight"
        active-class="textBase--text"
        background-color="bgPrimary"
        slider-color="mira"
        slider-size="4"
        class="tab-row"
        :class="$vuetify.breakpoint.xsOnly ? 'stick-to-body' : ''"
        @change="$emit('change', tab)"
      >
        <v-tab :to="{ name: 'LibraryBooks' }" exact-path>
          {{ libraryFields.books_title }}
        </v-tab>
        <v-tab :to="{ name: 'LibrarySets' }" exact-path>
          {{ libraryFields.sets_title }}
        </v-tab>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LibraryTabs",
  props: {
    libraryFields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tab: "",
    };
  },
  computed: {},
  mounted() {
    const name = this.$route.name;
    if (["LibrarySets", "LibrarySetsByTag", "LibrarySet"].includes(name)) {
      this.tab = "/library/sets/";
    } else {
      this.tab = "/library/";
    }
  },
  methods: {
    async init() {
      this.$refs.reftabs.callSlider();
    },
  },
};
</script>
<style scoped lang="scss">
#app.v-application.theme--light {
  .v-tabs.tab-row {
    overflow-x: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: unset;
  }
  .v-tabs.tab-row::-webkit-scrollbar {
    display: none !important;
  }
}
</style>
