<template>
  <div v-if="sectionData" class="subsection">
    <section-title
      header-id="operating-hours"
      :title="sectionData.fields.title"
    />

    <kk-dynamic-html :html="sectionData.fields.article" class="mb-8 mb-sm-16" />

    <v-row class="align-center">
      <v-col
        cols="12"
        md="5"
        offset-lg="1"
        xl="4"
        class="longread"
        v-html="sectionData.fields.article_heatmap"
      />
      <v-col cols="12" md="7" lg="6" class="order-md-first text-sm-center">
        <img :src="sectionData.fields.heatmap" style="max-width: 100%" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "VisitorsOperatingHours",
  components: { SectionTitle, KkDynamicHtml },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
