<template>
  <div>
    <div v-show="isListRoute">
      <v-row v-if="pageLoaded" class="mb-7 d-none d-sm-flex align-center">
        <v-col>
          <v-text-field
            v-model="filters.titleOrAuthor"
            block
            outlined
            :placeholder="pageData.fields.title_or_author"
            append-icon="$vuetify.icons.Search"
            hide-details
            @change="queryItems"
          />
        </v-col>
        <v-col class="flex-grow-0">
          <div
            class="clickable text-no-wrap d-flex align-center mt-2"
            @click="filtersVisible = !filtersVisible"
          >
            <v-icon class="mr-3">$vuetify.icons.Sliders</v-icon>
            {{
              filtersVisible
                ? pageData.fields.button_filters_hide
                : pageData.fields.button_filters_show
            }}
          </div>
        </v-col>
      </v-row>

      <div id="library-elements" />

      <div class="border-top pt-5 d-none d-sm-block"></div>

      <v-row v-if="pageLoaded" class="mt-4 align-stretch flex-nowrap">
        <v-col>
          <library-filters-line
            v-model="filters"
            :meta="pageData.stat"
            :dic="pageData.fields"
            class="d-none d-sm-block mb-10 mt-1"
            @change="queryItems"
          />
          <library-list
            :items="pageData.children"
            :filters-visible="filtersVisible"
            :not-found-text="pageData.fields.filters_not_found"
          />
        </v-col>

        <v-col
          class="d-none d-sm-block kk-nav-hidable-container transitioned-filters"
          :style="filterPanelStyle"
        >
          <library-filters-controls
            id="filters-desktop"
            v-model="filters"
            :meta="pageData.stat"
            :meta-static="staticDics"
            :dic="pageData.fields"
            class="kk-nav-hidable"
            @change="queryItems"
            @search="searchDic"
          />
        </v-col>
      </v-row>

      <infinite-loader
        :fetch="fetchMore"
        :disabled="loading || pageData.more == 0"
        :visible="pageData.more > 0 || !pageLoaded"
      />

      <auxiliary-menu-bar
        v-if="pageLoaded"
        v-slot="scope"
        class="justify-center"
      >
        <auxiliary-menu
          :title="pageData.fields.button_filters_show"
          icon="$vuetify.icons.Sliders"
          :title-close="pageData.fields.button_filters_hide"
          icon-close="$vuetify.icons.Sliders"
          class="mr-4"
          :class="!scope.expanded ? 'flex-grow-0' : ''"
          @toggle="scope.toggle"
        >
          <library-filters-controls-mobile
            v-model="filters"
            :meta="pageData.stat"
            :meta-static="staticDics"
            :dic="pageData.fields"
            @change="queryItems"
            @search="searchDic"
          />
        </auxiliary-menu>
      </auxiliary-menu-bar>

      <zuruck with-aux-bar />
    </div>

    <router-view
      v-if="!isListRoute"
      :key="$route.params.ident"
      @page-loaded="$emit('page-loaded')"
    >
    </router-view>
  </div>
</template>

<script>
import LibraryRepository from "@/api/library.js";
import LibraryFiltersControls from "./LibraryFiltersControls.vue";
import LibraryFiltersLine from "./LibraryFiltersLine.vue";
import LibraryFiltersControlsMobile from "./LibraryFiltersControlsMobile.vue";
import LibraryList from "./LibraryList.vue";
import AuxiliaryMenuBar from "@/components/AuxiliaryMenu/AuxiliaryMenuBar.vue";
import AuxiliaryMenu from "@/components/AuxiliaryMenu/AuxiliaryMenu.vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";
import Zuruck from "@/components/Zuruck.vue";

export default {
  components: {
    LibraryFiltersControls,
    LibraryFiltersLine,
    LibraryFiltersControlsMobile,
    LibraryList,
    AuxiliaryMenuBar,
    AuxiliaryMenu,
    InfiniteLoader,
    Zuruck,
  },
  props: {
    filterPreset: {
      type: Object,
      default: null,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      init: false,
      pageData: {
        fields: {},
        children: [],
        stat: {
          years: [0, 0],
          categories: [],
          languages: [],
        },
        more: 0,
      },
      staticDics: {
        authors: [],
        publishers: [],
      },
      filters: {
        titleOrAuthor: "",
        years: [0, 0],
        authors: [],
        categories: [],
        languages: [],
        publishers: [],
      },
      offset: 0,
      loading: true,
      filtersVisible: false,
      viewType: 0,
      scrollPos: 0,
      scrollTop: 0,
    };
  },
  computed: {
    isListRoute() {
      return this.$route.name === "LibraryBooks";
    },
    filterPanelStyle() {
      return !this.filtersVisible
        ? {
            "min-width": 0,
            "max-width": 0,
            "margin-left": 0,
            "padding-left": 0,
            "padding-right": 0,
            "overflow-x": "hidden",
          }
        : {};
    },
    queryParams() {
      const params = {};
      if (this.filters.titleOrAuthor.length > 0) {
        params.title_or_author = this.filters.titleOrAuthor;
      }
      if (
        this.filters.years[0] !== 0 &&
        !(
          this.filters.years[0] === this.pageData.stat.years[0] &&
          this.filters.years[1] === this.pageData.stat.years[1]
        )
      ) {
        params.min_year = this.filters.years[0];
        params.max_year = this.filters.years[1];
      }
      if (this.filters.authors.length > 0) {
        params.authors = this.filters.authors;
      }
      if (this.filters.categories.length > 0) {
        params.categories = this.filters.categories;
      }
      if (this.filters.languages.length > 0) {
        params.languages = this.filters.languages;
      }
      if (this.filters.publishers.length > 0) {
        params.publishers = this.filters.publishers;
      }
      return params;
    },
    routeKind() {
      return this.$route.meta?.kind || this.$route.name;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    async "$route.name"() {
      if (this.filterPreset) {
        const f = this.filterPreset;
        this.filters.titleOrAuthor = f.titleOrAuthor || "";
        this.filters.categories = f.category ? [f.category] : [];
        this.filters.publishers = f.publisher ? [f.publisher] : [];
        this.filters.languages = f.language ? [f.language] : [];
        this.filters.authors = f.author ? [f.author] : [];
        if (f.years) {
          const matchTwo = f.years.match(/(\d{4}).+(\d{4})/);
          const matchOne = f.years.match(/(\d{4})/);
          if (matchTwo) {
            this.filters.years = [matchTwo[1], matchTwo[2]];
          } else if (matchOne) {
            this.filters.years = [matchOne[1], matchOne[1]];
          }
        }
        await this.queryItems();
        await this.$nextTick();
        const element = document.getElementById("library-elements");
        if (element) {
          element.scrollIntoView({ behavior: "instant" });
        }
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      await this.queryItems();
      this.filters.years = this.pageData.stat.years;
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.loading = false;
      if (this.$route.name !== "LibraryBook") {
        this.$emit("page-loaded");
      }
    },
    async queryItems() {
      // Запомним текущую позицию скролла
      this.scrollPos = Math.round(window.scrollY);

      const { data } = await LibraryRepository.getBooks(this.queryParams);
      this.offset = data.children.length;
      this.pageData = data;

      // Пережидаем цикл обновления DOM и исправляем позицию скролла, если она съехала
      // (проявляется в Firefox, особенно если дрыгать туда-сюда годы в фильтре)
      if (this.pageLoaded) {
        this.fixScroll();
      }
    },
    async fixScroll() {
      await this.$nextTick();
      if (this.scrollPos != Math.round(window.scrollY)) {
        window.scrollTo({ top: this.scrollPos, behavior: "instant" });
      }
    },
    async fetchMore() {
      this.loading = true;

      const queryParams = { ...this.queryParams };
      queryParams.offset = this.offset;

      const { data } = await LibraryRepository.getBooks(queryParams);

      this.offset += data.children.length;
      data.children.forEach((element) => {
        const index = this.pageData.children.length;
        this.$set(this.pageData.children, index, element);
      });
      this.pageData.more = data.more;

      this.loading = false;
    },
    async searchDic(dicName, term) {
      let items = [];
      if (term.length > 1) {
        const { data } = await LibraryRepository.searchDic(dicName, term);
        items = data;
      } else {
        items = [];
      }
      this.$set(this.staticDics, dicName, []);
      items.forEach((item) => {
        this.staticDics[dicName].push(item);
      });
    },
  },
};
</script>
<style scoped lang="scss">
#app.v-application.theme--light {
  .transitioned-filters {
    transition: all 0.3s ease-in-out;
  }
  .kk-nav-hidable-container {
    min-width: 336px;
    max-width: 336px;
    @media (min-width: 1440px) {
      min-width: 390px;
      max-width: 390px;
    }

    .kk-nav-hidable {
      min-width: 312px;
      max-width: 312px;
      @media (min-width: 1440px) {
        min-width: 366px;
        max-width: 366px;
      }
    }
  }
  .loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
  }
  .view-switcher {
    z-index: 10;
  }
}
</style>
