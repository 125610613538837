<template>
  <div class="img-header stick-to-body stick-to-menu" :data-glow="glow">
    <div :id="observedId">
      <image-extended
        :image="image"
        :position="`center ${parallax}%`"
        class="img-header-img"
        eager
      />
      <video-background :src="video" />
      <div
        v-if="glow == '1'"
        ref="refglow"
        class="glow"
        :style="glowStyle"
        @mousemove="onMouseMove"
        @mouseleave="onMouseOut"
      />
    </div>
  </div>
</template>

<script>
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
import ImageExtended from "@/components/ImageExtended.vue";
import VideoBackground from "@/components/VideoBackground.vue";

export default {
  components: { ImageExtended, VideoBackground },
  mixins: [ProgressObserverMixin],
  props: {
    image: {
      type: Object,
      default: () => {},
    },
    glow: {
      type: String,
      default: "0",
    },
    video: {
      type: String,
      default: "",
    },
    observedId: {
      type: String,
      default: "header-image",
    },
  },
  data() {
    return {
      glowRadius: 1000,
      glowCoord: {
        x: null,
        y: null,
      },
      obvOptions: {
        viewportTopPct: 0,
        viewportBottomPct: 0,
        pivotBegin: "top",
        pivotEnd: "bottom",
      },
      obvDeltaPct: 25,
      obvBounds: {
        min: 40,
        max: 90,
      },
    };
  },
  computed: {
    parallax() {
      const { min, max } = this.obvBounds;
      return min + this.obv(this.observedId) * (max - min);
    },
    height() {
      if (this.$vuetify.breakpoint.mdAndUp) return 530;
      if (this.$vuetify.breakpoint.smAndUp) return 250;
      return 170;
    },
    glowStyle() {
      const { x, y } = this.glowCoord;
      if (x == null || y == null) {
        return {
          "background-color": "black",
        };
      } else {
        return {
          background: `radial-gradient(${this.glowRadius}px
            circle at ${x}px ${y}px,
            rgba(255, 255, 255, 0.25) 0%,
            rgba(255, 255, 255, 0.1) 10%,
            transparent 16%,
            black 40%`,
        };
      }
    },
  },
  methods: {
    initObserver() {
      this.obvAddElement(this.observedId);

      const top = this.image?.i_top || 20;
      const bottom = this.image?.i_bottom || 80;
      const posY = (top + bottom) / 2;

      this.obvBounds.min = posY - this.obvDeltaPct;
      this.obvBounds.max = posY + this.obvDeltaPct;
      if (this.obvBounds.min < 0) {
        this.obvBounds.min = 0;
        this.obvBounds.max = this.obvBounds.min + this.obvDeltaPct * 2;
      } else if (this.obvBounds.max > 100) {
        this.obvBounds.max = 100;
        this.obvBounds.min = this.obvBounds.max - this.obvDeltaPct * 2;
      }
    },
    onMouseMove(event) {
      if (this.glow == "1" && this.$refs.refglow) {
        const rect = this.$refs.refglow.getBoundingClientRect();
        this.glowCoord.x = event.clientX - rect.left;
        this.glowCoord.y = event.clientY - rect.top;
      }
    },
    onMouseOut() {
      this.glowCoord.x = this.glowCoord.y = null;
    },
  },
};
</script>
<style scoped lang="scss">
.img-header {
  margin-bottom: 80px;
  position: relative;
  overflow: hidden;

  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
}
.glow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 3;
}
.img-header-img {
  height: 180px;
  @media (min-width: 640px) {
    height: 250px;
  }
  @media (min-width: 1024px) {
    height: 350px;
  }
  @media (min-width: 1280px) {
    height: 530px;
  }
}
</style>
