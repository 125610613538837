<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <path
      d="M15.1786 24.8399V23.9682H16.0714H25V0.429688H0V33.5579H15.1786V24.8399ZM16.9643 32.325L23.7373 25.7117H16.9643V32.325Z"
      fill="currentColor"
    />
    <path d="M16.0714 9.14844H8.92859V16.1228H16.0714V9.14844Z" fill="white" />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 25,
    },
    height: {
      type: [Number, String],
      default: 34,
    },
  },
};
</script>
