<template>
  <v-dialog v-model="dialog" content-class="common-dialog inclusive-dialog">
    <v-card class="card pa-4 pa-sm-16">
      <v-card-title class="flex-nowrap pa-0 mb-8">
        <h3 style="word-break: normal">{{ $t("Inclusive.title") }}</h3>
        <v-spacer></v-spacer>
        <div class="pa-2 mb-1 mr-n2" @click="dialog = false">
          <v-icon class="link" size="40">$vuetify.icons.Close</v-icon>
        </div>
      </v-card-title>
      <v-row dense>
        <v-col v-for="(val, model, index) in models" :key="index" cols="12">
          <v-checkbox
            :input-value="val"
            :label="$t(`Inclusive.models.${model}`)"
            hide-details
            on-icon="$vuetify.icons.CheckOn"
            off-icon="$vuetify.icons.CheckOff"
            class="mt-0"
            @change="onCheckboxChange($event, model)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn outlined tile @click="dialog = false"
            >{{ $t("Common.apply") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "HeaderInclusiveModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    models() {
      return this.$store.getters.getInclusiveModels;
    },
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    this.$store.dispatch("initInclusive");
  },
  methods: {
    onCheckboxChange(value, model) {
      if (value) {
        this.$store.dispatch("setInclusive", model);
      } else {
        this.$store.dispatch("unsetInclusive", model);
      }
    },
  },
};
</script>
