<template>
  <div class="view-switcher__container">
    <div
      class="view-switcher clickable mr-2"
      :class="{ active: viewType == 0 }"
      @click="viewType = 0"
    >
      <v-icon>$vuetify.icons.ViewGrid</v-icon>
    </div>
    <div
      class="view-switcher clickable"
      :class="{ active: viewType == 1 }"
      @click="viewType = 1"
    >
      <v-icon>$vuetify.icons.ViewList</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewSwitcher",
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    viewType: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style scoped lang="scss">
#app.v-application.theme--light {
  .view-switcher__container {
    display: flex;
    align-items: center;

    .view-switcher {
      padding: 8px;
      .v-icon {
        display: flex;
        color: var(--v-line-base);
      }
      &.active {
        background-color: var(--v-bgSecondary-base);
        .v-icon {
          color: var(--v-textBase-base);
        }
      }
    }
  }
}
</style>
