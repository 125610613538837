<template>
  <div
    v-if="itemData.fields && itemData.fields.image"
    class="pa-3 collection__link-container"
  >
    <kk-link
      :link="`/collection/${itemData.node_name}`"
      class="d-block collection__link"
      draggable="false"
    >
      <div :style="style" class="collection__img d-none d-sm-block" />
      <img
        :src="itemData.fields.image.i_size_500 || itemData.fields.image.i_main"
        class="collection__img d-block d-sm-none"
        draggable="false"
        alt=""
      />
    </kk-link>
  </div>
</template>
<script>
import KkLink from "@/components/KkLink.vue";
export default {
  name: "FluidCollectionItem",
  components: { KkLink },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    style() {
      const img =
        this.itemData.fields.image.i_size_500 ||
        this.itemData.fields.image.i_main;
      return {
        "background-image": `url(${img})`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1023px) {
  .collection__link {
    height: 240px;
    .collection__img {
      height: 100%;
      width: auto;
    }
  }
}

@media (min-width: 1024px) {
  .collection__link-container {
    transition: flex 0.3s linear;
    .collection__link {
      width: 100%;
      height: 100%;
      .collection__img {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
      }
    }
  }
}
</style>
