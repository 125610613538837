var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pageLoaded)?_c('div',{staticClass:"section"},[_c('kk-breadcrumbs',{staticClass:"no-marker px-0 mb-6 mb-sm-12 mt-n4 mt-sm-0",attrs:{"items":_vm.breadcrumbsItems}}),_vm._v(" "),_c('h2',{staticClass:"mb-3 mb-sm-8"},[_vm._v(_vm._s(_vm.pageData.fields.title))]),_vm._v(" "),_c('v-row',{staticClass:"flex-sm-nowrap mt-0 mt-sm-3"},[_c('v-col',{staticClass:"collection-piece"},[_c('div',{staticClass:"collection-image mb-5 mb-sm-10",on:{"click":_vm.zoom}},[_c('image-extended',{ref:"refcollectionimage",attrs:{"image":_vm.pageData.fields.image,"max-width":"100%","max-height":"calc(100vh - 200px)","contain":true,"fill":"transparent"},on:{"load":_vm.onMainImageLoad}})],1),_vm._v(" "),_c('kk-dynamic-html',{attrs:{"html":_vm.pageData.fields.article}})],1),_vm._v(" "),_c('v-col',{staticClass:"order-first order-sm-last collection-nav ml-0",attrs:{"cols":"12","sm":""}},[_c('div',{staticClass:"kk-sticky-container"},[_c('div',{staticClass:"kk-sticky"},[((_vm.pageData.fields.authors || []).length > 0)?_c('v-row',{staticClass:"border-top"},[_c('v-col',{staticClass:"linksOnBgDark--text flex-grow-0 text-no-wrap"},[_vm._v(_vm._s((_vm.pageData.collection_fields.filters_author || "").toLowerCase()))]),_vm._v(" "),_c('v-col',{staticClass:"text-right"},_vm._l((_vm.pageData.fields.authors || []),function(author,authorIndex){return _c('span',{key:authorIndex},[(authorIndex > 0)?_c('span',[_vm._v(", ")]):_vm._e(),_vm._v(" "),_c('router-link',{staticClass:"link",attrs:{"to":{
                    name: 'Collection',
                    params: { filterPreset: { author } },
                  }}},[_vm._v("\n                  "+_vm._s(author)+"\n                ")])],1)}),0)],1):_vm._e(),_vm._v(" "),_c('v-row',{staticClass:"border-top"},[_c('v-col',{staticClass:"linksOnBgDark--text flex-grow-0 text-no-wrap"},[_vm._v(_vm._s((_vm.pageData.collection_fields.filters_title || "").toLowerCase()))]),_vm._v(" "),_c('v-col',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.pageData.fields.title))])],1),_vm._v(" "),(_vm.pageData.fields.category?.length > 0)?_c('v-row',{staticClass:"border-top"},[_c('v-col',{staticClass:"linksOnBgDark--text flex-grow-0 text-no-wrap"},[_vm._v(_vm._s((
                _vm.pageData.collection_fields.filters_category || ""
              ).toLowerCase()))]),_vm._v(" "),_c('v-col',{staticClass:"text-right"},[_c('router-link',{staticClass:"link",attrs:{"to":{
                  name: 'Collection',
                  params: {
                    filterPreset: { category: _vm.pageData.fields.category },
                  },
                }}},[_vm._v("\n                "+_vm._s(_vm.pageData.fields.category)+"\n              ")])],1)],1):_vm._e(),_vm._v(" "),(_vm.pageData.fields.year?.length > 0)?_c('v-row',{staticClass:"border-top"},[_c('v-col',{staticClass:"linksOnBgDark--text flex-grow-0 text-no-wrap"},[_vm._v(_vm._s((_vm.pageData.collection_fields.filters_year || "").toLowerCase()))]),_vm._v(" "),_c('v-col',{staticClass:"text-right"},[_c('router-link',{staticClass:"link",attrs:{"to":{
                  name: 'Collection',
                  params: { filterPreset: { years: _vm.pageData.fields.year } },
                }}},[_vm._v("\n                "+_vm._s(_vm.pageData.fields.year)+"\n              ")])],1)],1):_vm._e()],1)])])],1),_vm._v(" "),_c('v-dialog',{attrs:{"content-class":"zoom-dialog","overlay-opacity":0.54},model:{value:(_vm.zoomDialog),callback:function ($$v) {_vm.zoomDialog=$$v},expression:"zoomDialog"}},[_c('div',{staticClass:"zoom-control zoom-close clickable",on:{"click":function($event){_vm.zoomDialog = false}}},[_c('v-icon',{attrs:{"size":"36"}},[_vm._v("$vuetify.icons.Close")])],1),_vm._v(" "),_c('div',{staticClass:"zoom-panel"},[_c('div',{attrs:{"id":"zoom-modal-zoom-out"}},[_c('div',{staticClass:"zoom-panel-control clickable"},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v("$vuetify.icons.ZoomOut")])],1)]),_vm._v(" "),_c('div',{staticClass:"ml-5",attrs:{"id":"zoom-modal-zoom-in"}},[_c('div',{staticClass:"zoom-panel-control clickable"},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v("$vuetify.icons.ZoomIn")])],1)])]),_vm._v(" "),_c('div',{staticClass:"zoom-dialog__scrollbox",attrs:{"id":"openseadragon-modal"}})]),_vm._v(" "),_c('collection-sub-piece-dialog',{attrs:{"show":_vm.subItemDialog,"ident":_vm.subident,"item":_vm.pageData.current_sub_item},on:{"close-dialog":_vm.onSubitemDialogClose}}),_vm._v(" "),(_vm.pageData.children?.length > 0)?_c('div',{staticClass:"subelements",attrs:{"id":"collection-subelements"}},[_c('collection-list',{attrs:{"items":_vm.pageData.children || [],"base-url":_vm.ident}}),_vm._v(" "),_c('infinite-loader',{attrs:{"fetch":_vm.fetchMore,"disabled":_vm.disableInfiniteScroll,"visible":_vm.pageData.more > 0 || !_vm.pageLoaded,"element-id":"v-infinite-loader-subcollection"}})],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }