<template>
  <div v-if="sectionData">
    <div
      class="d-sm-flex flex-wrap flex-md-nowrap align-center justify-space-between mb-5 mb-sm-10"
    >
      <div class="flex-grow-1 flex-shrink-3 pb-3 pb-sm-2 pr-1 pr-sm-3">
        <h3 id="halls" class="fix-contents">{{ sectionData.fields.title }}</h3>
      </div>
      <div class="flex-grow-0 text-no-wrap pl-1 pl-sm-3">
        <v-btn outlined tile @click="mapDialog = true">
          {{ sectionData.fields.browse_map }}
        </v-btn>
      </div>
    </div>

    <horizontal-scroller
      :items="sectionData.children"
      content-class="mx-n3"
      stick-to="left"
    >
      <template v-slot="{ item }">
        <div class="mx-3">
          <div>
            <image-extended
              :image="item.fields.image"
              eager
              class="hall-image mb-3 mb-sm-6"
            />
          </div>
          <h4 class="selectable-text">{{ item.fields.title }}</h4>
          <kk-dynamic-html
            :html="item.fields.description"
            class="selectable-text"
          />
        </div>
      </template>
    </horizontal-scroller>

    <v-dialog v-model="mapDialog" content-class="common-dialog">
      <v-card class="card">
        <v-row no-gutters class="mb-8 mb-sm-12">
          <v-col>
            <h3>{{ sectionData.fields.browse_map_title }}</h3>
          </v-col>
          <v-col class="text-right flex-grow-0">
            <div class="pa-2 mb-1 mr-n2" @click="mapDialog = false">
              <v-icon class="link" size="40">$vuetify.icons.Close</v-icon>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="mb-8 mb-sm-12 justify-center">
          <v-col>
            <img width="100%" :src="sectionData.fields.image_map" />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import ImageExtended from "@/components/ImageExtended.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "AboutHalls",
  components: {
    HorizontalScroller,
    ImageExtended,
    KkDynamicHtml,
  },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mapDialog: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.hall-image {
  width: 750px;
  height: 450px;
  @media (max-width: 1023px) {
    width: 260px;
    height: 260px;
  }
}
</style>
