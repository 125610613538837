<template>
  <div v-if="sectionData" class="section">
    <page-title :title="sectionData.fields.title" />

    <horizontal-scroller
      :items="sectionData.children"
      content-class="mx-n3"
      class="pb-4 pb-sm-8"
      @drag-end="awakeLoader"
    >
      <template v-slot="{ item }">
        <main-calendar-day
          :item-data="item"
          :header-data="headerData"
          class="mx-3"
        />
      </template>
      <template #append>
        <div id="calendar-loader-container">
          <infinite-loader
            ref="infiniteloader"
            element-id="calendar-infinite-loader"
            :fetch="fetchMore"
            :disabled="loading || sectionData.more == 0 || disabled"
            :visible="sectionData.more > 0"
            direction="right"
          />
        </div>
      </template>
    </horizontal-scroller>

    <v-row v-if="sectionData.fields.notice" no-gutters>
      <v-col cols="12" sm="6" md="5" lg="4" xl="3">
        <kk-dynamic-html :html="sectionData.fields.notice" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PageTitle from "@/components/PageTitle.vue";
import MainCalendarDay from "./MainCalendarDay.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import InfiniteLoader from "@/components/InfiniteLoader.vue";

export default {
  name: "MainCalendar",
  components: {
    PageTitle,
    MainCalendarDay,
    HorizontalScroller,
    KkDynamicHtml,
    InfiniteLoader,
  },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      disabled: false,
    };
  },
  computed: {
    headerData() {
      return this.$store.getters.getHeader;
    },
  },
  methods: {
    awakeLoader() {
      this.$refs.infiniteloader?.fetchFix();
    },
    fetchMore() {
      this.$emit("request-more");
    },
  },
};
</script>
<style scoped>
@media (max-width: 1023px) {
  #calendar-loader-container {
    padding-top: 120px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1024px) {
  #calendar-loader-container {
    padding-top: 160px;
    padding-left: 80px;
    padding-right: 80px;
  }
}
</style>
