<template>
  <v-row class="footer__partners justify-end flex-wrap">
    <v-col
      v-for="(item, index) in items"
      :key="index"
      cols="12"
      sm=""
      class="flex-grow-0"
    >
      <kk-link :link="item.fields.link" class="d-inline-block">
        <v-img
          :src="item.fields.image"
          max-width="125"
          max-height="40"
          contain
          position="left top"
          eager
        />
      </kk-link>
    </v-col>
  </v-row>
</template>
<script>
import KkLink from "@/components/KkLink.vue";
export default {
  name: "FooterPartners",
  components: { KkLink },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
