<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="currentColor"
      d="M14.6667 23.3333C19.4531 23.3333 23.3333 19.4531 23.3333 14.6667C23.3333 9.8802 19.4531 6 14.6667 6C9.8802 6 6 9.8802 6 14.6667C6 19.4531 9.8802 23.3333 14.6667 23.3333ZM25.3333 14.6667C25.3333 17.2514 24.414 19.6215 22.8844 21.4676L22.9076 21.4908L28.7076 27.2908L29.4147 27.9979L28.0005 29.4121L27.2934 28.705L21.4934 22.905L21.4704 22.882C19.6239 24.413 17.2528 25.3333 14.6667 25.3333C8.77563 25.3333 4 20.5577 4 14.6667C4 8.77563 8.77563 4 14.6667 4C20.5577 4 25.3333 8.77563 25.3333 14.6667ZM10.6667 15.6667H18.6667V13.6667H10.6667V15.6667Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
  },
};
</script>
