<template>
  <div v-if="pageLoaded" class="shsi__container">
    <image-header
      ref="refimageheader"
      :image="pageData.fields.poster"
      :video="pageData.fields.video"
      class="mb-10 mb-md-16"
    />

    <page-title :title="pageData.fields.title" />

    <kk-article ref="refarticle" :contents="contents" dense class="section">
      <div
        v-for="block in pageData.children || []"
        :key="block.node_name"
        class="subsection"
      >
        <section-title
          :header-id="block.node_name"
          :title="block.fields.title"
          :browse-title="block.fields.button_all || ''"
          :browse-link="browseLink(block)"
          class="mb-5 mb-md-10"
        >
        </section-title>
        <shsi-events-widget
          v-if="block.class_name == 'shsi_events_widget'"
          :block-data="block"
        />
        <shsi-exhibitions-widget
          v-else-if="block.class_name == 'shsi_exhibitions_widget'"
          :block-data="block"
        />
        <shsi-press
          v-else-if="block.class_name == 'shsi_press'"
          :block-data="block"
        />
        <shsi-partners
          v-else-if="block.class_name == 'shsi_partners'"
          :block-data="block"
        />
        <shsi-contacts
          v-else-if="block.class_name == 'shsi_contacts'"
          :block-data="block"
        />
        <kk-dynamic-html
          v-else-if="block.class_name == 'shsi_paragraph'"
          :html="block.fields.article"
        />
      </div>
    </kk-article>

    <zuruck with-aux-bar />
  </div>
</template>
<script>
import ShsiRepository from "@/api/shsi.js";
import ImageHeader from "@/components/ImageHeader.vue";
import PageTitle from "@/components/PageTitle.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import KkArticle from "@/components/Article/KkArticle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Zuruck from "@/components/Zuruck.vue";
import ShsiEventsWidget from "./ShsiEventsWidget.vue";
import ShsiExhibitionsWidget from "./ShsiExhibitionsWidget.vue";
import ShsiPress from "./ShsiPress.vue";
import ShsiPartners from "./ShsiPartners.vue";
import ShsiContacts from "./ShsiContacts.vue";

export default {
  components: {
    ImageHeader,
    PageTitle,
    SectionTitle,
    KkArticle,
    KkDynamicHtml,
    ShsiEventsWidget,
    ShsiExhibitionsWidget,
    ShsiPress,
    ShsiPartners,
    ShsiContacts,
    Zuruck,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
      },
    };
  },
  computed: {
    contents() {
      return (this.pageData.children || []).reduce((contents, item) => {
        contents[item.node_name] = item.fields.title;
        return contents;
      }, {});
    },
    browseLink() {
      return (blockData) => {
        if (blockData.class_name == "shsi_exhibitions_widget") {
          if (blockData.fields.tags.length == 0) {
            return { path: "/exhibitions/all" };
          } else {
            const tag = blockData.fields.tags[0];
            return { name: "ExhibitionsAll", params: { tag: tag.id } };
          }
        } else if (blockData.class_name == "shsi_events_widget") {
          if (blockData.fields.tags.length == 0) {
            return { path: "/events/all" };
          } else {
            const tag = blockData.fields.tags[0];
            return { name: "EventsAll", params: { tag: tag.id } };
          }
        } else if (blockData.class_name == "shsi_press") {
          return { name: "ShsiPressPage" };
        } else {
          return {};
        }
      };
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await ShsiRepository.getShsi();
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs?.refimageheader?.initObserver();
        this.$refs?.refarticle?.initObserver();
      });
    },
  },
};
</script>
<style lang="scss">
.shsi__container {
  --v-mira-base: #f15b40;
}
</style>
