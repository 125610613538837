<template>
  <div v-if="pageLoaded" class="section">
    <kk-breadcrumbs
      class="no-marker px-0 mb-6 mb-sm-12 mt-n4 mt-sm-0"
      :items="breadcrumbsItems"
    />

    <h2 class="mb-3 mb-sm-8">{{ pageData.fields.title }}</h2>

    <div class="library-image d-sm-none mt-8">
      <image-extended
        v-if="pageData.fields.image_gallery.items.length == 1"
        :image="pageData.fields.image_gallery.items[0]"
        max-width="100%"
        max-height="calc(100vh - 200px)"
        fill="transparent"
        class="mb-4"
      />
      <image-gallery
        v-else-if="pageData.fields.image_gallery.items.length > 1"
        :items="pageData.fields.image_gallery.items"
      />
    </div>

    <v-row class="flex-sm-nowrap mt-0 mt-sm-3 subsection">
      <v-col class="library-book">
        <div class="library-image d-none d-sm-block mb-sm-10">
          <image-extended
            v-if="pageData.fields.image_gallery.items.length == 1"
            :image="pageData.fields.image_gallery.items[0]"
            max-width="100%"
            max-height="calc(100vh - 200px)"
            fill="transparent"
          />
          <image-gallery
            v-else-if="pageData.fields.image_gallery.items.length > 1"
            :items="pageData.fields.image_gallery.items"
          />
        </div>

        <kk-dynamic-html :html="pageData.fields.article" />

        <h4
          v-if="pageData.fields.keywords.length > 0"
          class="mt-6 mt-sm-12 mb-2 mb-md-4"
        >
          {{ pageData.library_books_fields.title_keywords }}
        </h4>

        <tag-list :tags="pageData.fields.keywords" :tag-route="keywordRoute" />
      </v-col>
      <v-col cols="12" sm="" class="order-first order-sm-last library-nav ml-0">
        <div class="kk-sticky-container">
          <div class="kk-sticky">
            <v-row
              v-if="(pageData.fields.authors || []).length > 0"
              class="border-top"
            >
              <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
                (
                  pageData.library_books_fields.filters_author || ""
                ).toLowerCase()
              }}</v-col>
              <v-col
                class="text-right authors-wrap"
                :class="{
                  'authors-wrap--collapsed': !authorsAll && hasCollapser,
                }"
              >
                <span
                  v-for="(author, authorIndex) in pageData.fields.authors || []"
                  :key="authorIndex"
                >
                  <span v-if="authorIndex > 0">, </span>
                  <router-link
                    :to="{
                      name: 'LibraryBooks',
                      params: { filterPreset: { author } },
                    }"
                    class="link"
                    >{{ author }}</router-link
                  >
                </span>
                <div v-if="hasCollapser" class="authors-more">
                  <span class="clickable" @click="authorsAll = !authorsAll">{{
                    authorsAll ? "свернуть" : "смотреть всех"
                  }}</span>
                </div>
              </v-col>
            </v-row>

            <v-row
              v-if="pageData.fields.periodic_title.length > 0"
              class="border-top"
            >
              <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
                (
                  pageData.library_books_fields.filters_periodic_title || ""
                ).toLowerCase()
              }}</v-col>
              <v-col class="text-right">{{
                pageData.fields.periodic_title
              }}</v-col>
            </v-row>

            <v-row
              v-if="pageData.fields.publisher?.length > 0"
              class="border-top"
            >
              <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
                (
                  pageData.library_books_fields.filters_publisher || ""
                ).toLowerCase()
              }}</v-col>
              <v-col class="text-right">
                <router-link
                  :to="{
                    name: 'LibraryBooks',
                    params: {
                      filterPreset: { publisher: pageData.fields.publisher },
                    },
                  }"
                  class="link"
                >
                  {{ pageData.fields.publisher }}
                </router-link>
              </v-col>
            </v-row>

            <v-row v-if="pageData.fields.year?.length > 0" class="border-top">
              <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
                (pageData.library_books_fields.filters_year || "").toLowerCase()
              }}</v-col>
              <v-col class="text-right">
                <router-link
                  :to="{
                    name: 'LibraryBooks',
                    params: { filterPreset: { years: pageData.fields.year } },
                  }"
                  class="link"
                >
                  {{ pageData.fields.year }}
                </router-link>
              </v-col>
            </v-row>

            <v-row
              v-if="pageData.fields.language?.length > 0"
              class="border-top"
            >
              <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
                (
                  pageData.library_books_fields.filters_language || ""
                ).toLowerCase()
              }}</v-col>
              <v-col class="text-right">
                <router-link
                  :to="{
                    name: 'LibraryBooks',
                    params: {
                      filterPreset: { language: pageData.fields.language },
                    },
                  }"
                  class="link"
                >
                  {{ pageData.fields.language }}
                </router-link>
              </v-col>
            </v-row>

            <v-row
              v-if="pageData.fields.category?.length > 0"
              class="border-top"
            >
              <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
                (
                  pageData.library_books_fields.filters_category || ""
                ).toLowerCase()
              }}</v-col>
              <v-col class="text-right">
                <router-link
                  :to="{
                    name: 'LibraryBooks',
                    params: {
                      filterPreset: { category: pageData.fields.category },
                    },
                  }"
                  class="link"
                >
                  {{ pageData.fields.category }}
                </router-link>
              </v-col>
            </v-row>

            <v-row
              v-if="pageData.fields.age_rating.length > 0"
              class="border-top"
            >
              <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
                (
                  pageData.library_books_fields.filters_age_rating || ""
                ).toLowerCase()
              }}</v-col>
              <v-col class="text-right">
                <v-icon color="" class="" size="30">{{
                  `$vuetify.icons.AgeRating${pageData.fields.age_rating}`
                }}</v-icon>
              </v-col>
            </v-row>

            <v-row
              v-if="pageData.fields.quantity.length > 0"
              class="border-top"
            >
              <v-col class="linksOnBgDark--text flex-grow-0 text-no-wrap">{{
                (
                  pageData.library_books_fields.filters_quantity || ""
                ).toLowerCase()
              }}</v-col>
              <v-col class="text-right">{{ pageData.fields.quantity }}</v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <section-title
      v-if="pageData.library_sets.children.length > 0"
      :title="pageData.library_books_fields.title_sets"
      :browse-title="pageData.library_books_fields.title_sets_all"
      :browse-link="{ name: 'LibrarySets' }"
    />

    <horizontal-scroller
      :items="pageData.library_sets.children"
      :disabled="pageData.library_sets.children.length == 1"
      content-class="mx-n3"
    >
      <template v-slot="{ item }">
        <preview
          :payload="item"
          inner-info
          no-date
          :to="{ name: 'LibrarySet', params: { ident: item.node_name } }"
          class="mx-3"
        />
      </template>
    </horizontal-scroller>
  </div>
</template>

<script>
import LibraryRepository from "@/api/library.js";
import KkBreadcrumbs from "@/components/Article/KkBreadcrumbs.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import ImageExtended from "@/components/ImageExtended.vue";
import ImageGallery from "@/components/ImageGallery.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import Preview from "@/components/Preview/Preview.vue";
import TagList from "@/components/Tags/TagList.vue";

export default {
  name: "LibraryBook",

  components: {
    KkBreadcrumbs,
    KkDynamicHtml,
    ImageExtended,
    ImageGallery,
    TagList,
    HorizontalScroller,
    SectionTitle,
    Preview,
  },

  props: {
    ident: {
      type: String,
      required: true,
    },
    subident: {
      type: String,
      default: "",
    },
  },

  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },

  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        children: [],
        main_fields: {},
        library_fields: {},
        library_books_fields: {},
        library_sets: { fields: {}, children: [] },
      },
      loading: true,
      authorsAll: false,
    };
  },

  computed: {
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.library_fields.title || "",
          to: { name: "LibraryBooks" },
          exact: true,
          disabled: false,
        },
      ];
    },

    disableInfiniteScroll() {
      return this.loading || this.pageData.more == 0;
    },

    hasCollapser() {
      return this.pageData.fields.authors.length > 6;
    },
  },

  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },

  mounted() {
    this.loadPage();
  },

  methods: {
    async loadPage() {
      const { data } = await LibraryRepository.getBook(this.ident);
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
    keywordRoute(tag) {
      return {
        name: "LibraryBooks",
        params: {
          filterPreset: { titleOrAuthor: tag },
        },
      };
    },
  },
};
</script>
<style scoped lang="scss">
.library-nav {
  @media (min-width: 1024px) {
    min-width: 300px;
    max-width: 300px;
  }
  @media (min-width: 1280px) {
    min-width: 336px;
    max-width: 336px;
    margin-left: 48px;
  }
  @media (min-width: 1440px) {
    min-width: 390px;
    max-width: 390px;
  }
}
.library-book {
  @media (min-width: 1024px) {
    min-width: calc(100% - 300px);
    max-width: calc(100% - 300px);
  }
  @media (min-width: 1280px) {
    min-width: calc(100% - 336px);
    max-width: calc(100% - 336px);
  }
  @media (min-width: 1440px) {
    min-width: calc(100% - 390px);
    max-width: calc(100% - 390px);
  }
}

.kk-sticky .row.border-top:first-child {
  border: none !important;
}

.authors-wrap .authors-more {
  display: none;
}

@media (min-width: 1024px) {
  .authors-wrap {
    overflow-y: hidden;
    position: relative;

    .authors-more {
      display: block;
      background-color: rgb(247, 246, 246);
      padding-top: 24px;
      padding-bottom: 12px;
      height: auto;
    }

    &.authors-wrap--collapsed {
      min-height: 172px;
      max-height: 172px;

      .authors-more {
        position: absolute;
        z-index: 3;
        bottom: 0;
        left: 0;
        right: 0;
        padding-right: 12px;
        padding-bottom: 24px;
      }
    }
  }
}
</style>
