<template>
  <transition
    appear
    name="progressbar"
    @before-enter="$emit('progress-bar-start')"
    @after-enter="$emit('progress-bar-finish')"
  >
    <div
      v-if="!trigger"
      class="progress"
      :style="{ 'transition-duration': `${duration}s` }"
    ></div>
  </transition>
</template>
<script>
export default {
  props: {
    trigger: {
      type: Boolean,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style scoped>
.progressbar-enter,
.progressbar-leave-to {
  width: 0%;
}

.progressbar-enter-active {
  transition-property: width;
  transition-timing-function: linear;
}
.progressbar-leave-active {
  transition-property: none;
}

.progressbar-leave,
.progressbar-enter-to {
  width: 100%;
}

.progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 6px;
  z-index: 7;
  background-color: var(--v-mira-base);
}
</style>
