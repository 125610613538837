var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pageLoaded)?_c('div',{staticClass:"section"},[_c('image-header',{ref:"refimageheader",attrs:{"image":_vm.pageData.fields.image,"video":_vm.pageData.fields.video}}),_vm._v(" "),_c('kk-article',{ref:"refarticle",attrs:{"breadcrumbs":_vm.breadcrumbsItems,"contents":_vm.pageData.fields.contents,"ticket-settings":_vm.pageData.fields,"dense":_vm.hasPersons}},[_c('h2',[_vm._v(_vm._s(_vm.pageData.fields.title))]),_vm._v(" "),_c('kk-dynamic-html',{attrs:{"html":_vm.allHtml}}),_vm._v(" "),_c('tag-list',{attrs:{"tags":_vm.pageData.fields.tags,"tag-route":_vm.tagRoute}})],1),_vm._v(" "),(_vm.hasPersons)?_c('v-row',{staticClass:"mt-4 mt-sm-8",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"section"},[_c('persons-list',{attrs:{"persons":_vm.pageData.children,"hide-details":"","disable-wrap-name":""}})],1)],1):_vm._e(),_vm._v(" "),(_vm.pageData.library_set)?_c('section-title',{attrs:{"title":_vm.pageData.library_set.fields.activity_title,"browse-title":_vm.pageData.library_set.fields.activity_browse,"browse-link":{
      name: 'LibrarySet',
      params: { ident: _vm.pageData.library_set.set_node_name },
    }}}):_vm._e(),_vm._v(" "),(_vm.pageData.library_set)?_c('horizontal-scroller',{staticClass:"subsection",attrs:{"items":_vm.pageData.library_set.children,"content-class":"mx-n3"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('horizontal-scroller-end-link-book',{attrs:{"browse-title":_vm.pageData.library_set.fields.activity_browse_link,"browse-link":{
          name: 'LibrarySet',
          params: { ident: _vm.pageData.library_set.set_node_name },
        }}})]},proxy:true},{key:"default",fn:function({ item }){return [_c('library-book-preview',{staticClass:"mx-3",attrs:{"book":item,"width":_vm.$vuetify.breakpoint.smAndUp ? 286 : 214,"in-set-list":""}})]}}],null,false,1320465133)}):_vm._e(),_vm._v(" "),_c('section-title',{attrs:{"title":_vm.pageData.exhibitions_fields.title_other,"browse-title":_vm.pageData.exhibitions_fields.button_all,"browse-link":{ path: '/exhibitions' }}}),_vm._v(" "),_c('horizontal-scroller',{attrs:{"items":_vm.pageData.more,"content-class":"mx-n3"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('horizontal-scroller-end-link',{attrs:{"browse-title":_vm.pageData.exhibitions_fields.button_all,"browse-link":{ path: '/exhibitions' }}})]},proxy:true},{key:"default",fn:function({ item }){return [_c('preview',{staticClass:"mx-3",attrs:{"payload":item,"to":{ name: 'Exhibition', params: { ident: item.node_name } },"tag-route":_vm.tagRouteInList}})]}}],null,false,859291302)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }