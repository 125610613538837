<template>
  <div>
    <v-row no-gutters class="align-end mb-8">
      <v-col class="flex-grow-1">
        <v-tabs
          ref="reftabs"
          v-model="tab"
          color="textLight"
          active-class="textBase--text"
          background-color="bgPrimary"
          slider-color="mira"
          slider-size="4"
          class="tab-row"
          :class="$vuetify.breakpoint.xsOnly ? 'stick-to-body' : ''"
          @change="onTabSwitch"
        >
          <v-tab
            v-for="kind in kinds"
            :key="kind.name"
            :to="{ path: kind.path }"
            exact-path
          >
            {{ (dic.kind || {})[kind.name] }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col v-if="canSelectYear" class="year-col mt-8 mt-sm-0">
        <v-select
          v-model="filters.year"
          dense
          outlined
          clearable
          :items="selectableYears"
          item-value="id"
          item-text="text"
          :menu-props="{
            nudgeBottom: $vuetify.breakpoint.smAndUp ? 53 : 40,
            contentClass: 'kk-select-menu',
          }"
          append-icon="$vuetify.icons.ChevronDown"
          :placeholder="$t('Common.selectYear')"
          hide-details
          @change="onYearChange"
        />
      </v-col>
    </v-row>

    <horizontal-scroller
      v-if="meta.tags"
      :items="meta.tags"
      :disabled="$vuetify.breakpoint.smAndUp"
      content-class="mx-n2"
      class="mb-sm-16"
      disable-spacing
    >
      <template v-slot:prepend>
        <v-col class="flex-grow-0">
          <tag-chip
            :input-value="filters.tags.length == 0"
            class="my-2 mx-2"
            @click="onTagsReset"
          >
            {{ $t("Common.allTags") }}
          </tag-chip>
        </v-col>
      </template>

      <template v-slot="{ item }">
        <tag-chip
          :input-value="filters.tags.some((ct) => ct == item.id)"
          class="my-2 mx-2"
          @click="onTagToggle(item)"
        >
          {{ (item.text || "").toLowerCase() }}
        </tag-chip>
      </template>
    </horizontal-scroller>
  </div>
</template>

<script>
import TagChip from "@/components/Tags/TagChip.vue";
import HorizontalScroller from "./HorizontalScroller.vue";
import xor from "lodash/xor";

export default {
  name: "TabsFilters",
  components: { TagChip, HorizontalScroller },
  props: {
    value: {
      type: Object,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    dic: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    tab: {
      get() {
        const base = process.env.BASE_URL;
        return base + this.kindToPath(this.filters.kind).substr(1);
      },
      set(val) {
        const base = process.env.BASE_URL;
        const kindRec = this.kinds.find(
          (kind) => `${base}${kind.path.substr(1)}` == val
        );
        if (!kindRec) return;
        this.filters.kind = kindRec.name;
      },
    },
    kinds() {
      return Object.keys(this.meta.kind).map((kind) => ({
        name: kind,
        path: this.kindToPath(kind),
      }));
    },
    kindToPath() {
      return (kind) => {
        const slug = kind === "actual" ? "" : `/${kind}`;
        return `${this.$route.matched[0].path}${slug}`;
      };
    },
    selectableYears() {
      if (!this.meta.year) return [];
      const entries = Object.entries(this.meta.year);
      if (entries.length <= 1) return [];
      return entries
        .sort((y1, y2) => parseInt(y2[0]) - parseInt(y1[0]))
        .map((entry) => ({ id: entry[0], text: entry[0], cnt: entry[1] }));
    },
    canSelectYear() {
      return this.filters.kind == "archive" && this.selectableYears.length > 0;
    },
    selectableTags() {
      if (!this.meta.tags) return [];
      const entries = Object.entries(this.meta.tags);
      return entries.map((entry) => entry[0]);
    },
  },
  methods: {
    async init() {
      this.$refs.reftabs.callSlider();
    },
    onTabSwitch() {
      this.filters.year = null;
      this.filters.tags = [];
      this.$emit("change");
    },
    onYearChange() {
      this.filters.tags = [];
      this.$emit("change");
    },
    onTagsReset() {
      this.filters.tags = [];
      this.$emit("change");
    },
    onTagToggle(tag) {
      this.filters.year = null;
      this.filters.tags = xor(this.filters.tags, [tag.id]);
      this.$emit("change");
    },
    setTag(tag) {
      this.filters.year = null;
      this.filters.tags = [tag.id];
      this.$emit("change");
    },
  },
};
</script>
<style scoped lang="scss">
#app.v-application.theme--light {
  .v-tabs.tab-row {
    overflow-x: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: unset;
  }
  .v-tabs.tab-row::-webkit-scrollbar {
    display: none !important;
  }
}
.year-col {
  flex-grow: 0;
  min-width: 220px;
}
</style>
