<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      fill="currentColor"
      d="M3 3L3 21L21 21L21 3M10 17L5 12L6.41 10.58813L10 14.1681L17.59 6.57812L19 8L10 17Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
