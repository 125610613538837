<template>
  <router-link
    :to="{ name: 'Visitors', hash: '#prices' }"
    class="menu-closure base-3"
  >
    {{ sectionData.fields.button_buy_ticket }}
  </router-link>
</template>

<script>
export default {
  props: {
    sectionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
