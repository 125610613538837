<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M18.7071 6.70711L19.4142 6L18 4.58579L17.2929 5.29289L18.7071 6.70711ZM5.29289 17.2929L4.58579 18L6 19.4142L6.70711 18.7071L5.29289 17.2929ZM17.2929 5.29289L5.29289 17.2929L6.70711 18.7071L18.7071 6.70711L17.2929 5.29289Z"
        fill="currentColor"
      />
      <path
        d="M6.70711 5.29289L6 4.58579L4.58579 6L5.29289 6.70711L6.70711 5.29289ZM17.2929 18.7071L18 19.4142L19.4142 18L18.7071 17.2929L17.2929 18.7071ZM5.29289 6.70711L17.2929 18.7071L18.7071 17.2929L6.70711 5.29289L5.29289 6.70711Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
