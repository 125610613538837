<template>
  <div v-if="sectionData" class="section">
    <page-title :title="sectionData.fields.title" />

    <section-title
      :title="sectionData.exhibitions.fields.title"
      :browse-title="browseTitle"
      :browse-link="{ path: '/exhibitions' }"
    />

    <horizontal-scroller
      :items="sectionData.exhibitions.children"
      class="subsection"
      content-class="mx-n3"
    >
      <template v-slot:append>
        <horizontal-scroller-end-link
          :browse-title="browseTitle"
          :browse-link="{ path: '/exhibitions' }"
        />
      </template>
      <template v-slot="{ item }">
        <preview
          :payload="item"
          :to="{ name: 'Exhibition', params: { ident: item.node_name } }"
          class="mx-3"
          disabled-tags
        />
      </template>
    </horizontal-scroller>

    <section-title
      :title="sectionData.events.fields.title"
      :browse-title="browseTitle"
      :browse-link="{ path: '/events' }"
    />

    <horizontal-scroller
      :items="sectionData.events.children"
      class="subsection"
      content-class="mx-n3"
    >
      <template v-slot:append>
        <horizontal-scroller-end-link
          :browse-title="browseTitle"
          :browse-link="{ path: '/events' }"
        />
      </template>
      <template v-slot="{ item }">
        <preview
          :payload="item"
          :to="{ name: 'Event', params: { ident: item.node_name } }"
          inner-info
          class="mx-3 pb-6"
          disabled-tags
        />
      </template>
    </horizontal-scroller>

    <section-title
      :title="sectionData.news.fields.title"
      :browse-title="browseTitle"
      :browse-link="{ name: 'NewsList' }"
    />

    <div class="subsection">
      <news-list-item
        v-for="newsItem in sectionData.news.children"
        :key="newsItem.node_name"
        :payload="newsItem"
      />
    </div>

    <section-title
      v-if="hasOnlineProjects"
      :title="sectionData.online_projects.fields.title"
      :browse-title="browseTitle"
      :browse-link="{ name: 'OnlineProjects' }"
    />

    <main-whats-new-grid
      v-if="hasOnlineProjects"
      :items-with-image="sectionData.online_projects.with_image"
      :items-no-image="sectionData.online_projects.no_image"
    />
  </div>
</template>
<script>
import PageTitle from "@/components/PageTitle.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import Preview from "@/components/Preview/Preview.vue";
import NewsListItem from "@/components/NewsListItem.vue";
import MainWhatsNewGrid from "./MainWhatsNewGrid.vue";
import HorizontalScrollerEndLink from "@/components/HorizontalScrollerEndLink.vue";

export default {
  name: "MainWhatsNew",
  components: {
    PageTitle,
    SectionTitle,
    HorizontalScroller,
    Preview,
    NewsListItem,
    MainWhatsNewGrid,
    HorizontalScrollerEndLink,
  },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
    browseTitle: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasOnlineProjects() {
      return (
        (this.sectionData?.online_projects?.with_image || []).length +
          (this.sectionData?.online_projects?.no_image || []).length >
        0
      );
    },
  },
};
</script>
