<template>
  <div class="section section__gallery">
    <div id="touch-panel">
      <kk-link
        v-if="items.length > 0"
        :link="thisFields.link"
        class="d-block"
        draggable="false"
      >
        <div
          id="scroller-obv"
          class="scroller__container"
          :style="`height: ${height}px`"
        >
          <div class="scroller" :style="`height: ${height}px`">
            <div class="scroller__item left" :style="leftItem">
              <image-extended
                :image="leftElement.fields.image"
                class="scroller__image"
                :position="`center ${parallax}%`"
                :style="otherImage"
              />
              <div class="shader" :style="leftShader" />
            </div>
            <div class="scroller__item this" :style="thisItem">
              <image-extended
                :image="thisElement.fields.image"
                class="scroller__image"
                :position="`center ${parallax}%`"
                :style="thisImage"
              />
              <video-background
                :src="thisElement.fields.video"
                :fade="moving"
              />
              <div class="shader" :style="thisShader" />
            </div>
            <div class="scroller__item right" :style="rightItem">
              <image-extended
                :image="rightElement.fields.image"
                class="scroller__image"
                :position="`center ${parallax}%`"
                :style="otherImage"
              />
              <div class="shader" :style="rightShader" />
            </div>
          </div>
          <div class="ear ear--left">
            <kk-arrow :rotate="180" @click.stop.prevent="scrollLeftManual" />
          </div>
          <div class="ear ear--right">
            <kk-arrow :rotate="0" @click.stop.prevent="scrollRightManual" />
          </div>
          <progress-bar
            :trigger="moving || !autoScroll"
            :duration="autoScrollInterval - animationDuration - zoomDuration"
            @progress-bar-finish="autoScrollRight"
          />
        </div>
      </kk-link>
    </div>
    <div :style="titlesStyle" class="pt-5">
      <h4 class="mb-1">{{ thisFields.title }}</h4>
      <div class="longread">{{ thisFields.subtitle }}</div>
    </div>
  </div>
</template>
<script>
import ProgressObserverMixin from "@/mixins/ProgressObserverMixin.js";
import KkLink from "@/components/KkLink.vue";
import KkArrow from "@/components/KkArrow.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import ImageExtended from "@/components/ImageExtended.vue";
import VideoBackground from "@/components/VideoBackground.vue";
export default {
  name: "MainImageGallery",
  components: { KkLink, KkArrow, ProgressBar, ImageExtended, VideoBackground },
  mixins: [ProgressObserverMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
    animationDuration: {
      type: Number,
      default: 0.5,
    },
    animationTimingFunction: {
      type: String,
      default: "ease-in-out",
    },
    fadeTimingFunction: {
      type: String,
      default: "ease-in",
    },
    zoomDuration: {
      type: Number,
      default: 0.9,
    },
    zoomTimingFunction: {
      type: String,
      default: "ease-out",
    },
    zoomFactor: {
      type: Number,
      default: 1.2,
    },
    height: {
      type: Number,
      default: 750,
    },
    autoScrollInterval: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      swipeDistance: 80,
      activeItemIndex: 0,
      movingLeft: false,
      movingRight: false,
      zooming: false,
      autoScroll: true,
      touchstartX: 0,
      touchstartY: 0,
      obvOptions: {
        viewportTopPct: 15,
        viewportBottomPct: 60,
        pivotBegin: "top",
        pivotEnd: "bottom",
      },
    };
  },
  computed: {
    parallax() {
      return 40 + this.obv("scroller-obv") * 50;
    },
    moving() {
      return this.movingLeft || this.movingRight;
    },
    thisFields() {
      if (this.zooming) {
        return this.movingLeft
          ? this.leftElement.fields
          : this.rightElement.fields;
      } else if (this.thisElement) {
        return this.thisElement.fields;
      } else {
        return {};
      }
    },
    leftElement() {
      const index =
        this.activeItemIndex > 0
          ? this.activeItemIndex - 1
          : this.items.length - 1;
      return this.items[index];
    },
    thisElement() {
      return this.items[this.activeItemIndex];
    },
    rightElement() {
      const index =
        this.activeItemIndex < this.items.length - 1
          ? this.activeItemIndex + 1
          : 0;
      return this.items[index];
    },
    otherImage() {
      return this.zooming
        ? {
            transition: `all ${this.zoomDuration}s ${this.zoomTimingFunction}`,
            width: "100%",
            height: `${this.height}px`,
            left: "0",
            top: "0",
          }
        : {
            transition: `none`,
            width: `${this.zoomFactor * 100}%`,
            height: `${this.height * this.zoomFactor}px`,
            left: `-${(this.zoomFactor - 1) * 50}%`,
            top: `-${(this.zoomFactor - 1) * Math.round(this.height / 2)}px`,
          };
    },
    thisImage() {
      return {
        transition: this.zooming
          ? `all ${this.zoomDuration}s ${this.zoomTimingFunction}`
          : "none",
        width: "100%",
        height: `${this.height}px`,
        left: 0,
        top: 0,
      };
    },
    leftItem() {
      const transition = this.movingLeft
        ? `all ${this.animationDuration}s ${this.animationTimingFunction}`
        : "none";
      const left = this.movingLeft ? "0%" : "-100%";
      const height = `${this.height}px`;
      return { transition, left, height };
    },
    thisItem() {
      const transition = this.moving
        ? `all ${this.animationDuration}s ${this.animationTimingFunction}`
        : "none";
      const rightSide = this.movingRight ? "-50%" : "0%";
      const left = this.movingLeft ? "50%" : rightSide;
      const height = `${this.height}px`;
      return { transition, left, height };
    },
    rightItem() {
      const transition = this.movingRight
        ? `all ${this.animationDuration}s ${this.animationTimingFunction}`
        : "none";
      const left = this.movingRight ? "0%" : "100%";
      const height = `${this.height}px`;
      return { transition, left, height };
    },
    leftShader() {
      const transition = this.movingLeft
        ? `opacity ${this.animationDuration}s ${this.fadeTimingFunction}`
        : "none";
      const opacity = this.movingLeft ? 0 : 0.7;
      return { transition, opacity };
    },
    thisShader() {
      const transition = this.moving
        ? `opacity ${this.animationDuration}s ${this.fadeTimingFunction}`
        : "none";
      const opacity = this.moving ? 0.7 : 0;
      return { transition, opacity };
    },
    rightShader() {
      const transition = this.movingRight
        ? `opacity ${this.animationDuration}s ${this.fadeTimingFunction}`
        : "none";
      const opacity = this.movingRight ? 0 : 0.7;
      return { transition, opacity };
    },
    titlesStyle() {
      return {
        opacity: this.moving && !this.zooming ? 0 : 1,
        transition: `opacity ${this.zoomDuration}s ${this.fadeTimingFunction}`,
      };
    },
  },
  mounted() {
    const zone = document.getElementById("touch-panel");
    zone.addEventListener("pointerdown", this.onPointerDown, false);
    zone.addEventListener("pointerup", this.onPointerUp, false);
    this.initObserver();
  },
  destroyed() {
    const zone = document.getElementById("touch-panel");
    if (zone) {
      zone.removeEventListener("pointerdown", this.onPointerDown);
      zone.removeEventListener("pointerup", this.onPointerUp);
    }
  },
  methods: {
    onPointerDown(event) {
      this.touchstartX = event.screenX;
      this.touchstartY = event.screenY;
    },

    onPointerUp(event) {
      if (this.touchstartX < event.screenX - this.swipeDistance) {
        this.scrollLeftManual();
      } else if (this.touchstartX > event.screenX + this.swipeDistance) {
        this.scrollRightManual();
      }
    },

    async initObserver() {
      this.obvAddElement("scroller-obv");
    },

    autoScrollRight() {
      if (this.autoScroll) {
        this.scrollRight();
      }
    },

    scrollLeftManual() {
      this.autoScroll = false;
      this.scrollLeft();
    },

    scrollRightManual() {
      this.autoScroll = false;
      this.scrollRight();
    },

    async scrollLeft() {
      if (this.moving) return;

      this.movingLeft = true;
      await new Promise((resolve) =>
        setTimeout(resolve, this.animationDuration * 1000)
      );

      this.zooming = true;
      await new Promise((resolve) =>
        setTimeout(resolve, this.zoomDuration * 1000)
      );

      if (this.activeItemIndex == 0) {
        this.activeItemIndex = this.items.length - 1;
      } else {
        this.activeItemIndex--;
      }

      this.movingLeft = false;
      this.zooming = false;
    },

    async scrollRight() {
      if (this.moving) return;

      this.movingRight = true;
      await new Promise((resolve) =>
        setTimeout(resolve, this.animationDuration * 1000)
      );

      this.zooming = true;
      await new Promise((resolve) =>
        setTimeout(resolve, this.zoomDuration * 1000)
      );

      if (this.activeItemIndex == this.items.length - 1) {
        this.activeItemIndex = 0;
      } else {
        this.activeItemIndex++;
      }

      this.movingRight = false;
      this.zooming = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.section__gallery {
  @media (max-width: 1919px) {
    margin-bottom: 80px;
  }

  .scroller__container {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;

    .ear {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      position: absolute;
      top: 50%;
      margin-top: -26px;
      z-index: 4;
      &.ear--left {
        left: 20px;
      }
      &.ear--right {
        right: 20px;
      }
    }

    &:hover .ear {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    .scroller {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      overflow-x: visible;

      .scroller__item {
        overflow-x: hidden;
        overflow-y: hidden;
        position: absolute;
        width: 100%;
        &.this {
          z-index: 1;
        }
        &.left,
        &.right {
          z-index: 2;
        }

        .scroller__image {
          position: absolute;
          min-width: 100%;
          max-width: none;
        }

        .shader {
          position: absolute;
          z-index: 3;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #000;
        }
      }
    }
  }
}
</style>
