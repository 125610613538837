<template>
  <div v-if="sectionData" class="subsection">
    <section-title header-id="logo" :title="sectionData.fields.title" />
    <v-row no-gutters class="align-start">
      <v-col cols="12" xl="" class="pb-5 pb-sm-10 pr-xl-10 flex-md-grow-0">
        <img :src="sectionData.fields.logo_1" class="logo" />
      </v-col>
      <v-col
        cols="12"
        md=""
        class="pb-5 pb-sm-10 pr-md-10 pl-xl-10 flex-md-grow-0"
      >
        <img :src="sectionData.fields.logo_2" class="logo" />
      </v-col>
      <v-col cols="12" md="" class="pb-5 pb-sm-10 pl-md-10 flex-md-grow-0">
        <img :src="sectionData.fields.logo_3" class="logo" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" lg="8" class="longread pb-5 pb-sm-10">
        <kk-dynamic-html :html="sectionData.fields.text" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <person
          :name="sectionData.fields.author"
          :role="sectionData.fields.author_description"
          :avatar="sectionData.fields.author_photo"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <file-attach
          :item="{
            f_path: sectionData.fields.logo_archive,
            f_title: sectionData.fields.logo_archive_title,
          }"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import Person from "@/components/Person.vue";
import FileAttach from "@/components/FileAttach.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "AboutLogo",
  components: { SectionTitle, Person, FileAttach, KkDynamicHtml },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  @media (max-width: 639px) {
    width: 100%;
  }
  @media (max-width: 1279px) {
    max-width: 100%;
  }
  @media (min-width: 1024px) {
    height: 100px;
    width: auto;
  }
}
</style>
