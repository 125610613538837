<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M3 11H2V13H3V11ZM21 13H22V11H21V13ZM3 13H21V11H3V13Z"
        fill="currentColor"
      />
      <path
        d="M3 5H2V7H3V5ZM21 7H22V5H21V7ZM3 7H21V5H3V7Z"
        fill="currentColor"
      />
      <path
        d="M3 17H2V19H3V17ZM21 19H22V17H21V19ZM3 19H21V17H3V19Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
