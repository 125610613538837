<template>
  <div>
    <kk-dynamic-html
      v-if="blockData.fields.article_before"
      :html="blockData.fields.article_before"
    />

    <horizontal-scroller
      v-if="blockData.children.length > 0"
      :items="blockData.children"
      content-class="mx-n3"
      stick-to="left"
      class="mb-5 mb-sm-10"
    >
      <template v-slot:append>
        <horizontal-scroller-end-link
          :browse-title="blockData.fields.button_all"
          :browse-link="browseLink"
        />
      </template>
      <template v-slot="{ item }">
        <preview
          :payload="item"
          :to="{ name: 'Exhibition', params: { ident: item.node_name } }"
          :tag-route="tagRouteInList"
          class="mx-3"
          disabled-tags
        />
      </template>
    </horizontal-scroller>

    <kk-dynamic-html v-else :html="blockData.fields.article_nodata" />

    <kk-dynamic-html
      v-if="blockData.fields.article_after"
      :html="blockData.fields.article_after"
    />
  </div>
</template>
<script>
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import Preview from "@/components/Preview/Preview.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import HorizontalScrollerEndLink from "@/components/HorizontalScrollerEndLink.vue";

export default {
  name: "ShsiExhibitionsWidget",
  components: {
    KkDynamicHtml,
    Preview,
    HorizontalScroller,
    HorizontalScrollerEndLink,
  },
  props: {
    blockData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    browseLink() {
      if (this.blockData.fields.tags.length == 0) {
        return { path: "/exhibitions/all" };
      } else {
        const tag = this.blockData.fields.tags[0];
        return { name: "ExhibitionsAll", params: { tag: tag.id } };
      }
    },
  },
  methods: {
    tagRouteInList(tag) {
      return { name: "ExhibitionsAll", params: { tag: tag.id } };
    },
  },
};
</script>
