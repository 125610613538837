<template>
  <div v-if="pageLoaded" class="section">
    <page-title :title="pageData.fields.title" />

    <kk-dynamic-html
      v-if="pageData.fields.article"
      :html="pageData.fields.article"
      class="subsection"
    />

    <v-row no-gutters class="mb-5 mb-sm-10">
      <v-col cols="12" sm="9" md="6" lg="5" xl="4">
        <v-select
          v-model="tourId"
          dense
          outlined
          :items="selectableVirtualTours"
          item-value="id"
          item-text="text"
          attach="#virtual-tour-select-sub"
          :menu-props="{
            contentClass: 'kk-select-menu',
          }"
          append-icon="$vuetify.icons.ChevronDown"
          hide-details
          single-line
          width="100%"
        >
          <template v-slot:item="{ item }">
            <div class="py-1">
              {{ item.text }}
            </div>
          </template>
          <template v-slot:selection="{ item }">
            <div class="py-1 virtual-tour-selection">
              {{ item.text }}
            </div>
          </template>
        </v-select>
        <div id="virtual-tour-select-sub" />
      </v-col>
    </v-row>

    <v-row v-if="selectedText.length > 0" no-gutters class="mb-5 mb-sm-10">
      <v-col cols="12">
        <kk-dynamic-html :html="selectedText" />
      </v-col>
    </v-row>

    <virtual-tour :tour-path="tourPath" />
  </div>
</template>

<script>
import OnlineProjectsRepository from "@/api/online-projects.js";
import PageTitle from "@/components/PageTitle.vue";
import VirtualTour from "@/components/VirtualTour.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  components: {
    PageTitle,
    VirtualTour,
    KkDynamicHtml,
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  props: {
    ident: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pageLoaded: false,
      tourId: null,
      pageData: {
        fields: {},
        children: [],
      },
    };
  },
  computed: {
    selectableVirtualTours() {
      return this.pageData.children.map((item) => ({
        id: item.node_name,
        text: item.fields.title,
      }));
    },
    selectedText() {
      const selected = this.pageData.children.find(
        (item) => item.node_name == this.tourId
      );
      if (!selected) return "";
      return selected.fields.annotation;
    },
    tourPath() {
      return `${this.pageData.fields.virtual_tour_path}/${this.tourId}`;
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
    tourId(newV) {
      this.$router.push({ path: `/virtual-tour/${newV}` }).catch(() => {});
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await OnlineProjectsRepository.getVirtualTours({});
      if (data.children.length) {
        if (this.ident.length) {
          this.tourId = this.ident;
        } else {
          this.tourId = data.children[0].node_name;
        }
      }
      this.pageData = data;
      this.$store.commit("setLangsAvailAll");
      this.pageLoaded = true;
      this.$emit("page-loaded");
    },
  },
};
</script>
<style scoped lang="scss">
#virtual-tour-select-sub {
  position: relative;
}
.virtual-tour-selection {
  line-height: 140% !important;
}
</style>
