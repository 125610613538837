<template>
  <div v-if="sectionData">
    <page-title :title="sectionData.fields.title" />

    <v-row class="align-stretch section mt-n3">
      <v-col cols="12" sm="4" lg="3" class="mb-3 mb-sm-0">
        <div class="sticky">
          <kk-dynamic-html class="mb-6" :html="sectionData.fields.article" />
          <div class="d-block d-sm-inline-block">
            <v-btn outlined tile block :to="{ name: 'About' }">
              {{ sectionData.fields.browse_all }}
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="8" lg="9">
        <image-extended :image="sectionData.fields.image" width="100%" />
      </v-col>
    </v-row>

    <div class="about__map stick-to-body">
      <kk-map :coords="[56.010652, 92.893604]" />
    </div>
  </div>
</template>
<script>
import PageTitle from "@/components/PageTitle.vue";
import ImageExtended from "@/components/ImageExtended.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import KkMap from "@/components/KkMap.vue";

export default {
  name: "MainAbout",
  components: { PageTitle, ImageExtended, KkDynamicHtml, KkMap },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
.about__map {
  height: 320px;
  @media (min-width: 1024px) {
    height: 640px;
  }
}
.sticky {
  position: sticky;
  top: 100px;
}
</style>
