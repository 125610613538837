<template>
  <div class="mt-4 mt-sm-8 file-attach">
    <a
      :href="downloadLink"
      class="d-inline-flex align-start file-attach-link"
      target="_blank"
    >
      <div class="ml-n1">
        <v-icon size="34" color="mira">$vuetify.icons.Attach</v-icon>
      </div>
      <div class="file-attach-title">
        {{ item.f_title }}
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "FileAttach",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    downloadLink() {
      return this.item.f_path;
    },
  },
};
</script>
<style scoped lang="scss">
.file-attach-link {
  text-decoration: none !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 140%;
  }

  .file-attach-title {
    overflow-wrap: anywhere;
    margin-left: 8px;
    margin-top: 1px;
  }
}
</style>
