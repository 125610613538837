import Events from "@/pages/Events";
import Event from "@/pages/Event";
import EventSub from "@/pages/EventSub";

export default [
  {
    path: "/events",
    component: Events,
    props: true,
    children: [
      {
        path: "tag/:tag",
        name: "EventsByTag",
        props: true,
        meta: {
          kind: "Events",
          group: "Events",
        },
      },
      {
        path: "",
        name: "Events",
        meta: {
          kind: "Events",
          group: "Events",
        },
      },
      {
        path: "archive/tag/:tag",
        name: "EventsArchiveByTag",
        props: true,
        meta: {
          kind: "EventsArchive",
          group: "Events",
        },
      },
      {
        path: "archive",
        name: "EventsArchive",
        meta: {
          kind: "EventsArchive",
          group: "Events",
        },
      },
      {
        path: "all/tag/:tag",
        name: "EventsAllByTag",
        props: true,
        meta: {
          kind: "EventsAll",
          group: "Events",
        },
      },
      {
        path: "all",
        name: "EventsAll",
        meta: {
          kind: "EventsAll",
          group: "Events",
        },
      },
      {
        path: ":ident",
        name: "Event",
        component: Event,
        props: true,
        children: [
          {
            path: ":subIdent",
            name: "EventSub",
            component: EventSub,
            props: true,
          },
        ],
      },
    ],
  },
];
