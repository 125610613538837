<template>
  <div>
    <router-link
      :to="to"
      class="preview-block kk-hoverable-image mb-2 mb-sm-4"
      draggable="false"
    >
      <image-extended
        :image="payload.fields.image"
        class="preview-image mb-4"
        :class="fullWidth ? 'full-width' : ''"
        content-class="preview-image__container"
        eager
      />

      <div
        v-if="glow == '1'"
        ref="refglow"
        class="glow"
        :style="glowStyle"
        @mousemove="onMouseMove"
        @mouseleave="onMouseOut"
      />

      <div v-if="innerInfo" class="kk-shader" />

      <div v-if="innerInfo" class="item-title white--text" @click.prevent>
        <div v-if="!noDate">
          <schedule-dates-interval :schedule="payload.fields.schedule" />
        </div>
        <div class="sign-for-preview">
          {{ payload.fields.title }}
        </div>
      </div>

      <h4 v-if="!innerInfo" class="mb-2 textBase--text">
        {{ payload.fields.title }}
      </h4>

      <div v-if="!innerInfo" class="mb-3 textBase--text">
        <schedule-dates-interval
          v-if="!noDate"
          :schedule="payload.fields.schedule"
        />
      </div>
    </router-link>

    <tag-list
      v-if="payload.fields.tags && !disabledTags"
      :tags="payload.fields.tags"
      :tag-route="tagRoute"
      @tagclick="onTagClick"
    />
  </div>
</template>
<script>
import TagList from "@/components/Tags/TagList.vue";
import ScheduleDatesInterval from "@/components/ScheduleDatesInterval.vue";
import ImageExtended from "@/components/ImageExtended.vue";
import "./Preview.scss";

export default {
  components: { TagList, ScheduleDatesInterval, ImageExtended },
  props: {
    payload: {
      type: Object,
      required: true,
    },
    to: {
      type: Object,
      required: true,
    },
    glow: {
      type: [Number, String],
      default: "0",
    },
    innerInfo: {
      type: Boolean,
      default: false,
    },
    noDate: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabledTags: {
      type: Boolean,
      default: false,
    },
    tagRoute: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      glowRadius: 1000,
      glowCoord: {
        x: null,
        y: null,
      },
    };
  },
  computed: {
    glowStyle() {
      const { x, y } = this.glowCoord;
      if (x == null || y == null) {
        return {
          "background-color": "black",
        };
      } else {
        return {
          background: `radial-gradient(
            ${this.glowRadius}px circle at ${x}px ${y}px,
            rgba(255, 255, 255, 0.25) 0%,
            rgba(255, 255, 255, 0.1) 10%,
            transparent 16%,
            black 40%`,
        };
      }
    },
  },
  methods: {
    onMouseMove(event) {
      if (this.glow == "1" && this.$refs.refglow) {
        const rect = this.$refs.refglow.getBoundingClientRect();
        this.glowCoord.x = event.clientX - rect.left;
        this.glowCoord.y = event.clientY - rect.top;
      }
    },
    onMouseOut() {
      this.glowCoord.x = this.glowCoord.y = null;
    },
    onTagClick(tag) {
      this.$emit("tagclick", tag);
    },
  },
};
</script>
