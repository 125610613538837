import Repository from "@/plugins/axios";

export default {
  // Получить страницу ШСИ
  getShsi() {
    return Repository.get(`/school`);
  },

  // Получить страницу ШСИ -- пресса
  getShsiPress() {
    return Repository.get(`/school/press`);
  },
};
