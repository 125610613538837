import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ru from "vuetify/es5/locale/ru";
import AgeRating00 from "@/components/Icons/AgeRating00.vue";
import AgeRating06 from "@/components/Icons/AgeRating06.vue";
import AgeRating12 from "@/components/Icons/AgeRating12.vue";
import AgeRating16 from "@/components/Icons/AgeRating16.vue";
import AgeRating18 from "@/components/Icons/AgeRating18.vue";
import ArrowRight from "@/components/Icons/ArrowRight.vue";
import Arrrow from "@/components/Icons/Arrrow.vue";
import Attach from "@/components/Icons/Attach.vue";
import Burger from "@/components/Icons/Burger.vue";
import Calendar from "@/components/Icons/Calendar.vue";
import CheckOn from "@/components/Icons/CheckOn.vue";
import CheckOff from "@/components/Icons/CheckOff.vue";
import ChevronDown from "@/components/Icons/ChevronDown.vue";
import ChevronDownLarge from "@/components/Icons/ChevronDownLarge.vue";
import ChevronUp from "@/components/Icons/ChevronUp.vue";
import Clock from "@/components/Icons/Clock.vue";
import Close from "@/components/Icons/Close.vue";
import Download from "@/components/Icons/Download.vue";
import ExternalLink from "@/components/Icons/ExternalLink.vue";
import Info from "@/components/Icons/Info.vue";
import InfoOutline from "@/components/Icons/InfoOutline.vue";
import LowVision from "@/components/Icons/LowVision.vue";
import MapPin from "@/components/Icons/MapPin.vue";
import Mic from "@/components/Icons/Mic.vue";
import Minus from "@/components/Icons/Minus.vue";
import Navigation from "@/components/Icons/Navigation.vue";
import PlayerPlay from "@/components/Icons/PlayerPlay.vue";
import PlayerPause from "@/components/Icons/PlayerPause.vue";
import Plus from "@/components/Icons/Plus.vue";
import Search from "@/components/Icons/Search.vue";
import Sliders from "@/components/Icons/Sliders.vue";
import StarOutline from "@/components/Icons/StarOutline.vue";
import Ticket from "@/components/Icons/Ticket.vue";
import User from "@/components/Icons/User.vue";
import ViewGrid from "@/components/Icons/ViewGrid.vue";
import ViewList from "@/components/Icons/ViewList.vue";
import ZoomIn from "@/components/Icons/ZoomIn.vue";
import ZoomOut from "@/components/Icons/ZoomOut.vue";

Vue.use(Vuetify);

const options = {
  lang: {
    locales: { ru },
    current: "ru",
  },
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    values: {
      // используем иконку например вот так: <v-icon>$vuetify.icons.reload</v-icon>
      AgeRating00: { component: AgeRating00 },
      AgeRating06: { component: AgeRating06 },
      AgeRating12: { component: AgeRating12 },
      AgeRating16: { component: AgeRating16 },
      AgeRating18: { component: AgeRating18 },
      ArrowRight: { component: ArrowRight },
      Arrrow: { component: Arrrow },
      Attach: { component: Attach },
      Burger: { component: Burger },
      Calendar: { component: Calendar },
      CheckOn: { component: CheckOn },
      CheckOff: { component: CheckOff },
      ChevronDown: { component: ChevronDown },
      ChevronDownLarge: { component: ChevronDownLarge },
      ChevronUp: { component: ChevronUp },
      Clock: { component: Clock },
      Close: { component: Close },
      Download: { component: Download },
      ExternalLink: { component: ExternalLink },
      Info: { component: Info },
      InfoOutline: { component: InfoOutline },
      LowVision: { component: LowVision },
      MapPin: { component: MapPin },
      Mic: { component: Mic },
      Minus: { component: Minus },
      Navigation: { component: Navigation },
      PlayerPlay: { component: PlayerPlay },
      PlayerPause: { component: PlayerPause },
      Plus: { component: Plus },
      Search: { component: Search },
      Sliders: { component: Sliders },
      StarOutline: { component: StarOutline },
      Ticket: { component: Ticket },
      User: { component: User },
      ViewGrid: { component: ViewGrid },
      ViewList: { component: ViewList },
      ZoomIn: { component: ZoomIn },
      ZoomOut: { component: ZoomOut },
    },
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        // Corporate
        mira: "#E20000",
        bgPrimary: "#F7F6F6",
        bgSecondary: "#FFFFFF",
        bgDark: "#242424",
        line: "#BDBDBD",
        iconSocial: "#BDBDBD",

        // Semantic
        info: "#0F7FFF",
        success: "#4CAF50",
        error: "#FF4F44",
        warning: "#FF9800",

        // Text
        primary: "#242424",
        textBase: "#242424",
        textLight: "#898989",
        textOnBgDark: "#FFFFFF",
        linksOnBgDark: "#A7A7A7",
        iconsOnBgDark: "#A7A7A7",
      },
    },
  },

  breakpoint: {
    thresholds: {
      xs: 1024,
      sm: 1280,
      md: 1440,
      lg: 1920,
    },
    scrollBarWidth: 0,
  },
};

export default new Vuetify(options);
