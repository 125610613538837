import NewsList from "@/pages/NewsList";
import NewsItem from "@/pages/NewsItem";

export default [
  {
    path: "/news",
    name: "NewsList",
    component: NewsList,
    children: [
      {
        path: ":ident",
        name: "NewsItem",
        component: NewsItem,
        props: true,
      },
    ],
  },
];
