<template>
  <div v-if="sectionData" class="subsection">
    <section-title header-id="inclusivity" :title="sectionData.fields.title" />

    <kk-dynamic-html :html="sectionData.fields.article" class="mb-4 mb-sm-8" />

    <v-row no-gutters>
      <v-col cols="12" sm="" class="flex-sm-grow-0">
        <v-btn
          outlined
          block
          tile
          :to="{ name: 'CustomPage', params: { ident: 'inclusive' } }"
        >
          {{ sectionData.fields.button_more }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionTitle from "@/components/SectionTitle.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";

export default {
  name: "VisitorsInclusive",
  components: { SectionTitle, KkDynamicHtml },
  props: {
    sectionData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
