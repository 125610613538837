<template>
  <div v-if="typ == 2">
    {{ dateText }}
  </div>
  <div
    v-else-if="dates.length > 0"
    class="d-flex align-center schedule-dates-interval"
  >
    <div>{{ datesFirst }}</div>
    <kk-popover
      v-if="datesOther.length > 0"
      disable-article
      :html="datesOther"
      icon="mdi-dots-horizontal"
      class="ml-2 d-inline-block"
    />
  </div>
  <span v-else-if="dtb && dte">{{ dtb }} — {{ dte }}</span>
  <span v-else-if="dtb">{{ $t("Common.dateFrom") }} {{ dtb }}</span>
  <span v-else-if="dte">{{ $t("Common.dateTo") }} {{ dte }}</span>
  <span v-else>{{ ticketWidgetSection.fields.every_day }}</span>
</template>

<script>
import moment from "moment";
import KkPopover from "./KkPopover.vue";
export default {
  name: "ScheduleDatesInterval",
  components: { KkPopover },
  props: {
    schedule: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fmt: "DD.MM.yyyy",
    };
  },
  computed: {
    ticketWidgetSection() {
      return this.$store.getters.getTicketWidget;
    },
    typ() {
      if (!this.schedule) return -1;
      return this.schedule.typ;
    },
    dateText() {
      if (!this.schedule) return "";
      return this.schedule.text;
    },
    dates() {
      if (!this.schedule) return [];
      const dates = [...(this.schedule.dates || [])];
      dates.sort();
      const datesMoment = dates.map((dt) => moment(dt));
      const datesActual = datesMoment.filter(
        (dt) => moment().startOf("day").diff(dt) <= 0
      );
      if (datesMoment.length > 0 && datesActual.length == 0) {
        return datesMoment.map((dt) => dt.format(this.fmt));
      } else {
        return datesActual.map((dt) => dt.format(this.fmt));
      }
    },
    datesFirst() {
      return this.dates.slice(0, 2).join(", ");
    },
    datesOther() {
      return this.dates.slice(2).join("<br />");
    },
    dtb() {
      const dtb = this.schedule.dt_begin || undefined;
      return dtb ? moment(dtb).format(this.fmt) : undefined;
    },
    dte() {
      const dte = this.schedule.dt_end || undefined;
      return dte ? moment(dte).format(this.fmt) : undefined;
    },
  },
};
</script>
