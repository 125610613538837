<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M20 40C9 40 0 31 0 20C0 9 9 0 20 0C31 0 40 9 40 20C40 31.1 31 40 20 40ZM20 0.799999C9.4 0.799999 0.799999 9.4 0.799999 20C0.799999 30.6 9.4 39.2 20 39.2C30.6 39.2 39.2 30.6 39.2 20C39.2 9.5 30.6 0.799999 20 0.799999Z"
        fill="currentColor"
      />
      <path
        d="M15.0998 26.7984C13.8998 25.0984 13.2998 22.7984 13.2998 20.1984C13.2998 17.5984 13.8998 15.2984 15.0998 13.5984C16.4998 11.5984 18.2998 10.8984 19.6998 10.8984C21.0998 10.8984 22.8998 11.5984 24.2998 13.5984C25.4998 15.2984 26.0998 17.5984 26.0998 20.1984C26.0998 22.7984 25.4998 25.0984 24.2998 26.7984C22.8998 28.7984 21.0998 29.4984 19.6998 29.4984C18.2998 29.4984 16.3998 28.6984 15.0998 26.7984ZM16.5998 14.6984C15.5998 16.1984 15.2998 18.2984 15.2998 20.1984C15.2998 22.0984 15.6998 24.0984 16.5998 25.6984C17.4998 27.1984 18.7998 27.6984 19.6998 27.6984C20.5998 27.6984 21.8998 27.1984 22.7998 25.6984C23.7998 24.1984 24.0998 22.0984 24.0998 20.1984C24.0998 18.2984 23.6998 16.2984 22.7998 14.6984C21.8998 13.1984 20.5998 12.6984 19.6998 12.6984C18.7998 12.6984 17.4998 13.0984 16.5998 14.6984Z"
        fill="currentColor"
      />
      <path
        d="M28 19.2016H32.9V14.6016H34.8V19.2016H39.7V21.0016H34.8V25.6016H32.9V21.0016H28V19.2016Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 40,
    },
    height: {
      type: [Number, String],
      default: 40,
    },
  },
};
</script>
