<template>
  <div>
    <kk-dynamic-html
      v-if="blockData.fields.article_before"
      :html="blockData.fields.article_before"
    />

    <v-row class="subsection justify-start align-center mx-n4 mx-md-n8">
      <v-col
        v-for="(partner, index) in blockData.children"
        :key="index"
        class="flex-grow-0 px-4 px-md-8"
      >
        <kk-link
          v-if="partner.fields.link"
          :link="partner.fields.link"
          class="d-block"
          draggable="false"
        >
          <img
            :src="partner.fields.logo"
            :height="$vuetify.breakpoint.mdAndUp ? 100 : 70"
            alt=""
          />
        </kk-link>
        <div v-else>
          <img
            :src="partner.fields.logo"
            :height="$vuetify.breakpoint.mdAndUp ? 100 : 70"
            alt=""
          />
        </div>
      </v-col>
    </v-row>

    <kk-dynamic-html
      v-if="blockData.fields.article_after"
      :html="blockData.fields.article_after"
    />
  </div>
</template>
<script>
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import KkLink from "@/components/KkLink.vue";

export default {
  name: "ShsiPartners",
  components: {
    KkDynamicHtml,
    KkLink,
  },
  props: {
    blockData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {},
  methods: {},
};
</script>
