import Repository from "@/plugins/axios";

export default {
  // Получить список событий
  getEvents(params) {
    return Repository.get(`/events`, { params });
  },

  // Получить страницу события
  getEvent(eventName) {
    return Repository.get(`/events/${eventName}`);
  },

  // Получить страницу этапа события
  getSubEvent(eventName, subEventName) {
    return Repository.get(`/events/${eventName}/${subEventName}`);
  },
};
