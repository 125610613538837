<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M20.3008 40C9.30078 40 0.300781 31 0.300781 20C0.300781 9 9.30078 0 20.3008 0C31.3008 0 40.3008 9 40.3008 20C40.3008 31.1 31.3008 40 20.3008 40ZM20.3008 0.799999C9.70078 0.799999 1.10078 9.4 1.10078 20C1.10078 30.6 9.70078 39.2 20.3008 39.2C30.9008 39.2 39.5008 30.6 39.5008 20C39.5008 9.5 30.9008 0.799999 20.3008 0.799999Z"
        fill="currentColor"
      />
      <path
        d="M9.80078 12.9938H6.80078L7.90079 11.0938H11.9008V29.0938H9.90079V12.9938H9.80078Z"
        fill="currentColor"
      />
      <path
        d="M23.4008 11.7969L19.3008 17.5969C19.8008 17.3969 20.3008 17.3969 20.9008 17.3969C24.4008 17.3969 26.7008 19.9969 26.7008 23.2969C26.7008 26.8969 24.1008 29.4969 20.5008 29.4969C16.3008 29.4969 14.3008 26.1969 14.3008 23.3969C14.3008 22.6969 14.4008 21.1969 15.8008 19.2969L21.9008 10.7969L23.4008 11.7969ZM24.7008 23.2969C24.7008 20.8969 22.9008 19.0969 20.5008 19.0969C18.0008 19.0969 16.3008 20.8969 16.3008 23.2969C16.3008 25.6969 18.0008 27.5969 20.5008 27.5969C23.0008 27.5969 24.7008 25.7969 24.7008 23.2969Z"
        fill="currentColor"
      />
      <path
        d="M28.3008 19.1937H33.2008V14.5938H35.1008V19.1937H40.0008V20.9938H35.1008V25.5938H33.2008V20.9938H28.3008V19.1937Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 40,
    },
    height: {
      type: [Number, String],
      default: 40,
    },
  },
};
</script>
