<template>
  <div v-if="pageLoaded" class="section">
    <image-header ref="refimageheader" :image="pageData.fields.image" />

    <kk-article
      ref="refarticle"
      :breadcrumbs="breadcrumbsItems"
      dense
      :contents="pageData.fields.contents"
      class="mb-8 mb-md-16"
    >
      <h2>{{ pageData.fields.title }}</h2>
      <kk-dynamic-html :html="pageData.fields.article" />

      <tag-list :tags="pageData.fields.tags" :tag-route="tagRoute" />
    </kk-article>

    <v-row class="subsection">
      <v-col
        v-for="book in pageData.children"
        :key="book.node_name"
        cols="12"
        md="6"
        xl="4"
      >
        <v-row>
          <v-col cols="6" sm="4" md="5">
            <library-book-preview :book="book" in-set-list class="mx-3" />
          </v-col>
          <v-col cols="12" sm="8" md="7" class="pt-0 pt-sm-3">
            <kk-dynamic-html class="base-2" :html="book.fields.annotation" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div v-if="pageData.linked_events.length > 0" class="subsection">
      <section-title :title="pageData.library_sets_fields.linked_events" />

      <horizontal-scroller
        :items="pageData.linked_events"
        content-class="mx-n3"
        :disabled="pageData.linked_events.length == 1"
      >
        <template v-slot="{ item }">
          <preview
            :payload="item"
            :to="{ name: 'Event', params: { ident: item.node_name } }"
            inner-info
            class="mx-3 pb-6"
            :tag-route="tagEventRoute"
          />
        </template>
      </horizontal-scroller>
    </div>

    <div v-if="pageData.linked_exhibitions.length > 0" class="subsection">
      <section-title :title="pageData.library_sets_fields.linked_exhibitions" />

      <horizontal-scroller
        :items="pageData.linked_exhibitions"
        content-class="mx-n3"
        :disabled="pageData.linked_exhibitions.length == 1"
      >
        <template v-slot="{ item }">
          <preview
            :payload="item"
            :to="{ name: 'Exhibition', params: { ident: item.node_name } }"
            class="mx-3 pb-6"
            :tag-route="tagExhibitionRoute"
          />
        </template>
      </horizontal-scroller>
    </div>

    <div v-if="pageData.linked_tours.length > 0" class="subsection">
      <section-title :title="pageData.library_sets_fields.linked_tours" />

      <horizontal-scroller
        :items="pageData.linked_tours"
        content-class="mx-n3"
        :disabled="pageData.linked_tours.length == 1"
      >
        <template v-slot="{ item }">
          <preview
            :payload="item"
            :to="{ name: 'Tour', params: { ident: item.node_name } }"
            inner-info
            :glow="item.fields.glow"
            class="mx-3 pb-6"
            :tag-route="tagTourRoute"
          />
        </template>
      </horizontal-scroller>
    </div>
  </div>
</template>

<script>
import LibraryRepository from "@/api/library.js";
import KkArticle from "@/components/Article/KkArticle.vue";
import ImageHeader from "@/components/ImageHeader.vue";
import HorizontalScroller from "@/components/HorizontalScroller.vue";
import SectionTitle from "@/components/SectionTitle.vue";
import Preview from "@/components/Preview/Preview.vue";
import TagList from "@/components/Tags/TagList.vue";
import KkDynamicHtml from "@/components/Article/KkDynamicHtml.vue";
import LibraryBookPreview from "./LibraryBookPreview.vue";

export default {
  components: {
    KkArticle,
    KkDynamicHtml,
    ImageHeader,
    HorizontalScroller,
    SectionTitle,
    Preview,
    TagList,
    LibraryBookPreview,
  },
  props: {
    ident: {
      type: String,
      required: true,
    },
  },
  metaInfo() {
    return {
      title: this.$store.getters.getWindowTitle(this.pageData.fields.title),
    };
  },
  data() {
    return {
      pageLoaded: false,
      pageData: {
        fields: {},
        main_fields: {},
        library_fields: {},
        library_sets_fields: {},
        children: [],
        linked_events: { fields: {}, children: [] },
        linked_exhibitions: { fields: {}, children: [] },
        linked_tours: { fields: {}, children: [] },
      },
    };
  },
  computed: {
    basePublicUrl() {
      return process.env.BASE_URL;
    },
    coverAspectRatio() {
      return 286 / 404;
    },
    breadcrumbsItems() {
      return [
        {
          text: this.pageData.main_fields.title || "",
          to: { name: "Main" },
          exact: true,
          disabled: false,
        },
        {
          text: this.pageData.library_fields.title || "",
          to: { name: "LibrarySets" },
          exact: true,
          disabled: false,
        },
      ];
    },
  },
  watch: {
    "$store.getters.getLang"(newV, oldV) {
      if (oldV.length > 0) {
        this.loadPage();
      }
    },
  },
  mounted() {
    this.loadPage();
  },
  methods: {
    async loadPage() {
      const { data } = await LibraryRepository.getSet(this.ident);
      this.pageData = data;
      this.$store.commit("setLangsAvail", data.published);
      this.pageLoaded = true;
      this.$emit("page-loaded");

      this.$nextTick(() => {
        this.$refs.refarticle.initObserver();
        this.$refs.refimageheader.initObserver();
      });
    },
    tagRoute(tag) {
      return { name: "LibrarySetsByTag", params: { tag: tag.id } };
    },
    tagExhibitionRoute(tag) {
      return { name: "Exhibitions", params: { tag: tag.id } };
    },
    tagEventRoute(tag) {
      return { name: "Events", params: { tag: tag.id } };
    },
    tagTourRoute(tag) {
      return { name: "Tours", params: { tag: tag.id } };
    },
  },
};
</script>
