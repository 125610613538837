<template>
  <router-link :to="browseLink" class="d-flex align-center button">
    <span>{{ browseTitle }}</span>
    <v-icon size="32" class="ml-4">$vuetify.icons.Arrrow</v-icon>
  </router-link>
</template>

<script>
export default {
  name: "BrowseButton",
  props: {
    browseTitle: {
      type: String,
      required: true,
    },
    browseLink: {
      type: Object,
      required: true,
    },
  },
};
</script>
