<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    role="presentation"
    fill="none"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M6.73926 17.8531C7.19442 18.0908 7.66693 18.3054 8.15116 18.4909L9.52824 17.1138C9.07308 16.908 8.64396 16.6441 8.26422 16.3281L6.73926 17.8531Z"
        fill="currentColor"
      />
      <path
        d="M6.54749 13.8672L4.19629 16.2155C4.57605 16.5054 4.97901 16.7895 5.39945 17.062L7.2433 15.2181C6.95339 14.8065 6.71858 14.3513 6.54749 13.8672Z"
        fill="currentColor"
      />
      <path
        d="M6.21799 11.9836C6.21799 11.053 6.44703 10.1774 6.84708 9.40625L1.97363 14.2797C2.29544 14.5986 2.64916 14.932 3.03476 15.2683L6.22381 12.0793C6.22372 12.0474 6.21796 12.0155 6.21796 11.9836L6.21799 11.9836Z"
        fill="currentColor"
      />
      <path
        d="M11.2165 17.575L9.80176 18.9898C10.3932 19.1202 11.002 19.2015 11.6224 19.2218L13.4692 17.375C12.9561 17.5286 12.4139 17.6127 11.8514 17.6127C11.634 17.6127 11.4223 17.5982 11.2165 17.5751L11.2165 17.575Z"
        fill="currentColor"
      />
      <path
        d="M23.8638 11.9822C23.8638 11.9822 18.5062 4.73438 11.8961 4.73438C9.94497 4.73438 8.10407 5.36931 6.47765 6.26217C6.42547 6.29114 6.37035 6.32011 6.31533 6.34918C6.3008 6.35787 6.28636 6.36372 6.27183 6.3724C5.72681 6.67968 5.20494 7.01311 4.71497 7.36388L0.000976562 12.0808C0.13725 12.2547 0.459064 12.6606 0.937445 13.194L6.46899 7.66244L7.3764 8.56985C7.58222 8.30023 7.81419 8.0509 8.06637 7.82184C8.09534 7.7958 8.12431 7.76966 8.15338 7.74361C8.23161 7.67689 8.30994 7.61026 8.3911 7.54646C9.34786 6.79846 10.5452 6.34915 11.8527 6.34915C14.9634 6.34915 17.4857 8.87139 17.4857 11.9822C17.4857 13.2897 17.0363 14.487 16.2884 15.4437C16.2246 15.5249 16.1579 15.6032 16.0912 15.6815C16.0652 15.7104 16.039 15.7394 16.013 15.7685C15.7839 16.0207 15.5346 16.2498 15.265 16.4556L15.8825 17.0731L13.9545 19.001C15.2185 18.7257 16.413 18.2211 17.506 17.6036C17.5117 17.6007 17.5205 17.595 17.5263 17.592C17.6219 17.5398 17.7147 17.4847 17.8075 17.4297C17.8423 17.4094 17.877 17.3891 17.9119 17.3659C17.9757 17.3283 18.0423 17.2905 18.1061 17.2499L18.1032 17.247C21.556 15.1016 23.8637 11.9822 23.8637 11.9822L23.8638 11.9822Z"
        fill="currentColor"
      />
      <path
        d="M13.9638 14.0581C14.1204 13.8986 14.2566 13.7217 14.3726 13.5304C14.3756 13.5275 14.3784 13.5247 14.3784 13.5218C14.6538 13.0724 14.8133 12.5447 14.8133 11.9794C14.8133 10.3385 13.4826 9.00781 11.8417 9.00781C11.2764 9.00781 10.7487 9.17014 10.2993 9.44268C10.2964 9.44561 10.2936 9.44844 10.2906 9.44844C10.0992 9.56735 9.92249 9.70069 9.76299 9.85727L9.76591 9.86019C9.58038 10.0428 9.41512 10.2458 9.28178 10.4719L9.60651 10.7966L8.91069 11.4925C8.88465 11.649 8.86719 11.8113 8.86719 11.9766C8.86719 12.4463 8.97732 12.8869 9.17164 13.2812L10.6299 11.823L11.691 12.8841L10.1516 14.4235C10.5922 14.728 11.117 14.9135 11.6823 14.9424L12.7144 13.9104L13.3406 14.5366C13.5668 14.4033 13.7698 14.241 13.9524 14.0525L13.9638 14.0581Z"
        fill="currentColor"
      />
      <line
        x1="3.00789"
        y1="2.79289"
        x2="21.1805"
        y2="20.9655"
        stroke="currentColor"
        stroke-width="2"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
};
</script>
